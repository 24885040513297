import { createContext } from "react";

export interface ContextMenuItem {
    name: string;
    onClick: () => void
}

interface ContextMenuModel {
    setContextMenu: (items: ContextMenuItem[], position: number[], title: string) => void
}

export const ContextMenu = createContext<ContextMenuModel>({
    setContextMenu: () => {}
});