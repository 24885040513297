const Minutes = 60,
	Hours = 3600,
	Days = 86400

export default class TimeExtensions {
	static GetTotalSeconds(seconds: number) {
		return Math.round(seconds)
	}

	static GetTotalMinutes(seconds: number) {
		return Math.round(seconds / Minutes)
	}

	static GetTotalHours(seconds: number) {
		return Math.round(seconds / Hours)
	}

	static GetTotalDays(seconds: number) {
		return Math.round(seconds / Days)
	}
}
