import { ESubscription } from './user'

export enum ETransactionType {
	Deposits,
	Purchases,
	Withdraws,
	Promo,
	SubPause,
}

export interface Transaction {
	transactionID: bigint
	type: ETransactionType
	count: number
	time: number
	access: ESubscription
	botID: string
	tradeID: string
	paymentID: bigint
}

export interface TransactionResponse {
	next: boolean
	page: number
	transactions: Transaction[]
}
