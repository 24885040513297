import {
	IHomeState,
	HomeAction,
	EHomeActionTypes,
} from './../../types/redux/home'

const initialState: IHomeState = {
	data: null,
	loading: false,
	loaded: false,
	error: null,
}

export const homeReducer = (
	state: IHomeState = initialState,
	action: HomeAction
): IHomeState => {
	switch (action.type) {
		case EHomeActionTypes.FETCH_HOME:
			return { loading: true, error: null, data: null, loaded: false }
		case EHomeActionTypes.FETCH_HOME_SUCCESS:
			return { loading: false, error: null, data: action.payload, loaded: true }
		case EHomeActionTypes.FETCH_HOME_ERROR:
			return { loading: false, error: action.payload, data: null, loaded: true }
		default:
			return state
	}
}
