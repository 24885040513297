import { CSSProperties, FC, useState } from 'react'

export interface InputTextProps {
	className?: string
	style?: CSSProperties
	placeholder?: string
	defaultText: string
	onValueChange: (value: string) => void
}

const InputText: FC<InputTextProps> = ({ className, style, placeholder, defaultText, onValueChange }) => {
	const [text, setText] = useState(defaultText)

	return (
		<input
			placeholder={placeholder}
			className={className}
			style={style}
			type='text'
			onKeyPress={e => {
				if (e.key === 'Enter') {
					const elem = e.target as HTMLElement
					elem.blur()
				}
			}}
			value={text}
			onChange={e => setText(e.target.value)}
			onBlur={e => {
				onValueChange?.call(this, text)
			}}
		/>
	)
}

export default InputText
