import { Filter } from "../table/filter"

export interface IFilterSalesState {
    active: boolean
}

export enum EFilterSalesActionTypes {
	SHOW_FILTER_SALES = 'SHOW_FILTER_SALES',
	CLOSE_FILTER_SALES = 'CLOSE_FILTER_SALES',
}

interface IShowFilterSalesAction {
	type: EFilterSalesActionTypes.SHOW_FILTER_SALES
}

interface ICloseFilterSalesAction {
	type: EFilterSalesActionTypes.CLOSE_FILTER_SALES
}

export type FilterSalesAction = IShowFilterSalesAction | ICloseFilterSalesAction
