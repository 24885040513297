import Extensions from '../extensions'
import DataModel from './../dataModel'
import { ILanguage } from './language'

export class EnglishLang implements ILanguage {
	HeaderMenu = 'Menu'
	MainPageWelcome = `Welcome to ${DataModel.AppName}`

	// menu
	MenuHome = 'Home'
	MenuProfile = 'Profile'
	MenuTable = 'Table'
	MenuInventory = 'Inventory'
	MenuSubscribe = 'Subscribe'
	MenuBalance = 'Balance'
	MenuSupport = 'Support'
	MenuAbout = 'About us'
	MenuSettings = 'Settings'
	MenuLogin = 'Log in'
	MenuLogout = 'Log out'
	MenuLanguage = 'Language'
	MenuBuy = 'Payments'

	// profile page
	ProfilePage_Sub = 'Subscription'
	ProfilePage_Balance = 'Balance'
	ProfilePage_Deposit = 'Deposit'
	ProfilePage_SubPause = 'Pause'
	ProfilePage_SubPauseAlreadyPaused = 'Paused'
	ProfilePage_PromoCodeTitle = 'Promo code'
	ProfilePage_ActivatePromoCode = 'Activate'
	ProfilePage_ReferralCodeTitle = 'Referral code'
	ProfilePage_ConfirmReferralCode = 'Confirm'
	ProfilePage_YourCode = 'Your code'
	ProfilePage_ReferralsCount = 'Referrals'
	ProfilePage_ReferralsCountWithSub = 'Bought sub'
	ProfilePage_KeysAvailable = 'Keys available'
	ProfilePage_CopyLink = 'Copy link'

	ProfilePage_Transactions_Title = 'Transactions'
	ProfilePage_Transactions_Empty = 'No transactions'
	ProfilePage_Transactions_More = 'More'

	ProfilePageNotification_PromoNotFound = 'Promo code not found'
	ProfilePageNotification_PromoNoUses = 'Promo code exhausted'
	ProfilePageNotification_PromoAlreadyUsed = 'You have already used this promo code'
	ProfilePageNotification_PromoAlreadyUsedGroup = 'You have already used this promo codes group'
	ProfilePageNotification_PromoHasSub = 'You already have an active sub'
	ProfilePageNotification_AlreadyStopped = 'Subscription already suspended'
	ProfilePageNotification_PauseNotAvailableByDelay = 'You have already used a pause, the next one will be available '
	ProfilePageNotification_PauseNotAvailableBySub = 'Unable to activate pause without subscription'

	ProfilePageNotification_ReferralAlreadyRegistered = 'You have already become a referral'
	ProfilePageNotification_ReferralReferralCodeNotFound = 'Referral code not found'
	ProfilePageNotification_ReferralSelfCode = 'You cannot become your referral'

	// sub page

	SubPage_SubInformation = 'Information'

	SubPageNotification_NoBalance = 'Insufficient funds!'
	SubPageNotification_SubIsBetter = 'Your subscription is higher!'
	SubPageNotification_SubPaused = 'Your subscription is paused!'

	// support page
	SupportPage_Title = 'Report the problem to us'
	SupportPage_Name = 'What is your name?'
	SupportPage_Info = 'How to contact you?'
	SupportPage_Problem = 'Describe your problem:'
	SupportPage_Images = 'Add screenshots'
	SupportPage_ButtonSend = 'Send'
	SupportNotify_Success = 'Success!'
	SupportNotify_ErrorEmptyFields = 'Please fill in all fields!'

	// balance page
	BalancePage_Crypto = 'Crypto'
	BalancePage_DepositBtn = 'Deposit'
	BalancePage_RefillBtn = 'Top up'

	// table page
	TablePage_ProfileList = 'Profile'
	TablePage_ProfileListAdd = 'Add new'
	TablePage_ProfileStartValue = 'Profile'
	TablePage_Game = 'Game'
	TablePage_Currency = 'Currency'
	TablePage_Filters = 'Filters'
	TablePage_Clear = 'Clear'
	TablePage_Reset = 'Reset'
	TablePage_Lists = 'Lists'
	TablePage_ResetFilters = 'Reset filters'
	TablePage_SalesService = 'Sales service'
	TablePage_SalesPeriod = 'Sales period'
	TablePage_SalesMin = 'Min. sales'
	TablePage_SalesDay = '3 Days'
	TablePage_SalesWeek = 'Week'
	TablePage_SalesMonth = 'Month'
	TablePage_SalesMonth3 = '3 Months'
	TablePage_FirstService = 'First service'
	TablePage_SecondService = 'Second service'
	TablePage_Markets = 'Markets'
	TablePage_Exchangers = 'Exchangers'
	TablePage_Roulette = 'Roulette'
	TablePage_Time = 'Time (min)'
	TablePage_MinPrice = 'Min price'
	TablePage_MaxPrice = 'Max price'
	TablePage_MinCount = 'Min pc'
	TablePage_MaxCount = 'Max pc'
	TablePage_MinProfit = 'Min %'
	TablePage_MaxProfit = 'Max %'
	TablePage_PriceTypeTitle = 'Price type'
	TablePage_ServiceTitle = 'Service'
	TablePage_PriceTypeNormal = 'Normal'
	TablePage_PriceTypeDeposit = 'Deposit'
	TablePage_PriceTypeOrder = 'Order'
	TablePage_PriceTypeAverage = 'Average'
	TablePage_PriceTypeNoHold = 'No hold'
	TablePage_ButtonUpdate = 'Update'
	TablePage_ButtonCheckPrice = 'Check price'
	TablePage_Table_SkinName = 'Skinname'
	TablePage_Table_Tools = 'Tools'
	TablePage_Table_Search = 'Search'
	TablePage_Table_Sales = 'Sales'
	TablePage_Table_Price = 'Price'
	TablePage_Table_Update = 'Update'
	TablePage_Table_Profit = 'Profit'
	TablePage_Table_Count = 'Count'
	TablePage_Seconds = 'sec'
	TablePage_Minutes = 'min'
	TablePage_Hours = 'h'
	TablePage_Days = 'd'
	TablePage_CheckPrice_Title = 'View item prices on all services'
	TablePage_CheckPrice_ItemName = 'Item name'
	TablePage_CheckPrice_ButtonFind = 'Find'
	TablePage_Message_NoItems = 'No items. Try changing filters.'
	TablePage_SearchNotification_NotFound = 'Nothing found!'
	TablePage_LastUpdate_Title = 'Last update'
	TablePage_LastUpdate_Never = 'Never'
	TablePage_LastUpdate_Now = 'Now'
	TablePage_LastUpdate_Ago = 'ago'
	TablePage_Sales_Total = 'Total'
	TablePage_Sales_Max = 'Max'
	TablePage_Sales_Min = 'Min'
	TablePage_Sales_Avg = 'Avg'
	TablePage_Sales_Corridor = 'Corridor'
	TablePage_Sales_Last = 'Last'
	TablePage_Lists_Profiles = 'Profiles'
	TablePage_Lists_Favorites = 'Favorites'
	TablePage_Lists_BlackList = 'Black list'
	TablePage_Lists_Currency = 'Currency'
	TablePage_Title_Copy = 'Copy'
	TablePage_Title_AddToFavorites = 'Add to favorites'
	TablePage_Title_RemoveFromFavorites = 'Remove from favorites'
	TablePage_Title_AddToBlacklist = 'Add to blacklist'
	TablePage_Title_AddToBlacklistTmp = 'Remove before price change'
	TablePage_Title_PriceCheck = 'View prices for all services'
	TablePage_Toast_ItemsWasUpdated = 'Items have been updated'
	TablePage_FilterCheck_Title = 'Sales filter'
	TablePage_AutoReset = 'Auto reset'
	TablePage_Yes = 'Yes'
	TablePage_No = 'No'
	TablePage_MaxHoldHours = 'Max hold, h.'
	TablePage_Sound = 'Sound'
	TablePage_InInventory = 'In inventory'
	TablePage_ChangePrice = 'Change price'
	TablePage_ConfirmChangePrice = 'Change'
	TablePage_Notification_TooManyRequests = 'Too many requests!'

	// notification default	
	Notification_Error = 'Error'
	Notification_Success = 'Success'
	Notification_CopySuccess = 'Copied'
	Notification_InventoryClosedError = 'Error, your inventory is private'

	// confirmations

	Confirmation_DefaultText = 'Confirm your action'
	Confirmation_Logout = 'Are you sure want to log out?'
	Confirmation_BuySub = 'Confirm purchase'
	Confirmation_DoDeposit = 'Top up balance?'
	Confirmation_PauseSubscribeTitle = 'Suspend subscription for two weeks'
	Confirmation_PauseSubscribe = 'Are you sure you want to pause your subscription for two weeks? (It is not possible to manually restore a subscription)'
	Confirmation_ResetFilters = 'Are you sure you want to reset all filters?'

	// login form

	LoginForm_Text = 'To go to this page, you need to log in.'
	LoginForm_Back = 'Back'

	// user settings page

	UserSettingsPage_Reset = 'Reset'
	UserSettingsPage_UserInfoTitle = 'Connections'
	UserSettingsPage_SettingsTitle = 'Commissions and bonuses'
	UserSettingsPage_Commission = 'Commission'
	UserSettingsPage_Bonus = 'Bonus'
	UserSettingsPage_GetTradeOfferLink = 'To get'
	UserSettingsPage_SaveTradeOfferLink = 'Save'

	UserSettingsPage_Label_TradeOfferLink = 'Trade URL'
	UserSettingsPage_Label_Vk = 'Vk'
	UserSettingsPage_Label_Telegram = 'Telegram'

	UserSettingsPageNotification_IncorrectTradeOfferLink = 'Incorrect trade offer link'
	UserSettingsPageNotification_TokenAlreadyUsed = 'This trade offer link is already in use'
	UserSettingsPageNotification_IncorrectData = 'Incorrect data'
	UserSettingsPageNotification_AccountAlreadyUsed = 'This account was linked by another user'

	UserSettingsPage_ResetCommissions(app: number): string {
		return `Are you sure you want to reset all commissions for ${Extensions.GetGamePrettyByAppId(app)}?`
	}

	// Texts

	Text_DepositKeys(count: number): string {
		return `Deposit: x ${count}`
	}

	// Footer

	Footer_AllRightsReserved = 'All rights reserved'
	Footer_TermsOfUse = 'Terms of use'

	// Preview page

	PreviewPage_BtnStart = 'Start'
	PreviewPage_Box_Title = 'Next level trading'
	PreviewPage_Box_SubTitle = 'Steam trading better with vvTable!'
	PreviewPage_TotalItems_Title = 'Items tracked'
	PreviewPage_Adv_Title_1 = 'Choice of services for trading for every taste'
	PreviewPage_Adv_Description_1 = 'We provide users more than 20 services for trading items from 4 games.'
	PreviewPage_Adv_Title_2 = 'Fastest item refresh time'
	PreviewPage_Adv_Description_2 = 'We use advanced parsing technology, so item prices are updated in about 10 minutes.'
	PreviewPage_Adv_Title_3 = 'Convenient way to replenish balance'
	PreviewPage_Adv_Description_3 =
		'We took into account preferences of trading community, so we choose item «Mann Co.Supply Crate Key» as way to replenish the balance.'
	PreviewPage_TrustContent_Title = 'Our partners'
	PreviewPage_Banner_Title = 'Start the path of true trader'
	PreviewPage_Banner_Description = 'Log in to vvTable and dive into the world of Steam trading, earning on the exchange of skins'

	// new year
	NewYear_Title = 'New Year'
	NewYear_Enable = 'Enable'
	NewYear_Disable = 'Disable'

	// connection
	MainConnection_ConnectionLostTitle = `Connection lost`
	MainConnection_ConnectionLostBody = `Try to reconnect...` 
	MainConnection_ConnectionLostBodySec = `Try to reconnect in {0} sec.`
	MainConnection_ConnectionSuccessHeader = `Connection established`
	MainConnection_ConnectionSuccessBody = `Successfully connected!`

	//SelectSalesPeriod
	SelectSalesPeriod_FilterServices = 'Filter'

	// ESortPrice
	ESortPrice_AscendingPrice = 'Ascending'
	ESortPrice_DescendingPrice = 'Descending'

	// InventoryPage
	InventoryPage_SortedPriceTitle = 'Sorted price'
	InventoryPage_InventoryEmptyText = 'Empty inventory'
	InventoryPage_InventoryCountText = 'Inventory count'
	InventoryPage_InventoryTotalPrice = 'Total price in services'
	InventoryPage_InventoryPriceText = 'Total price'
	InventoryPage_InfoItem = 'Info by item'
	InventoryPage_CopyName = 'Copy name'
	InventoryPage_ServiceItemLink = 'Go to item'
	InventoryPage_SteamLinkInventory = 'Go to inventory'
	InventoryPage_UpdateInventory = 'Update'
	InventoryPage_MarketableText = 'Marketable'
}
