import { User } from "./user";

export class PauseResponse
{
    user: User
    status: EStatusPause
}

export enum EStatusPause {
    None,
    Success,
    AlreadyStopped,
    PauseNotAvailableByDelay,
    PauseNotAvailableBySub,
}