import { FC, useEffect } from 'react'
import styles from './css/TosPage.module.css'

const TosPage: FC = () => {
	useEffect(() => {
		document.title = 'vvTable - Terms of use'
	}, [])

	return (
		<div>
			<h1 className={styles.tosTitle}>Условия пользования</h1>
			<div className={styles.tosContent}>
				<div className={styles.tosTextContent}>
					Добро пожаловать на vvTable - интернет-ресурс (далее «Сайт»), который предоставляет инструменты для заработка в Steam. Это наши условия
					пользования Сайтом, доступ к которому Вы можете получить по адресу tablevv.com. Авторизовавшись Вы соглашаетесь с условиями и правилами
					пользования сайтом.
				</div>
				<div className={styles.tosTextContent}>
					Администрация Сайта предоставляет вам доступ к использованию Сайта vvTable и его функционала на условиях, являющихся предметов настоящих
					Правил пользования сайтом vvTable. Вам необходимо внимательно ознакомиться с условиями настоящих Правил, которые рассматриваются
					Администрацией Сайта как публичная оферта в соответствии со ст. 437 Гражданского кодекса РФ.
				</div>
				<div className={styles.tosParagraph}>1. Термины, используемые в настоящих Правилах</div>
				<div className={styles.tosTextContent}>
					1.1. Сайт vvTable – сервис с инструментами для трейда, размещенный в сети Интернет по адресу tablevv.com (включая все уровни указанного
					домена, как функционирующие на дату принятия Пользователем настоящих Правил, так и запускаемые и вводимые в эксплуатацию в течение всего
					срока его действия) и доступная Пользователю через сайт, представляющая собой результат интеллектуальной деятельности в форме программы для
					ЭВМ. Сервис представлен в объективной форме совокупностью данных и команд, и порождаемых аудиовизуальных отображений (включая входящие в ее
					состав графические изображения и пользовательский интерфейс) (далее «Данные и Команды»), предназначенных для функционирования ЭВМ в целях
					получения определенного результата в виде организации функционала сервиса. Совокупность данных и команд состоит из активированных и
					неактивированных данных и команд.
				</div>
				<div className={styles.tosTextContent}>
					1.2. Неактивированные данные и команды – данные, команды и порождаемые аудиовизуальные отображения, позволяющие увеличить количество
					виртуальных ценностей, используемых в рамках функциональных возможностей Сайта.
				</div>
				<div className={styles.tosTextContent}>
					1.3. Платная услуга - услуга приобретенная за денежные средства.
				</div>
				<div className={styles.tosTextContent}>
					1.4. Бан, Забанить - блокировка аккаунта и платных услуг навсегда.
				</div>
				<div className={styles.tosParagraph}>2. Регистрация</div>
				<div className={styles.tosTextContent}>
					2.1. Регистрация Пользователя на Сайте является бесплатной, добровольной и производится по адресу в сети Интернет tablevv.com
				</div>
				<div className={styles.tosTextContent}>
					2.2. Пользователем Сайта является физическое лицо, зарегистрированное на Сайте в соответствии с установленным настоящими Правилами порядком,
					достигшее возраста, допустимого в соответствии с законодательством РФ для акцепта настоящих Правил, и обладающее соответствующими
					полномочиями (ранее и далее «Пользователь»).
				</div>
				<div className={styles.tosTextContent}>
					2.3. При регистрации Пользователь соглашается с настоящими Правилами и принимает на себя указанные в них права и обязанности, связанные с
					использованием и функционированием Сайта. Пользователь приобретает полный доступ к использованию функционала Сайта (его данных и команд), за
					исключением неактивированных данных и команд. Право использования неактивированных данных и команд предоставляется Пользователю при оплате
					соответствующих объектов.
				</div>
				<div className={styles.tosTextContent}>
					2.4. Пользователь не имеет права передавать данные от аккаунта третьим лицам, несет полную ответственность за их сохранность, самостоятельно
					выбирая способ их хранения.
				</div>
				<div className={styles.tosTextContent}>
					2.5. Пользователь не имеет права делится Платными услугами аккаунта с другими Пользователями. При использовании одной Платной услуги аккаунта более чем одним Пользователем Администратор в праве Забанить Пользователя.
				</div>
				<div className={styles.tosTextContent}>
					2.6. Если Пользователь считает, что получил Бан несправедливо он вправе обратиться в поддержку.
				</div>
				<div className={styles.tosParagraph}>3. Лицензия на использование программных продуктов</div>
				<div className={styles.tosTextContent}>
					3.1. Использование программных продуктов и/или сайта разрешено строго на условиях настоящей Лицензии. Если пользователь не принимает условия
					Лицензии в полном объеме, пользователь не имеет права использовать Программные Продукты и/или сайт в каких-либо целях. Использование
					программных продуктов и/или сайта с нарушением какого-либо пункта из условий Лицензии запрещено.
				</div>
				<div className={styles.tosTextContent}>
					3.2. Правообладатель вправе размещать в программных продуктах и/или на сайте vvTable любые информационные и/или рекламные материалы и ссылки
					на интернет-сайты третьих лиц, а также предоставлять право использования программных продуктов и/или сайта vvTable указанным способом
					третьим лицам по отдельному соглашению.
				</div>
				<div className={styles.tosParagraph}>4. Статус Правил пользования Сайтом vvTable</div>
				<div className={styles.tosTextContent}>
					4.1. Настоящие Правила пользования Сайтом vvTable (ранее и далее «Правила») разработаны Администрацией Сайта и определяют условия
					использования и развития Сайта, а также права и обязанности его Пользователей и Администрации. Правила распространяются также на отношения,
					связанные с правами и интересами третьих лиц, не являющимися Пользователями Сайта, но чьи права и интересы могут быть затронуты в результате
					действий Пользователей Сайта.
				</div>
				<div className={styles.tosTextContent}>
					4.2. Настоящие Правила являются юридически обязательным соглашением между Пользователем и Администрацией Сайта, предметом которого является
					предоставление Администрацией Сайта Пользователю доступа к использованию Сайта и его функционала. Помимо настоящих Правил, к соглашению
					между Пользователем и Администрацией Сайта относятся все специальные документы, регулирующие предоставление доступа к использованию
					отдельного функционала Сайта (в том числе его неактивированных данных и команд), размещенного в соответствующих разделах Сайта в сети
					Интернет.
				</div>
				<div className={styles.tosTextContent}>
					4.3. Пользователь обязан полностью ознакомиться с настоящими Правилами до момента регистрации на Сайте. Регистрация Пользователя на Сайте
					означает полное и безоговорочное принятие Пользователем настоящих Правил в соответствии со ст. 438 Гражданского кодекса РФ.
				</div>
				<div className={styles.tosTextContent}>
					4.4. Настоящие Правила могут быть изменены и/или дополнены Администрацией Сайта в одностороннем порядке без какого-либо специального
					уведомления. Настоящие Правила являются открытым и общедоступным документом. Действующая редакция Правил располагается в сети Интернет по
					адресу tablevv.com/tos. Администрация Сайта рекомендует Пользователям регулярно проверять условия настоящих Правил на предмет их изменения
					и/или дополнения. Продолжение использования Сайта Пользователем после внесения изменений и/или дополнений в настоящие Правила означает
					принятие и согласие Пользователя с такими изменениями и/или дополнениями.
				</div>
				<div className={styles.tosParagraph}>5. Статус Сайта vvTable</div>
				<div className={styles.tosTextContent}>
					5.1. Права на Сайт в целом и на использование сетевого адреса (доменного имени) tablevv.com принадлежат Администрации Сайта. Последняя
					предоставляет доступ к Сайту всем заинтересованным лицам в соответствии с настоящими Правилами.
				</div>
				<div className={styles.tosTextContent}>
					5.2. Настоящими Правилами установлены условия, в соответствии с которыми права на использование информации и результатов интеллектуальной
					деятельности в составе отдельных разделов Сайта, могут принадлежать Пользователям Сайта и иным лицам, самостоятельно создавшим указанные
					объекты на Сайте.
				</div>
				<div className={styles.tosParagraph}>6. Отказ от ответственности</div>
				<div className={styles.tosTextContent}>
					6.1. Сайт не несет ответственности за любые убытки, причиненные в результате ваших действий/бездействий, в результате просмотра, чтения или
					прослушивания контента сайта или какой-либо ее части.
				</div>
				<div className={styles.tosTextContent}>
					6.2. Сайт не несет ответственности за ущерб, причиненный пользователю вследствие неумения или ненадлежащего использования программных
					продуктов, приобретенных на сайте, а так же любых других второстепенных воздействий, усложняющих работу с программными продуктами сайта.
				</div>
				<div className={styles.tosParagraph}>7. Оплата программных продуктов</div>
				<div className={styles.tosTextContent}>
					7.1. Для получения доступа к платным функциям сайта или программных продуктов, пользователю необходимо произвести оплату виртуальной валютой
					в виде предмета из игры Team Fortress 2 - Mann Co. Supply Crate Key.
				</div>
				<div className={styles.tosTextContent}>
					7.2. Функционал сайта обязан дать мгновенный доступ к оплаченной функции на установленный перед оплатой срок.
				</div>
				{/* <div className={styles.tosTextContent}>
					7.3. При покупке подписки уровнем выше, тем текущая - оставшееся время текущей подписки переносится пропорционально цене на покупаемую
					подписку.
				</div> */}
				<div className={styles.tosParagraph}>8. Возврат программных продуктов</div>
				<div className={styles.tosTextContent}>
					8.1. В случае, если после оплаты, доступ к оплаченным функциям не был получен, покупатель вправе написать письмо в поддержку с целью
					возврата средств.
				</div>
				<div className={styles.tosTextContent}>
					8.2. Пользователь вправе запросить полный возврат, если с момента внесения средств на сайт прошло менее 48 часов.
				</div>
				<div className={styles.tosTextContent}>8.3. Использованные средства на платный функционал возврату не подлежат.</div>
				<div className={styles.tosParagraph}>9. Гарантии и ответственность</div>
				<div className={styles.tosTextContent}>
					9.1. Если сторонние ресурсы внесут изменения, или произойдут любые другие события влияющие на работу Программных Продуктов сайта,
					администрация программных продуктов, гарантируют восстановление работоспособности.
				</div>
				<div className={styles.tosTextContent}>
					9.2. В случае, если восстановить работоспособность продуктов не получилось, то возможен возврат неиспользованных средств.
				</div>
			</div>
		</div>
	)
}

export default TosPage
