import { checkItemReducer } from './checkItemReducer'
import { tableReducer } from './tableReducer'
import { profileReducer } from './profileReducer'
import { loginReducer } from './loginReducer'
import { confirmationReducer } from './confirmationReducer'
import { homeReducer } from './homeReducer'
import { loadingReducer } from './loadingReducer'
import { langReducer } from './langReducer'
import { userReducer } from './userReducer'
import { feesReducer } from './feesReducer'
import { nyReducer } from './nyReducer'
import { combineReducers } from 'redux'
import { filterSalesReducer } from './filterSalesReducer'
import { inventoryFilterReducer } from './inventoryReducer'

export const rootReducer = combineReducers({
	user: userReducer,
	lang: langReducer,
	loading: loadingReducer,
	home: homeReducer,
	confirmation: confirmationReducer,
	login: loginReducer,
	profile: profileReducer,
	fees: feesReducer,
	table: tableReducer,
	checkItem: checkItemReducer,
	ny: nyReducer,
	filterSales: filterSalesReducer,
	inventoryFilter: inventoryFilterReducer
})

export type RootState = ReturnType<typeof rootReducer>
