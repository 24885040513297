import { ENyActionTypes, INyState, NyAction } from './../../types/redux/ny'

const initialState: INyState = {
	disabled: false,
}

export const nyReducer = (state: INyState = initialState, action: NyAction): INyState => {
	switch (action.type) {
		case ENyActionTypes.SET_NY:
			return {
				disabled: action.payload,
			}
		case ENyActionTypes.LOAD_NY_FROM_COOKIE:
			return {
				disabled: action.payload,
			}
		default:
			return state
	}
}
