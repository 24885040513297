import { EFilterSalesActionTypes } from '../../types/redux/filterSales'

export const showFilterSales = () => {
	return {
		type: EFilterSalesActionTypes.SHOW_FILTER_SALES
	}
}

export const closeFilterSales = () => {
	return {
		type: EFilterSalesActionTypes.CLOSE_FILTER_SALES,
	}
}
