import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import Loading from './components/Loading'
import LoadingScreen from './components/LoadingScreen'
import { useActions } from './hooks/useAction'
import { useTypedSelector } from './hooks/useTypedSelector'
import style from './App.module.css'
import PreviewPage from './pages/PreviewPage'
import NotFoundPage from './pages/NotFoundPage'
import { compose } from 'redux'
import Confirmation from './components/Confirmation'
import Login from './models/login'
import LoginForm from './components/LoginForm'
import ProfilePage from './pages/ProfilePage'
import SubPage from './pages/SubPage'
import cookie from './models/cookie'
import SupportPage from './pages/SupportPage'
import SettingsPage from './pages/SettingsPage'
import { TelegramAuth, VkAuth } from './types/user/userInfo'
import FaqPage from './pages/FaqPage'
import TosPage from './pages/TosPage'
import TablePage from './pages/TablePage'
import { hubConnection } from './signalR/mainConnection'
import PromoCsMoney from './components/PromoCsMoney'
import PaymentsPage from './pages/BalancePage'
import InventoryPage from './pages/InventoryPage'
import { ContextMenuProvider } from './context'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
		blaz: {
			changeFade: (className: string, to: boolean, time: number) => void
			markToScroll: (page: number, selector: string) => void
			scrollToMark: (page: number) => void
			CopyToClipboard: (value: string) => void
		}
		tableJs: {
			updateAnim: () => void
			showSalesHover: (id: number, className: string) => void
			hideSalesHover: (id: number, className: string) => void
		}
		initTelegramContext: (userAuth: (data: TelegramAuth) => Promise<void>) => void
		initTelegramButton: () => void
		VK: {
			initContext: (userAuth: (data: VkAuth) => Promise<void>) => void
			init: (options: { apiId: number }) => void
		}
	}

	interface Array<T> {
		diff: (this: Array<T>, arr: Array<T>) => Array<T>
	}
}

Array.prototype.diff = function <T>(this: Array<T>, arr: Array<T>): Array<T> {
	return this.filter(x => !arr.includes(x))
}

const FADE_TIME: number = 250

export function SetLoadingScreen(setLoading: (value: boolean) => {}, value: boolean, anim: boolean = true) {
	if (value) {
		setLoading(true)

		document.body.style.overflow = 'hidden'
	} else {
		document.body.style.overflow = null

		if (anim) {
			window.blaz.changeFade('fade-screen', true, 300)
		}

		const fadeTime = anim ? FADE_TIME : 0

		setTimeout(() => {
			setLoading(false)
		}, fadeTime)
	}
}

export function ShowLoadingScreen(setLoading: (value: boolean) => {}) {
	SetLoadingScreen(setLoading, true)

	window.setTimeout(() => {
		SetLoadingScreen(setLoading, false)
	}, 500)
}

// const promoPages = ['/', '/sub', '/support', '/settings']

function App() {
	const { loading, loaded, user } = useTypedSelector(x => x.user)
	const { loading: appLoading, loadingScreen } = useTypedSelector(x => x.loading)
	const { active: isLoginNeeded } = useTypedSelector(x => x.login)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)
	const { fetchUser, showLoginForm, loadLanguageFromCookie, loadNyFromCookie, setUser } = useActions()
	const location = useLocation()
	const navigate = useNavigate()

	const [promo, setPromo] = useState(false)

	useEffect(() => {
		// CHECK FOR LOGIN
		if (loaded) {
			window.scrollTo(0, 0)
			const loginNeeded = Login.CheckForLogin(location.pathname + location.hash, location.pathname, user)
			if (loginNeeded && location.hash !== '#banned') {
				showLoginForm(e => {
					if (e) {
						window.location.href = '/'
					} else {
						navigate(Login.GetCurrentUrl())
					}
				})
			}
		}
	}, [location.pathname, loaded])

	// check for banned
	useEffect(() => {
		if (loaded) {
			if (location.hash === '#banned') {
				location.hash = null
				navigate('/')
			}
		}
	}, [location.hash])

	useEffect(() => {
		const langId = Number.parseInt(cookie.GetCookie('lang')) || 0
		loadLanguageFromCookie(langId)
		loadNyFromCookie()

		fetchUser()
	}, [])

	useEffect(() => {
		if (loaded) {
			hubConnection.start(user, lang, setUser)
		}
	}, [loaded])

	useEffect(() => {
		if (loaded) {
			hubConnection.update(user, lang)
		}
	}, [user,lang])

	useEffect(() => {
		// const newValue = promoPages.includes(window.location.pathname)
		// setPromo(newValue)

		setPromo(true)
	})

	return (
		<React.Fragment>
			<ContextMenuProvider>
				<div className={style.page}>
					<div className={style.main}>
						<div id='free-space' className={style.freeSpace}></div>
						<div className='notify-list'></div>
						<Header />
						{promo ? <PromoCsMoney /> : undefined}
						<div className='content px-4' style={{ paddingTop: promo ? '3rem' : '1rem' }}>
							{!isLoginNeeded ? (
								<Routes>
									<Route path='/' element={<PreviewPage />} />
									<Route path='/faq' element={<FaqPage />} />
									<Route path='/tos' element={<TosPage />} />
									{user && [
										<Route path='/profile' element={<ProfilePage />} key={0} />,
										<Route path='/table' element={<TablePage />} key={1} />,
										<Route path='/inventory' element={<InventoryPage />} key={2} />,
										<Route path='/sub' element={<SubPage />} key={3} />,
										<Route path='/balance' element={<PaymentsPage />} key={4} />,
										<Route path='/support' element={<SupportPage />} key={5} />,
										<Route path='/settings' element={<SettingsPage />} key={6} />,
									]}
									<Route path='*' element={<NotFoundPage />} />
								</Routes>
							) : (
								<Routes>
									<Route path='*' element={<div></div>} />
								</Routes>
							)}
						</div>
						<Footer />
						<Confirmation />
						<LoginForm />
					</div>
				</div>
				<Loading active={loading || !loaded || loadingScreen} force={true} />
				{appLoading && <LoadingScreen />}
			</ContextMenuProvider>
		</React.Fragment>
	)
}

export default App
