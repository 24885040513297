import { ECheckItemActionTypes } from './../../types/redux/checkItem'

export const showCheckItem = (data: number) => {
	return {
		type: ECheckItemActionTypes.SHOW_CHECK_ITEM,
		payload: data,
	}
}

export const closeCheckItem = () => {
	return {
		type: ECheckItemActionTypes.CLOSE_CHECK_ITEM,
	}
}
