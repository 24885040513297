import { ServicesFee } from './../table/requestData'
import { EServices } from '../tableHelper'

export interface IFeesState {
	loaded: boolean
	data: Object
	inputsFee: Object
	inputsBonus: Object
}

export enum EFeesActionTypes {
	FEES_SET_DATA = 'FEES_SET_DATA',
	FEES_CHANGE_ITEM = 'FEES_CHANGE_ITEM',
	FEES_SET_INPUT_FEE = 'FEES_SET_INPUT_FEE',
	FEES_SET_INPUT_BONUS = 'FEES_SET_INPUT_BONUS',
}

interface IFeesSetDataAction {
	type: EFeesActionTypes.FEES_SET_DATA
	payload: Object
}

interface IFeesChangeItemAction {
	type: EFeesActionTypes.FEES_CHANGE_ITEM
	payload: { app: number; service: EServices; data: ServicesFee }
}

interface IFeesSetInputFeeAction {
	type: EFeesActionTypes.FEES_SET_INPUT_FEE
	payload: { app: number; service: EServices; data: string }
}

interface IFeesSetInputBonusAction {
	type: EFeesActionTypes.FEES_SET_INPUT_BONUS
	payload: { app: number; service: EServices; data: string }
}

export type FeesAction = IFeesSetDataAction | IFeesChangeItemAction | IFeesSetInputFeeAction | IFeesSetInputBonusAction
