import { CSSProperties, FC, ReactNode } from 'react'

export interface DropdownProps {
	children?: ReactNode
	clickable?: boolean
	style?: CSSProperties
	title?: string
}

const Dropdown: FC<DropdownProps> = ({ children, clickable = true, style, title = undefined }) => {
	return (
		<div
			className={`dropdown${clickable ? ' clickable' : undefined}${title ? ' with-title' : undefined}`}
			onClick={e => {
				if (clickable) {
					const t = e.target as HTMLDivElement
					if (t.classList.contains('no-close')) return

					const isOpen = e.currentTarget.classList.contains('show')

					document.querySelectorAll('.dropdown.show').forEach(x => {
						x.classList.remove('show')
						window.dispatchEvent(new Event('dropdown_closed'))
					})

					if (!isOpen) {
						e.currentTarget.classList.add('show')
					}
				}
			}}
			style={style}
			data-title={title}
		>
			{children}
		</div>
	)
}

export default Dropdown
