import { EnglishLang } from './englishLang'
import { RussianLang } from './russianLang'
import { ILanguage } from './language'

export class Language {
	Title: string
	Name: string
	Value: ILanguage
}

export default class LangController {
	static Current: number = 0
	static Items: Language[] = [
		{ Title: 'RU', Name: 'Русский', Value: new RussianLang() },
		{ Title: 'EN', Name: 'English', Value: new EnglishLang() },
	]
	static GetCurrent(): Language {
		return this.Items[this.Current]
	}
}
