import { EPriceType } from './../../../types/tableHelper'
import LangController from '../../language/langController'
import TimeExtensions from '../../timeExtensions'
import Currency from '../../currency'

export default class TableExtensions {
	static AppIds(): number[] {
		return [730, 570, 440, 252490]
	}

	static GetTime(unixSeconds: number): string {
		let sec = Math.ceil(Date.now() / 1000 - unixSeconds)

		if (sec < 0) sec = 0

		if (sec < 60) {
			return `${TimeExtensions.GetTotalSeconds(sec)} ${LangController.GetCurrent().Value.TablePage_Seconds}`
		} else if (sec < 3600) {
			return `${TimeExtensions.GetTotalMinutes(sec)} ${LangController.GetCurrent().Value.TablePage_Minutes}`
		} else if (sec < 86400) {
			return `${TimeExtensions.GetTotalHours(sec)} ${LangController.GetCurrent().Value.TablePage_Hours}`
		} else {
			return `${TimeExtensions.GetTotalDays(sec)} ${LangController.GetCurrent().Value.TablePage_Days}`
		}
	}

	static GetTimeFull(unixSeconds: number): string {
		if (unixSeconds === 0) return LangController.GetCurrent().Value.TablePage_LastUpdate_Never

		if (unixSeconds === Date.now() / 1000) return LangController.GetCurrent().Value.TablePage_LastUpdate_Now

		const ago = LangController.GetCurrent().Value.TablePage_LastUpdate_Ago

		return `${this.GetTime(unixSeconds)} ${ago}`
	}
}

export class TableOptions {
	updateTime: number

	constructor(updateTime: number) {
		this.updateTime = updateTime
	}
}

export class CheckItemsFilters {
	priceType: EPriceType
	currency: Currency

	constructor() {
		this.priceType = EPriceType.Normal
	}
}
