import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTypedSelector } from '../hooks/useTypedSelector'
import style from './css/Footer.module.css'

const Footer: FC = () => {
	const lang = useTypedSelector(x => x.lang).lang.Value
	const nyDisabled = useTypedSelector(x => x.ny.disabled)

	return (
		<footer>
			<div className={style.footerList}>
				<div className={`${style.itemsList} mr-0`}>
					<div className={style.brandLogo}>
						<Link aria-label='Home' title='vvTable' to='/' style={{ marginRight: '10px' }}>
							<div className='fill-main d-inline'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 211.64 138.19'>
									<polygon points='158.41 82.99 211.64 0.09 166.07 0.09 143.71 34.91 121.51 0.09 71.5 0.09 118.75 73.79 108.14 90.3 49.24 0.09 0 0 88.74 138.19 104.64 138.19 23.87 12.4 42.02 12.4 42.02 12.4 107.71 115.55 126.6 86.02 139.91 106.76 156.91 106.76 96.31 12.4 113.08 12.4 158.41 82.99' />
								</svg>
							</div>
						</Link>
					</div>
				</div>
				<div className={`${style.itemsList} text-center`}>
					<div>
						<div>© vvTable {new Date().getFullYear()}</div>
						<div>{lang.Footer_AllRightsReserved}</div>
					</div>
				</div>
				<div className={`${style.itemsList} text-left`}>
					<div>
						<Link to='tos' className={style.footerLink}>
							{lang.Footer_TermsOfUse}
						</Link>
					</div>
					<div>
						<a href='mailto:support@tablevv.com' className={style.footerLink}>
							support@tablevv.com
						</a>
					</div>
					<div>
						<a href='mailto:business@tablevv.com' className={style.footerLink}>
							business@tablevv.com
						</a>
					</div>
				</div>
				<div className={`${style.itemsList} ${style.footerLinks}`}>
					<div>
						<a href='https://t.me/vvtable' target='_blank' rel='nofollow'>
							<img src='Resources/Footer/telegram.png' />
						</a>
					</div>
					<div>
						<a href='https://vk.com/vvtable' target='_blank' rel='nofollow'>
							<img src='Resources/Footer/vk.png' />
						</a>
					</div>
				</div>
			</div>
			{/* {!nyDisabled ? (
				<div id='new-year'>
					<div className='ny-item' id='snowman' />
					<div className='ny-item' id='gift' />
				</div>
			) : undefined} */}
		</footer>
	)
}

export default Footer
