import { AxiosError } from 'axios'
import React, { FC } from 'react'
import { api } from '../../api/api'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Notification from '../../models/notification'
import { Sub } from '../../types/subscription/sub'
import { EResponseCode, SubscriptionBuyRequest, SubscriptionBuyResponse } from '../../types/subscription/subscription'
import { EServices, TableHelper } from '../../types/tableHelper'
import { ESubscription } from '../../types/user/user'
import styles from './SubInfo.module.css'
import Extensions from '../../models/extensions'
import { CurrencyData } from '../../types/currency/currency'

export interface SubInfoProps {
	sub: Sub
	active: boolean
	setActive: (value: boolean) => void
	onBought: () => void
}

const BaseServices = Extensions.GetEnumValues(EServices).filter(service => TableHelper.CheckServiceBySubscription(service, ESubscription.BaseSubscription))
const ProServices = Extensions.GetEnumValues(EServices).filter(service => TableHelper.CheckServiceBySubscription(service, ESubscription.ProSubscription))

const SubInfo: FC<SubInfoProps> = ({ sub, active, setActive, onBought }) => {
	const {
		current: currentLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const { user } = useTypedSelector(x => x.user)
	const { disabledServices: disabledServicesArray } = useTypedSelector(x => x.user.feed)
	const { showConfirmation, setUser } = useActions()

	const disabledServices = new Set(disabledServicesArray)

	if (!sub) return

	const subServices: EServices[] = {
		[ESubscription.BaseSubscription]: BaseServices,
		[ESubscription.ProSubscription]: ProServices,
	}[sub.sub]

	const buySub = () => {
		showConfirmation({
			text: lang.Confirmation_BuySub,
			action: async confirmed => {
				if (confirmed) {
					try {
						const response = await api.post<SubscriptionBuyResponse>('subscription/buy', { sub: sub.sub } as SubscriptionBuyRequest)
						const data = response.data
						if (!data) throw new Error()
						let ntf = lang.Notification_Error

						switch (data.code) {
							case EResponseCode.Success:
								ntf = lang.Notification_Success
								break
							case EResponseCode.NoBalance:
								ntf = lang.SubPageNotification_NoBalance
								break
							case EResponseCode.SubIsBetter:
								ntf = lang.SubPageNotification_SubIsBetter
								break
							case EResponseCode.SubPaused:
								ntf = lang.SubPageNotification_SubPaused
								break
						}

						Notification.ShowNotification(ntf)

						setActive(false)

						if (data.user) {
							setUser(data.user)
						}

						onBought?.call(this)
					} catch (error) {
						if (error instanceof AxiosError) {
							const err = error as AxiosError
							const data = err?.response?.data as SubscriptionBuyResponse

							const message: string = {
								[EResponseCode.NoBalance]: lang.SubPageNotification_NoBalance,
								[EResponseCode.SubIsBetter]: lang.SubPageNotification_SubIsBetter,
							}[data?.code]

							Notification.ShowNotification(message)
						} else {
							Notification.ShowNotification(lang.Notification_Error)
						}
					}
				}
			},
		})
	}

	const getPrice = (sub: Sub): number => {
		return sub.price - sub.discount
	}

	const price = getPrice(sub)

	const currencyList = [...CurrencyData.AllCurrencies.keys()]
	let currencyCodes = currencyList.join(', ')
	
	return (
		<div
			className={`${styles.subInfo} ${styles.flex}${active ? ` ${styles.subShow}` : ''}`}
			onClick={e => {	
				if (e.target['id'] === 'sub-popup') {
					setActive(false)
				}
			}}
			id='sub-popup'
			data-sub={sub.sub}
		>
			<div className={styles.subHeader}>
				<div className={`${styles.subTitle} ${styles.flexCenter}`}>
					<div>{{ 0: 'Подписка' }[currentLang] || 'Subscription'}:</div>
					<span>{sub.name}</span>
				</div>
				<div className={`${styles.subTitle} ${styles.flexCenter}`}>
					<div>{{ 0: 'Время' }[currentLang] || 'Time'}:</div>

					<span>
						{sub.sub !== ESubscription.NoSubscription
							? { 0: '30 дней' }[currentLang] || '30 days'
							: { 0: 'Бессрочно' }[currentLang] || 'Indefinitely'}
					</span>
				</div>
				<div className={`${styles.subTitle} ${styles.flexCenter}`}>
					<div>{{ 0: 'Цена' }[currentLang] || 'Price'}:</div>
					{price > 0 ? (
						<span>
							{price} x<img src='Resources/key_mini.png' alt='key' className={`${styles.imgKey} ml-1`} />
						</span>
					) : (
						<span>{{ 0: 'Бесплатно' }[currentLang] || 'Free'}</span>
					)}
				</div>
				<div className={`${styles.subTitle} ${styles.flexCenter} mt-2`}>
					<div>{{ 0: 'Описание' }[currentLang] || 'Description'}:</div>
				</div>
				<div className={`${styles.subDescription} ${styles.flexCol} mt-2`}>
					<div className={styles.descriptionItem}>
						<div className={styles.title}>{{ 0: 'Площадки' }[currentLang] || 'Services'}:</div>
						<div className={`${styles.content} ${styles.services}`}>
							{subServices?.map((service, i) => {
								const disabled = disabledServices?.has(service)

								return (
									<div
										key={i}
										data-disabled={disabled || undefined}
										title={disabled ? { 0: 'Отключен' }[currentLang] || 'Disabled' : undefined}
									>
										<img src={`Resources/ServicesIcons/${EServices[service].toLowerCase()}.png`} alt={EServices[service]} />
										{TableHelper.GetServiceName(service)}
									</div>
								)
							})}
						</div>
					</div>
					<div className={styles.descriptionItem}>
						<div className={styles.title}>{{ 0: 'Особенности' }[currentLang] || 'Peculiarities'}:</div>
						<div className={styles.content}>
							<ul style={{ paddingLeft: '25px' }}>
								{
									{
										[ESubscription.BaseSubscription]: (
											<React.Fragment>
												{{
													0: (
														<React.Fragment>
															<li>Безлимитные цены</li>
															<li>Отсутствие функции просмотра цены на всех сервисах</li>
															<li>Отображение периода продаж: только неделя</li>
															<li>Доступная валюта для просмотра цен: {`${currencyCodes}`}</li>
														</React.Fragment>
													),
												}[currentLang] || (
													<React.Fragment>
														<li>Unlimited prices</li>
														<li>Lack of price viewing function on all services</li>
														<li>Sales period display: week only</li>
														<li>Available currency for viewing prices: {`${currencyCodes}`}</li>
													</React.Fragment>
												)}
											</React.Fragment>
										),
										[ESubscription.ProSubscription]: (
											<React.Fragment>
												{{
													0: (
														<React.Fragment>
															<li>Безлимитные цены</li>
															<li>Функция просмотра цены на всех сервисах</li>
															<li>Отображение всех периодов продаж</li>
															<li>Доступная валюта для просмотра цен: {`${currencyCodes}`}</li>
														</React.Fragment>
													),
												}[currentLang] || (
													<React.Fragment>
														<li>Unlimited prices</li>
														<li>Function of viewing prices on all services</li>
														<li>Display of all sales periods</li>
														<li>Available currency for viewing prices: {`${currencyCodes}`}</li>
													</React.Fragment>
												)}
											</React.Fragment>
										),
									}[sub.sub]
								}
							</ul>
						</div>
					</div>
				</div>
				<div className={`${styles.btnPay} btn-hov d-inline-block`} onClick={buySub}>
					{user.access === sub.sub && sub.sub !== ESubscription.NoSubscription ? (
						<span>{{ 0: 'Продлить' }[currentLang] || 'Renew'}</span>
					) : (
						sub.sub !== ESubscription.NoSubscription && <span>{{ 0: 'Подписаться' }[currentLang] || 'Subscribe'}</span>
					)}
				</div>

				<div
					className={`btn-simple-hov ${styles.subClose}`}
					onClick={() => {
						setActive(false)
					}}
				>
					✖
				</div>
			</div>
		</div>
	)
}

export default SubInfo
