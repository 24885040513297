import { Language } from '../../models/language/langController'

export interface ILangState {
	lang: Language
	current: number
}

export enum ELangActionTypes {
	SET_LANG = 'SET_LANG',
	LOAD_LANG_FROM_COOKIE = 'LOAD_LANG_FROM_COOKIE',
}

interface ISetLangAction {
	type: ELangActionTypes.SET_LANG
	payload: number
}

interface ILoadLangFromCookieAction {
	type: ELangActionTypes.LOAD_LANG_FROM_COOKIE
	payload: number
}

export type LangAction = ISetLangAction | ILoadLangFromCookieAction
