import { ConfirmationData, EConfirmationActionTypes } from './../../types/redux/confirmation'

export const showConfirmation = (data: ConfirmationData) => {
	return {
		type: EConfirmationActionTypes.SHOW_CONFIRMATION,
		payload: data,
	}
}

export const closeConfirmation = () => {
	return {
		type: EConfirmationActionTypes.CLOSE_CONFIRMATION,
	}
}
