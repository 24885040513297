import { HomeData } from './../../types/home/homeData'
import { HomeAction, EHomeActionTypes } from './../../types/redux/home'
import { api } from './../../api/api'
import { Dispatch } from 'react'

export const fetchHome = (setLoadingScreen: (value: boolean) => {}) => {
	return async (dispatch: Dispatch<HomeAction>) => {
		try {
			dispatch({ type: EHomeActionTypes.FETCH_HOME })

			setLoadingScreen(true)

			const response = await api.get<HomeData>('home')

			dispatch({
				type: EHomeActionTypes.FETCH_HOME_SUCCESS,
				payload: response.data,
			})

			eval(`blaz.numberAnim('#items-total', ${response.data?.itemsTotal}, 1000)`)
		} catch (e) {
			dispatch({
				type: EHomeActionTypes.FETCH_HOME_ERROR,
				payload: 'Something went wrong!',
			})

			eval(`blaz.numberAnim('#items-total', ${100000}, 1000)`)
		} finally {
			setLoadingScreen(false)
		}
	}
}
