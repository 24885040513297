import React, { FC } from 'react'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Dropdown from '../Elements/Dropdown'
import styles from './css/TableSelectedFilter.module.css'
import { ESubscription } from '../../types/user/user'

export interface TableSelectedFilterProps {
	
}

const TableSelectedFilter: FC<TableSelectedFilterProps> = () => {
	const { filter } = useTypedSelector(x => x.table)
    const {
		user,
	} = useTypedSelector(x => x.user)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const { setTableFilter } = useActions()

	if (filter == null) return
    
	return (
		<Dropdown>
			<div className={`${styles.title} title`}>{lang.TablePage_Filters}</div>
			<div className='menu'>
                <React.Fragment>
                    <div
                        className={`${styles.menuItem} menu-item no-close`}
                        onClick={() => {
                            filter.autoReset = !filter.autoReset
                            setTableFilter(filter, true, true)
                        }}>
                        {filter.autoReset ? (
                            <i className='fa fa-check' aria-hidden='true'></i>
                        ) : undefined }{' '}
                        {lang.TablePage_AutoReset}
                    </div>
                    {
                        user.access == ESubscription.ProSubscription 
                        ?  
                        <React.Fragment>
                            <div
                                className={`${styles.menuItem} menu-item no-close`}
                                onClick={() => {
                                    filter.inInventory = !filter.inInventory
                                    setTableFilter(filter, true, true)
                                }}>
                                {filter.inInventory ? (
                                    <i className='fa fa-check' aria-hidden='true'></i>
                                ): undefined }{' '}
                                {lang.TablePage_InInventory}
                            </div>
                        </React.Fragment>
                        : <React.Fragment>
                            <div className={`${styles.menuItem} menu-item no-close ${styles.noAccess}`}>
                                <div className='d-inline-block no-close'>
                                    <span>{lang.TablePage_InInventory}</span>
                                    <div className={`d-inline-block ${styles.noAccessPre} no-close`}>Pro</div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    <div
                        className={`${styles.menuItem} menu-item no-close`}
                        onClick={() => {
                            filter.withSound = !filter.withSound
                            setTableFilter(filter, true, true)
                        }}>
                        {filter.withSound ? (
                            <i className='fa fa-check' aria-hidden='true'></i>
                        ) : undefined }{' '}
                        {lang.TablePage_Sound}
                    </div>
                </React.Fragment>
			</div>
		</Dropdown>
	)
}

export default TableSelectedFilter
