import * as UserActionCreators from './user'
import * as LangActionCreators from './lang'
import * as LoadingActionCreators from './loading'
import * as HomeActionCreators from './home'
import * as ConfirmationActionCreators from './confirmation'
import * as LoginActionCreators from './login'
import * as ProfileActionCreators from './profile'
import * as FeesActionCreators from './fees'
import * as TableActionCreators from './table'
import * as CheckItemActionCreators from './checkItem'
import * as NyActionCreators from './ny'
import * as FilterSalesActionCreators from './filterSales'
import * as InventoryFilterActionCreators from './inventoryFilter'

export default {
	...UserActionCreators,
	...LangActionCreators,
	...LoadingActionCreators,
	...HomeActionCreators,
	...ConfirmationActionCreators,
	...LoginActionCreators,
	...ProfileActionCreators,
	...FeesActionCreators,
	...TableActionCreators,
	...CheckItemActionCreators,
	...NyActionCreators,
	...FilterSalesActionCreators,
	...InventoryFilterActionCreators,
}
