import { ENyActionTypes } from './../../types/redux/ny'
import cookie from '../../models/cookie'

const cookieName = 'ny-disabled'

export const setNy = (disabled: boolean) => {
	if (disabled) {
		cookie.SetCookie(cookieName, disabled.toString(), 3000)
	} else {
		cookie.DeleteCookie(cookieName)
	}

	return {
		type: ENyActionTypes.SET_NY,
		payload: disabled,
	}
}

export const loadNyFromCookie = () => {
	const disabledStr = cookie.GetCookie(cookieName)

	const disabled: boolean = disabledStr === 'true'

	return {
		type: ENyActionTypes.LOAD_NY_FROM_COOKIE,
		payload: disabled,
	}
}
