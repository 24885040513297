import { CheckItemAction, ECheckItemActionTypes, ICheckItemState } from './../../types/redux/checkItem'

const initialState: ICheckItemState = {
	active: false,
	itemId: 0,
}

export const checkItemReducer = (state: ICheckItemState = initialState, action: CheckItemAction): ICheckItemState => {
	switch (action.type) {
		case ECheckItemActionTypes.SHOW_CHECK_ITEM:
			return {
				active: true,
				itemId: action.payload,
			}
		case ECheckItemActionTypes.CLOSE_CHECK_ITEM:
			return {
				active: false,
				itemId: 0,
			}
		default:
			return state
	}
}
