export interface IAppConfig {
	baseUrl: string
	TelegramBot: string
	VkAPI: number
}

export const config: IAppConfig = {
	baseUrl: 'api/',
	TelegramBot: 'vvtable_bot',
	VkAPI: 7981235,
}
