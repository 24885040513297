import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './ProductCardBox.module.css'

export interface ProductCardBoxProps {
	children: JSX.Element
	className?: string
}

export const CardClassName = 'bfdm512bs'

const ProductCardBox: FC<ProductCardBoxProps> = ({ children, className }: ProductCardBoxProps) => {
	const boxRef = useRef<HTMLDivElement>()

    const [ hiddenElemCount, setHiddenElemCount ] = useState(0)
	const [ cardClass, setCardClass ] = useState('')

	useEffect(() => {
		window.addEventListener('resize', () => {
            calc()
        }, false)
	}, [])

	useEffect(() => {
		if (!children)
			return

		calc()
	}, [ children ])

	const calc = () => {
        if (!boxRef?.current) 
			return

		const items = [...boxRef.current.childNodes].filter(x => (x as HTMLElement).classList.contains(CardClassName))

		if (items?.length > 0 !== true) {
			setHiddenElemCount(0)
			return
		}

		const firstElem = items[0] as HTMLElement
		setCardClass(firstElem?.className?.replace(CardClassName, ''))

		let offset = 0, count = 0

		let success = false
		for (let i = 0; i < items.length; i++) {
			const elem = items[i] as HTMLDivElement

			if (offset > 0) {
				if (offset !== elem.offsetTop) {
					const free = items.length % count
					if (free > 0) {
						const newToAdd = count - free
						setHiddenElemCount(newToAdd)
					}
					else { 
						setHiddenElemCount(0)
					}
					success = true
					break
				}
			}

			offset = elem.offsetTop
			count++
		}

		if (!success) {
			const newToAdd = Math.floor(boxRef.current.clientWidth / firstElem.clientWidth) - count
			if (!isNaN(newToAdd)) {
				setHiddenElemCount(Math.max(0, newToAdd))
			}
		}
    }

	return (
		<div className={`${styles.productCardBox} ${className}`} ref={boxRef}>
			{children}
			{
				[...Array(hiddenElemCount).keys()].map((x,i) => 
					<div className={cardClass} data-hidden='' key={i} />
				)
			}
		</div>
	)
}

export default ProductCardBox