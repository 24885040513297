import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { EServices } from '../types/tableHelper'
import styles from './css/PreviewPage.module.css'
import { useActions } from '../hooks/useAction'

const PreviewPage: FC = () => {
	const { data, loading, loaded } = useTypedSelector(x => x.home)
	const { fetchHome, setLoadingScreen } = useActions()

	useEffect(() => {
		document.title = 'vvTable - Trade table for CS2, Dota 2, Tf2 and Rust'

		if (!loaded && !loading) {
			fetchHome(setLoadingScreen)
		}
	}, [])
	const navigate = useNavigate()
	const lang = useTypedSelector(x => x.lang).lang.Value
	const { user } = useTypedSelector(x => x.user)

	const tiers = new Map<number, EServices[]>()

	tiers.set(1, [EServices.Buff, EServices.Steam])
	tiers.set(2, [EServices.CSMoney, EServices.Bitskins])
	tiers.set(3, [EServices.TM, EServices.DMarket])

	const btnStartHandle = () => {
		if (user == null) {
			window.location.href = `api/login?return_to=${window.location.origin}/table`
		} else {
			navigate('table')
		}
	}

	const bannerJsx =
		user == null ? (
			<div className={styles.bannerNew}>
				<div className={styles.bannerContainer}>
					<div className={styles.banner}>
						<div>
							<div className={styles.bannerText}>
								<h2 className={styles.bannerTitle}>{lang.PreviewPage_Banner_Title}</h2>
								<p className={styles.bannerDesc}>{lang.PreviewPage_Banner_Description}</p>
							</div>
							<div className={styles.bannerStart}>
								<div className={styles.btnStart} onClick={btnStartHandle}>
									{lang.PreviewPage_BtnStart}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.bannerImg}>
						<img src='Resources/dragon-lore.png' alt='banner-img' />
					</div>
				</div>
			</div>
		) : null

	return (
		<div className={styles.pageContainer}>
			<div className={styles.box}>
				{Array.from(tiers).map(([key, tier]) =>
					tier.map((v, i) => (
						<div key={i} className={`${styles.circle} ${styles[`circle_${key}`]}`} data-idx={i} data-max={tier.length}>
							<div className={styles.service}>
								<img src={`Resources/ServicesIcons/${EServices[v].toLowerCase()}.png`} />
							</div>
						</div>
					))
				)}
				<div className={styles.prContent}>
					<h2 className={styles.prTitle}>{lang.PreviewPage_Box_Title}</h2>
					<h1 className={styles.prSubTitle}>{lang.PreviewPage_Box_SubTitle}</h1>
					<div className={styles.btnStart} onClick={btnStartHandle}>
						{lang.PreviewPage_BtnStart}
					</div>
					<p className={styles.prItemsTotal}>
						<span className={`${styles.prItemsValue} d-block`} id='items-total' data-error={!data?.itemsTotal ? '1' : undefined}>
							{data?.itemsTotal?.toLocaleString('en-US') ?? 100000?.toLocaleString('en-US')}
						</span>
						<span className={`${styles.prItemsTitle} d-block`}>{lang.PreviewPage_TotalItems_Title}</span>
					</p>
				</div>
			</div>
			<div className={styles.advantagesList}>
				<div className={styles.advantage}>
					<div className={styles.advItem}>
						<h2 className={styles.advTitle}>{lang.PreviewPage_Adv_Title_1}</h2>
						<p className={styles.advDesc}>{lang.PreviewPage_Adv_Description_1}</p>
					</div>
					<div className={styles.advImg}>
						<img src='Resources/preview/adv-new-1.png' alt='advantage image' />
					</div>
				</div>
				<div className={styles.advantage} data-right=''>
					<div className={styles.advImg}>
						<img src='Resources/preview/adv-new-2.png' alt='advantage image' />
					</div>
					<div className={styles.advItem}>
						<h2 className={styles.advTitle}>{lang.PreviewPage_Adv_Title_2}</h2>
						<p className={styles.advDesc}>{lang.PreviewPage_Adv_Description_2}</p>
					</div>
				</div>
				<div className={styles.advantage}>
					<div className={styles.advItem}>
						<h2 className={styles.advTitle}>{lang.PreviewPage_Adv_Title_3}</h2>
						<p className={styles.advDesc}>{lang.PreviewPage_Adv_Description_3}</p>
					</div>
					<div className={styles.advImg}>
						<img src='Resources/preview/adv-new-3.png' alt='advantage image' />
					</div>
				</div>
			</div>
			{/* <div className={styles.prTrustContent}>
				<h2 className={styles.prTrustContentTitle}>
					{lang.PreviewPage_TrustContent_Title}
				</h2>
				<div className={styles.contentMarquee}>
					<div className={styles.contentMarqueeLeft}>
						<div className={styles.marqueeContainer}>
							<div className={styles.overlay}></div>
							<div className={styles.marquee}>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
							</div>
							<div className={styles.marquee}>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
								<div className={styles.marqueeItem}>I Love vvTable</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{bannerJsx}
		</div>
	)
}

export default PreviewPage
