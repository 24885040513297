import { ELoadingActionTypes } from './../../types/redux/loading'

export const setLoading = (value: boolean) => {
	return {
		type: ELoadingActionTypes.SET_LOADING,
		payload: value,
	}
}

export const setLoadingScreen = (value: boolean) => {
	return {
		type: ELoadingActionTypes.SET_LOADING_SCREEN,
		payload: value,
	}
}
