import { ReferralsGet } from './../referral/referral'

export interface IProfileState {
	referrals: ReferralsGet
	loading: boolean
	loaded: boolean
}

export enum EProfileActionTypes {
	FETCH_PROFILE = 'FETCH_PROFILE',
	FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS',
	FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR',
	SET_PROFILE_REFERRALS = 'SET_PROFILE_REFERRALS',
}

interface IFetchProfileAction {
	type: EProfileActionTypes.FETCH_PROFILE
}

interface IFetchProfileSuccessAction {
	type: EProfileActionTypes.FETCH_PROFILE_SUCCESS
	payload: ReferralsGet
}

interface IFetchProfileErrorAction {
	type: EProfileActionTypes.FETCH_PROFILE_ERROR
	payload: ReferralsGet
}

interface ISetProfileReferralsAction {
	type: EProfileActionTypes.SET_PROFILE_REFERRALS
	payload: ReferralsGet
}

export type ProfileAction =
	| IFetchProfileAction
	| IFetchProfileSuccessAction
	| IFetchProfileErrorAction
	| ISetProfileReferralsAction
