export interface INyState {
	disabled: boolean
}

export enum ENyActionTypes {
	SET_NY = 'SET_NY',
	LOAD_NY_FROM_COOKIE = 'LOAD_NY_FROM_COOKIE',
}

interface ISetNyAction {
	type: ENyActionTypes.SET_NY
	payload: boolean
}

interface ILoadNyFromCookieAction {
	type: ENyActionTypes.LOAD_NY_FROM_COOKIE
	payload: boolean
}

export type NyAction = ISetNyAction | ILoadNyFromCookieAction
