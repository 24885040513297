import { FC } from 'react'

const LoadingSpinner: FC = () => {
	return (
		<div id='load-small'>
			<i className='demo-icon icon-spin6 animate-spin'></i>
		</div>
	)
}

export default LoadingSpinner
