import { CSSProperties, FC, useState } from 'react'

export interface InputFloatProps {
	className?: string
	style?: CSSProperties
	placeholder?: string
	defaultValue: number
	onValueChange: (value: number) => void
}

const InputFloat: FC<InputFloatProps> = ({ className, style, placeholder, defaultValue, onValueChange }) => {
	const [text, setText] = useState(defaultValue.toString())

	return (
		<input
			placeholder={placeholder}
			className={className}
			style={style}
			type='number'
			onKeyPress={e => {
				if (e.key === 'e') {
					e.preventDefault()
				} else if (e.key === 'Enter') {
					const elem = e.target as HTMLElement
					elem.blur()
				}
			}}
			value={text}
			onChange={e => setText(e.target.value)}
			onBlur={e => {
				const value = Number.parseFloat(text.replace(',', '.'))
				if (isNaN(value)) {
					setText(defaultValue?.toString())
				} else {
					onValueChange?.call(this, value)
				}
			}}
		/>
	)
}

export default InputFloat
