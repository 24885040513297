import { Apps } from '../apps'
import Extensions from '../models/extensions'
import { ESubscription } from './user/user'

export enum EServices {
	Steam,
	CSDeals,
	CSMoney,
	CSTrade,
	LF,
	Swap,
	Tradeit,
	TradeSkinsFast,
	Bitskins,
	Buff, // pro
	C5game, // pro
	CSDealsMarket,
	DMarket,
	Igxe, // pro
	Shadowpay, // pro
	Skinbaron, // pro
	Skinport, // pro
	TM,
	Waxpeer, // pro
	BuffMarket, // pro
	VMarket, // pro
	SkinsMonkey,
	SkinSwap,
	CsFail, // secret
	LisSkins, // pro
	SwapMarket,
	BitskinsP2P, // pro
	CsFloat, // pro
	TMNew, 
	CsMoneyMarket, // pro
	ITrade,
	SkinoutMarket, // pro
	WhiteMarket, // pro
	DMarketP2P, // pro
}

export enum ESalesPeriod {
	Week,
	Day,
	Month,
	Month3,
}

export enum EPriceType {
	Normal,
	Deposit,
	Order,
	Average,
	NoHold,
}

export enum EType {
	None,
	OverstockAndUnavailable,
	Favorites,

	Csgo_Stattrak,
	Csgo_Knife,
	Csgo_Glove,
	Csgo_Souvenir,
	Csgo_Sticker,	
	Csgo_Agent,
	Csgo_Patch,
	Csgo_Container,
	Csgo_Graffiti,

	Dota_1_Common,
	Dota_1_Uncommon,
	Dota_1_Rare,
	Dota_1_Mythical,
	Dota_1_Immortal,
	Dota_1_Legendary,
	Dota_1_Arcana,
	Dota_1_Ancient,

	Dota_2_Inscribed,
	Dota_2_Auspicious,
	Dota_2_Heroic,
	Dota_2_Genuine,
	Dota_2_Autographed,
	Dota_2_Frozen,
	Dota_2_Cursed,
	Dota_2_Corrupted,
	Dota_2_Infused,
	Dota_2_Unusual,
	Dota_2_Exalted,
	Dota_2_Elder,
	Dota_2_Legacy,
	Dota_2_Ascendant,
	Dota_2_Favored,

	Tf_Tool,
	Tf_Gift,
	Tf_SupplyCrate,
	Tf_Recipe,

	Rust_Weapon,
	Rust_Misc,
	Rust_ClothingArmor,
	Rust_Resource,

	Csgo_Tools,
	Csgo_Pins,
	Csgo_Passes,
	Csgo_Gifts,
	Csgo_MusicKits,
}

export enum ETypeValue {
	None,
	Without,
	Only,
}

export enum ESortPriceType {
	Descending,
	Ascending,
}

export class TableHelper {
	static AppIds: Set<number> = new Set<number>([730, 252490, 570, 440])

	static GetServiceName(service: EServices): string {
		switch (service) {
			case EServices.LF:
				return 'LootFarm'
			case EServices.TM:
				return 'TMarket'
			case EServices.VMarket:
				return 'VMarket (Dangerous)'
			case EServices.CsFail:
				return 'Fail'
			case EServices.LisSkins:
				return 'Lis-Skins'
			case EServices.SwapMarket:
				return 'Swap Market'
			case EServices.BitskinsP2P:
				return 'Bitskins P2P'
			case EServices.CsFloat:
				return 'CS Float'
			case EServices.TMNew:
				return 'TMarket New'
			case EServices.CsMoneyMarket:
				return 'CSMoney Market'
			case EServices.ITrade:
				return 'iTrade'
			case EServices.SkinoutMarket:
				return 'Skinout'
			case EServices.WhiteMarket:
				return 'White Market'
			case EServices.DMarketP2P:
				return 'DMarket P2P'
			default:
				return EServices[service]
		}
	}

	// Validations

	static CheckServiceByAppId(service: EServices, appId: number): boolean {
		switch (service) {
			case EServices.Bitskins:
				return appId !== Apps.Rust
			case EServices.VMarket:
				return appId !== Apps.Tf2
			case EServices.BuffMarket:
				return appId === Apps.CsGo
			case EServices.CSMoney:
				return appId === Apps.CsGo || appId === Apps.Dota2
			case EServices.Waxpeer:
				return appId === Apps.CsGo || appId === Apps.Rust
			case EServices.Shadowpay:
				return appId === Apps.CsGo || appId === Apps.Dota2
			case EServices.SkinsMonkey:
				return appId === Apps.CsGo || appId === Apps.Tf2 || appId === Apps.Rust
			case EServices.Swap:
				return appId === Apps.CsGo || appId === Apps.Tf2 || appId === Apps.Rust
			case EServices.SkinSwap:
				return appId === Apps.CsGo || appId === Apps.Rust
			case EServices.Skinbaron:
				return appId === Apps.CsGo || appId === Apps.Tf2
			case EServices.CsFail:
				return appId === Apps.CsGo || appId === Apps.Dota2 || appId === Apps.Rust
			case EServices.LisSkins:
				return appId === Apps.CsGo || appId === Apps.Dota2 || appId === Apps.Rust
			case EServices.SwapMarket:
				return appId === Apps.CsGo || appId === Apps.Dota2 || appId === Apps.Rust || appId === Apps.Tf2
			case EServices.CsFloat:
				return appId === Apps.CsGo
			case EServices.TMNew:
				return appId === Apps.CsGo
			case EServices.CsMoneyMarket:
				return appId === Apps.CsGo
			case EServices.ITrade:
				return appId === Apps.Rust
			case EServices.SkinoutMarket:
				return appId === Apps.CsGo
			case EServices.WhiteMarket:
				return appId === Apps.CsGo
			case EServices.DMarketP2P:
				return appId === Apps.CsGo || appId === Apps.Dota2 || appId === Apps.Rust || appId === Apps.Tf2
			case EServices.Buff: 
				return appId === Apps.CsGo || appId === Apps.Dota2 || appId === Apps.Rust || appId === Apps.Tf2
			default:
				return this.AppIds.has(appId)
		}
	}

	static CheckForSalesAvailable(service: EServices, period: ESalesPeriod): boolean {
		switch (service) {
			case EServices.Steam:
				return true
			// case EServices.CSMoney:
			// 	return true
			case EServices.Bitskins:
				return period < ESalesPeriod.Month
			// case EServices.Buff:
			// 	return period < ESalesPeriod.Month
			// case EServices.C5game:
			// 	return period < ESalesPeriod.Month3
			case EServices.CSDealsMarket:
				return period < ESalesPeriod.Month
			case EServices.DMarket:
				return true
			case EServices.Igxe:
				return period < ESalesPeriod.Month
			case EServices.Shadowpay:
				return period < ESalesPeriod.Month3
			// case EServices.Skinbaron:
			// 	return period < ESalesPeriod.Month
			case EServices.Skinport:
				return true
			case EServices.TM:
				return period < ESalesPeriod.Month3
			case EServices.Waxpeer:
				return period < ESalesPeriod.Month3
			case EServices.BuffMarket:
				return period < ESalesPeriod.Month
			case EServices.VMarket:
				return period < ESalesPeriod.Month3
			case EServices.SwapMarket:
				return period < ESalesPeriod.Month
			case EServices.BitskinsP2P:
				return period < ESalesPeriod.Month
			case EServices.CsFloat:
				return period < ESalesPeriod.Month
			case EServices.TMNew:
				return period < ESalesPeriod.Month3
			case EServices.WhiteMarket:
				return true
			case EServices.DMarketP2P:
				return true
			default:
				return false
		}
	}

	static CheckServiceBySubscription(service: EServices, sub: ESubscription): boolean {
		return !(
			(service === EServices.Buff ||
				service === EServices.BuffMarket ||
				service === EServices.C5game ||
				service === EServices.Igxe ||
				service === EServices.Shadowpay ||
				service === EServices.Skinbaron ||
				service === EServices.Skinport ||
				service === EServices.Waxpeer ||
				service === EServices.VMarket ||
				service === EServices.LisSkins ||
				service === EServices.CsFail ||
				service === EServices.CsFloat ||
				service === EServices.BitskinsP2P ||
				service === EServices.SkinoutMarket ||
				service === EServices.WhiteMarket ||
				service === EServices.DMarketP2P) &&
			sub !== ESubscription.ProSubscription
		)
	}

	static CheckForSalesBySubscription(service: EServices, sub: ESubscription, period: ESalesPeriod, freeServices: Set<EServices>): boolean {
		if (sub === ESubscription.ProSubscription) {
			return TableHelper.CheckForSalesAvailable(service, period)
		} else {
			return (
				period === ESalesPeriod.Week &&
				(TableHelper.CheckServiceBySubscription(service, sub) || freeServices?.has(service)) &&
				TableHelper.CheckForSalesAvailable(service, period)
			)
		}
	}

	static CheckServicePriceType(service: EServices, priceType: EPriceType): boolean {
		switch (priceType) {
			case EPriceType.Normal:
				return true
			case EPriceType.Order:
				switch (service) {
					case EServices.Steam:
					case EServices.Buff:
					case EServices.BuffMarket:
					case EServices.DMarket:
					case EServices.Bitskins:
					case EServices.TM:
					case EServices.VMarket:
					case EServices.SwapMarket:
					case EServices.TMNew:
					case EServices.SkinSwap:
						return true
					default:
						return false
				}
			case EPriceType.Deposit:
				switch (service) {
					case EServices.CSMoney:
					case EServices.LF:
					case EServices.CSDeals:
					case EServices.SkinSwap:
					case EServices.CSTrade:
					case EServices.Tradeit:
						return true
					default:
						return false
				}
			case EPriceType.Average:
				return this.CheckForSalesAvailable(service, ESalesPeriod.Week)
			case EPriceType.NoHold:
				switch (service) {
					case EServices.Swap:
					case EServices.CSMoney:
					case EServices.LF:
					case EServices.SkinSwap:
					case EServices.CSTrade:
					case EServices.LisSkins:
					case EServices.SkinoutMarket:
						return true
					default:
						return false
				}
			default:
				return false
		}
	}

	static CheckServiceIsMarket(service: EServices): boolean {
		switch (service) {
			case EServices.Steam:
			case EServices.Bitskins:
			case EServices.Buff:
			case EServices.C5game:
			case EServices.CSDealsMarket:
			case EServices.DMarket:
			case EServices.Igxe:
			case EServices.Shadowpay:
			case EServices.Skinbaron:
			case EServices.Skinport:
			case EServices.TM:
			case EServices.Waxpeer:
			case EServices.BuffMarket:
			case EServices.VMarket:
			case EServices.LisSkins:
			case EServices.SwapMarket:
			case EServices.BitskinsP2P:
			case EServices.CsFloat:
			case EServices.TMNew:
			case EServices.CsMoneyMarket:
			case EServices.SkinoutMarket:
			case EServices.WhiteMarket:
			case EServices.DMarketP2P:
				return true
			default:
				return false
		}
	}

	static CheckServiceIsRoulette(service: EServices): boolean {
		switch (service) {
			case EServices.CsFail:
				return true
			default:
				return false
		}
	}

	static CheckServiceForLinks(service: EServices): boolean {
		switch (service) {
			case EServices.Buff:
			case EServices.BuffMarket:
			case EServices.C5game:
			case EServices.DMarket:
			case EServices.Skinport:
			case EServices.SwapMarket:
				return true
			default:
				return false
		}
	}

	static CheckServiceForDepositCalculate(service: EServices): boolean {
		switch (service) {
			case EServices.CSMoney:
			case EServices.LF:
			case EServices.CSTrade:
				return true
			default:
				return false
		}
	}

	static CheckServiceForSecret(service: EServices): boolean {
		switch (service) {
			default:
				return false
		}
	}
}

export class ItemType {
	constructor(description: string, availableValues: ETypeValue[] | null = null, defaultValue: ETypeValue = ETypeValue.None, onlyWith: EType = null) {
		this.Description = description
		this.AvailableValues = availableValues ?? [ETypeValue.None, ETypeValue.Without, ETypeValue.Only]
		this.OnlyWith = onlyWith

		this.TypeValue = defaultValue
		this._current = defaultValue
	}

	OnlyWith?: EType
	Description: string
	AvailableValues: ETypeValue[] = [ETypeValue.None, ETypeValue.Without, ETypeValue.Only]

	TypeValue: ETypeValue

	private _current: number

	NextValue(hasOnly: boolean): ETypeValue {
		let value = this.AvailableValues[this.GetCurrent()]

		return (this.TypeValue = hasOnly && value === ETypeValue.Only ? this.AvailableValues[this.GetCurrent()] : value)
	}

	SetTypeValue(typeValue: ETypeValue): void {
		this.TypeValue = typeValue
		this._current = typeValue
	}

	GetCurrent(): number {
		return ++this._current >= this.AvailableValues.length ? (this._current = 0) : this._current
	}
}

export class ItemTypeController {
	constructor(types: Map<string, Map<EType, ItemType>>, combinable: boolean = true) {
		this.Types = types
		this._combinable = combinable
	}

	_combinable: boolean

	HasOnly(key: string, type: EType): boolean {
		const array = new Array<{
			key: EType
			value: ItemType
		}>()

		for (const type of Extensions.GetEnumValues(EType)) {
			if (this.Types.get(key).has(type)) {
				array.push({ key: type, value: this.Types.get(key).get(type) })
			}
		}

		return (
			this._combinable &&
			array.some(
				x =>
					x.value.TypeValue === ETypeValue.Only &&
					!ItemTypeController.CanCombine(x, {
						key: type,
						value: this.Types.get(key).get(type),
					})
			)
		)
	}

	static CanCombine(t1: { key: EType; value?: ItemType }, t2: { key: EType; value?: ItemType }): boolean {
		return t1.value?.OnlyWith === t2.key || t2.value?.OnlyWith === t1.key
	}

	Types: Map<string, Map<EType, ItemType>>
}
