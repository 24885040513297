import { User } from './../user/user'
export interface PromoRequest {
	promo: string
}

export interface PromoResponse {
	code: EResponseCode
	user: User
}

export interface PromoStatus {
	name: string
	type: number
	count: number
	uses: number
}

export enum EResponseCode {
	None,
	Success,
	NoAccess,
	BadRequest,
	NotFound,
	NoUses,
	AlreadyUsed,
	HasSub,
	AlreadyUsedGroup,
}
