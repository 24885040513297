import LangController from '../../models/language/langController'
import {
	ELangActionTypes,
	ILangState,
	LangAction,
} from '../../types/redux/lang'

const initialState: ILangState = {
	lang: LangController.GetCurrent(),
	current: LangController.Current,
}

export const langReducer = (
	state: ILangState = initialState,
	action: LangAction
): ILangState => {
	switch (action.type) {
		case ELangActionTypes.SET_LANG:
			return {
				...state,
				lang: LangController.Items[action.payload],
				current: action.payload,
			}
		case ELangActionTypes.LOAD_LANG_FROM_COOKIE:
			return {
				...state,
				lang: LangController.Items[action.payload],
				current: action.payload,
			}
		default:
			return state
	}
}
