import { ELoginActionTypes } from './../../types/redux/login'

export const showLoginForm = (data: (value: boolean) => void) => {
	return {
		type: ELoginActionTypes.SHOW_LOGIN_NEEDED,
		payload: data,
	}
}

export const closeLoginForm = () => {
	return {
		type: ELoginActionTypes.CLOSE_LOGIN_NEEDED,
	}
}
