import { CheckItemsFilters } from '../../models/pageModels/table/tableExtensions'

export interface ICheckItemState {
	active: boolean
	itemId: number
}

export enum ECheckItemActionTypes {
	SHOW_CHECK_ITEM = 'SHOW_CHECK_ITEM',
	CLOSE_CHECK_ITEM = 'CLOSE_CHECK_ITEM',
}

interface IShowCheckItemAction {
	type: ECheckItemActionTypes.SHOW_CHECK_ITEM
	payload: number
}

interface ICloseCheckItemAction {
	type: ECheckItemActionTypes.CLOSE_CHECK_ITEM
}

export type CheckItemAction = IShowCheckItemAction | ICloseCheckItemAction
