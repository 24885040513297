import { SaleDetail } from './itemSale'

// export class Item {
// 	// Count
// 	c: number
// 	// OrderCount
// 	q: number
// 	// NoHoldCount
// 	j: number
// 	// MaxCount
// 	m: number
// 	// NormalPrice
// 	n: number
// 	// OrderPrice
// 	o: number
// 	// DepositPrice
// 	d: number
// 	// NoHoldPrice
// 	h: number
// 	// WTime
// 	w: number
// 	// Status
// 	s: EItemStatus
// }

export class ItemTable {
	// Count
	c: number
	// MaxCount
	m: number
	// Price
	p: number
	// SourcePrice
	o: number
	// WTime
	w: number
	// Status
	s: EItemStatus
}

export class ItemResponse {
	// Id
	i: number
	// Name
	n: string
	// Item 1
	i1: ItemTable
	// Item 2
	i2: ItemTable
	// Sales
	s: SaleDetail
	// Percent
	p: number
	// IsFavorite
	f: boolean
}

export class ItemListResponse {
	// Id
	i: number
	// Name
	n: string
	// ImageUrl
	m: string
}

export class ItemSingleResponse {
	// NormalPrice
	n: number
	// Count
	c: number
	// MaxCount
	m: number
	// OrderPrice
	o: number
	// OrderCount
	q: number
	// DepositPrice
	d: number
	// AveragePrice
	a: number
	// NoHoldPrice
	h: number
	// NoHoldCount
	j: number
}

export class ItemsChunkResponse {
	next: boolean
	page: number
	updatedTime1: number
	updatedTime2: number
	items: ItemResponse[]
}

export enum EItemStatus {
	Tradable,
	Overstock,
	Unavailable,
}
