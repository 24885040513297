import { InventoryFilter, InventoryResponse } from '../../models/Inventory'

export interface IInventoryFilterState {
	inventoryFilter: InventoryFilter
	loaded: boolean
}

export enum EInventoryFilterActionTypes {
	SET_INVENTORY_FILTER = 'SET_INVENTORY_FILTER',
}

interface ISetInventoryFilterAction {
	type: EInventoryFilterActionTypes.SET_INVENTORY_FILTER
	payload: InventoryFilter
}

export type InventoryFilterAction = ISetInventoryFilterAction
