import { FC } from 'react'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import styles from './css/Confirmation.module.css'

const Confirmation: FC = () => {
	const { active, text, action } = useTypedSelector(x => x.confirmation)
	const {
		current: currLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)
	const { closeConfirmation } = useActions()

	return (
		<div
			className={`${styles.confirmSpace} flex-center${active ? ` ${styles.confShow}` : ''}`}
			onClick={e => {
				if (e.target['id'] === 'confirmation-space') {
					closeConfirmation()
				}
			}}
			id='confirmation-space'
		>
			<div className={styles.confirmForm}>
				<div className={styles.cfText}>{text ?? lang.Confirmation_DefaultText}</div>
				<div className={styles.cfButtons}>
					<div
						className={`${styles.cfBtn} btn-hov`}
						onClick={() => {
							action?.call(this, true)
							closeConfirmation()
						}}
					>
						{currLang == 0 ? 'Да' : 'Yes'}
					</div>
					<div
						className={`${styles.cfBtn} btn-hov`}
						onClick={() => {
							action?.call(this, false)
							closeConfirmation()
						}}
					>
						{currLang == 0 ? 'Нет' : 'No'}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Confirmation
