import { ShowLoadingScreen } from '../../App'
import cookie from '../../models/cookie'
import LangController from '../../models/language/langController'
import { ELangActionTypes } from '../../types/redux/lang'

export const setLanguage = (setLoading: (value: boolean) => {}, current: number) => {
	LangController.Current = current
	cookie.SetCookie('lang', current.toString(), 3000)

	ShowLoadingScreen(setLoading)

	return {
		type: ELangActionTypes.SET_LANG,
		payload: current,
	}
}

export const loadLanguageFromCookie = (id: number) => {
	LangController.Current = id

	return {
		type: ELangActionTypes.LOAD_LANG_FROM_COOKIE,
		payload: id,
	}
}
