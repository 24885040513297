import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Extensions from '../../models/extensions'
import { ESalesPeriod, EServices, TableHelper } from '../../types/tableHelper'
import styles from './css/FilterCheck.module.css'
import { FilterSales } from '../../types/table/filter'
import InputInt from '../Elements/InputInt'

export interface FilterCheckProps {
	onClose: (needRefresh: boolean) => void
}

let needRefresh = false

const FilterCheck: FC<FilterCheckProps> = props => {
	const { filter } = useTypedSelector(x => x.table)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)
	const { active } = useTypedSelector(x => x.filterSales)
	const { disabledServices: disabledServicesArray } = useTypedSelector(x => x.user.feed)
	const { closeFilterSales, setTableFilter } = useActions()

	const { data: fees } = useTypedSelector(x => x.fees)
	const [items, setItems] = useState<object>({})

	const disabledServices = new Set(disabledServicesArray)

	useEffect(() => {
		if (active) {
			needRefresh = false
		}
	}, [active])

	useEffect(() => {
		const salesPeriod = filter.salesPeriod
		const services = Extensions.GetEnumValues(EServices)

		let _minSales: object = {}
		let _maxSales: object = {}
		
		let _items = {}
		let _oldItems: object = {}

		if (filter?.filterSales && filter?.filterSales?.hasOwnProperty(salesPeriod)) {
			_oldItems = filter.filterSales[salesPeriod]
		}

		Object.keys(_oldItems).filter(x => _oldItems[x].maxSales > 0 || _oldItems[x].minSales > 0).forEach(service => {
			_maxSales[service] = _oldItems[service].maxSales ?? 0
			_minSales[service] = _oldItems[service].minSales ?? _oldItems[service] ?? 0
		});

		services.forEach(service => {
			if (!TableHelper.CheckForSalesAvailable(service, filter.salesPeriod)
		 	  || !TableHelper.CheckServiceByAppId(service, filter.appId)
			  || disabledServices?.has(service))
				return;
			
			_items[service] = {}

			if (_minSales.hasOwnProperty(service)) {
				_items[service].minSales = _minSales[service]
			}
			else {
				_items[service].minSales = 0
			}

			if (_maxSales.hasOwnProperty(service)) {
				_items[service].maxSales = _maxSales[service]
			}
			else {
				_items[service].maxSales = 0
			}
		})

		setItems(_items)
	}, [ filter.appId, filter.filterSales, filter.salesPeriod ])

	return (
		<div
			className={`${styles.checkMenu} ${styles.flex} flex ${active ? styles.fcShow : ''}`}
			onClick={e => {
				if (e.target['id'] === 'sales-filter-popup') {
					closeFilterSales()
					props?.onClose?.call(this, needRefresh)
				}
			}}
			id='sales-filter-popup'
		>
			<div className={styles.fcHeader}>
			{(() => {
					if (!(filter && active)) return
					
					return (
						<React.Fragment>
							<div className={`${styles.fcTitle} flex-center`}>
									<div>{lang.TablePage_FilterCheck_Title}</div>
							</div>
							<div className={`${styles.fcClose}`}>
								<span className='btn-simple-hov' 
									onClick={ e => {
										closeFilterSales()
										props?.onClose?.call(this, needRefresh)
									}}>
									✖
								</span>
							</div>

							<div className={`${styles.fcContent}`}>
								{Object.keys(items)?.map((key, i) => {
									const minSales: number = items[key].minSales
									const maxSales: number = items[key].maxSales
									
									return <div className={`${styles.fcItemSales}`}>
										{<div className={`${styles.fcServiceName}`}>
											<div className={styles.serviceLogo}>
												<img className='no-close' 
													src={`Resources/ServicesIcons/${EServices[key].toLowerCase()}.png`} 
													alt={`icon`} />
											</div>
											{`${EServices[key]}`}
										</div>}

										<div>
											<InputInt
												className={`${styles.inputStyle} input-style text-left` }
												defaultValue={minSales}
												onValueChange={value => {
													if(value !== minSales) {
														items[key].minSales = value
		
														if (!filter.filterSales)
															filter.filterSales = new Map();

														const newItems = {}
	
														Object.keys(items).filter(key => items[key].minSales > 0 || items[key].maxSales > 0).forEach(key => {
															newItems[key] = {}
															newItems[key].minSales = items[key].minSales
															newItems[key].maxSales = items[key].maxSales
														})

														filter.filterSales[filter.salesPeriod.toString()] = newItems;
														setTableFilter(filter, true)
														needRefresh = true
													}
												}}
											/>
											<span className={`${styles.indentSales}`}>
												-
											</span>

											<InputInt
												className={`${styles.inputStyle} input-style text-left` }
												defaultValue={maxSales}
												onValueChange={value => {
													if(value !== maxSales) {
														items[key].maxSales = value
		
														if (!filter.filterSales)
															filter.filterSales = new Map();

														const newItems = {}
		
														Object.keys(items).filter(key => items[key].minSales > 0 || items[key].maxSales > 0).forEach(key => {
															newItems[key] = {}
															newItems[key].minSales = items[key].minSales
															newItems[key].maxSales = items[key].maxSales
														})

														filter.filterSales[filter.salesPeriod.toString()] = newItems;
														setTableFilter(filter, true)
														needRefresh = true
													}
												}}
											/>
										</div>
									</div>
								}
								)}
							</div>
						</React.Fragment>
					)
				})()}
			</div>
		</div>
	)
}

export default FilterCheck
