import { PriceLimit } from './../user/priceLimit'
import { UserFeed } from './../user/userFeed'
import { TelegramUser, UserInfo, VkUser } from './../user/userInfo'
import { User, UserResponse } from '../user/user'
import Currency from '../user/currency'
import { BannedUser } from '../user/bannedUser'

export interface IUserState {
	user: User
	userInfo: UserInfo
	currencies: Currency
	feed: UserFeed
	activeProfile: number
	priceLimit: PriceLimit
	loading: boolean
	loaded: boolean
	error: null | string
}

export enum EUserActionTypes {
	FETCH_USER = 'FETCH_USER',
	FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
	FETCH_USER_ERROR = 'FETCH_USER_ERROR',
	FETCH_USER_BANNED = 'FETCH_USER_BANNED',
	SET_USER = 'SET_USER',
	SET_USER_INFO = 'SET_USER_INFO',
	SET_USER_INFO_TRADE_URL = 'SET_USER_INFO_TRADE_URL',
	SET_USER_INFO_TELEGRAM = 'SET_USER_INFO_TELEGRAM',
	SET_USER_INFO_VK = 'SET_USER_INFO_VK',
	SET_ACTIVE_PROFILE = 'SET_ACTIVE_PROFILE',
}

interface IFetchUserAction {
	type: EUserActionTypes.FETCH_USER
}

interface IFetchUserSuccessAction {
	type: EUserActionTypes.FETCH_USER_SUCCESS
	payload: UserResponse
}

interface IFetchUserErrorAction {
	type: EUserActionTypes.FETCH_USER_ERROR
	payload: string
}

interface IFetchUserBannedAction {
	type: EUserActionTypes.FETCH_USER_BANNED,
	payload: BannedUser
}

interface ISetUserAction {
	type: EUserActionTypes.SET_USER
	payload: User
}

interface ISetUserInfoAction {
	type: EUserActionTypes.SET_USER_INFO
	payload: UserInfo
}

interface ISetUserInfoTradeUrlAction {
	type: EUserActionTypes.SET_USER_INFO_TRADE_URL
	payload: string
}

interface ISetUserInfoTelegramAction {
	type: EUserActionTypes.SET_USER_INFO_TELEGRAM
	payload: TelegramUser
}

interface ISetUserInfoVkAction {
	type: EUserActionTypes.SET_USER_INFO_VK
	payload: VkUser
}

interface ISetActiveProfile {
	type: EUserActionTypes.SET_ACTIVE_PROFILE
	payload: number
}

export type UserAction =
	| IFetchUserAction
	| IFetchUserSuccessAction
	| IFetchUserErrorAction
	| IFetchUserBannedAction
	| ISetUserAction
	| ISetUserInfoAction
	| ISetUserInfoTelegramAction
	| ISetUserInfoVkAction
	| ISetUserInfoTradeUrlAction
	| ISetActiveProfile
