import { Filter } from '../table/filter'

export interface ITableState {
	filter: Filter
	loaded: boolean
}

export enum ETableActionTypes {
	LOAD_TABLE_FILTER_FROM_PROFILE = 'LOAD_TABLE_FILTER_FROM_PROFILE',
	SAVE_TABLE_FILTER_TO_PROFILE = 'SAVE_TABLE_FILTER_TO_PROFILE',
	SET_TABLE_FILTER = 'SET_TABLE_FILTER',
}

interface ISetTableFilterAction {
	type: ETableActionTypes.SET_TABLE_FILTER
	payload: Filter
}

interface ILoadTableFilterFromStorageAction {
	type: ETableActionTypes.LOAD_TABLE_FILTER_FROM_PROFILE
	payload: Filter
}

interface ISaveTableFilterToStorage {
	type: ETableActionTypes.SAVE_TABLE_FILTER_TO_PROFILE
}

export type TableAction = ISetTableFilterAction | ILoadTableFilterFromStorageAction | ISaveTableFilterToStorage
