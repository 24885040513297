import { Filter } from './filter'

export class ServicesFee {
	fee: number
	bonus: number
}

export class RequestData {
	filter: Filter
	fee1: ServicesFee
	fee2: ServicesFee
	currency: string
	profileId: number
}
