import { FC } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Extensions from '../../models/extensions'
import TableExtensions from '../../models/pageModels/table/tableExtensions'
import Dropdown from '../Elements/Dropdown'
import styles from './css/SelectGame.module.css'

export interface SelectGameProps {
	onChanged: (app: number) => void
	app: number
}

const SelectGame: FC<SelectGameProps> = ({ onChanged, app }) => {
	const lang = useTypedSelector(x => x.lang.lang.Value)

	const changeItem = (_app: number) => {
		app = _app
		onChanged?.call(this, _app)
	}

	const game = Extensions.GetGameByAppId(app)
	const gamePretty = Extensions.GetGamePrettyByAppId(app)
	return (
		<Dropdown title={`${lang.TablePage_Game}:`}>
			{
				<div className={`${styles.title} title`}>
					<img className='menu-img' src={`Resources/Games/${game}.jpg`} alt={game} />
					{gamePretty}
				</div>
			}
			<div className='menu'>
				{TableExtensions.AppIds().map((_app, i) => {
					const _game = Extensions.GetGameByAppId(_app)
					const _gamePretty = Extensions.GetGamePrettyByAppId(_app)

					return (
						<div className={`${styles.menuItem} menu-item`} onClick={() => changeItem(_app)} key={i}>
							<img className='menu-img' src={`Resources/Games/${_game}.jpg`} alt={_game} />
							{_gamePretty}
						</div>
					)
				})}
			</div>
		</Dropdown>
	)
}

export default SelectGame
