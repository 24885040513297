import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Extensions from '../../models/extensions'
import { ESalesPeriod, EServices, TableHelper } from '../../types/tableHelper'
import Dropdown from '../Elements/Dropdown'
import styles from './css/SelectSalesPeriod.module.css'

export interface SelectSalesPeriodProps {
	onChanged: (value: number) => void
}

const SelectSalesPeriod: FC<SelectSalesPeriodProps> = ({ onChanged }) => {
	const { filter, loaded: filterLoaded } = useTypedSelector(x => x.table)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)
	const {
		user,
		feed: { freeServices: _freeServices, disabledServices: _disabledServices },
	} = useTypedSelector(x => x.user)
	const freeServices = new Set(_freeServices)
	const disabledServices = new Set(_disabledServices)

	const { setTableFilter, showFilterSales } = useActions()

	if (filter == null) return

	const changeSalesService = (service: EServices) => {
		if (filter.salesService === service) return

		filter.salesService = service
		filter.salesPeriod = 0
		setTableFilter(filter, true)
		onChanged?.call(this)
	}

	const changeSalesStatus = (period: ESalesPeriod) => {
		if (filter.salesPeriod === period) return

		filter.salesPeriod = period
		setTableFilter(filter, true)
		onChanged?.call(this)
	}

	let printedExch = false
	let printedRoulette = false

	const getSalesPeriodStr = (period: ESalesPeriod): string => {
		return (
			{
				[ESalesPeriod.Week]: lang.TablePage_SalesWeek,
				[ESalesPeriod.Day]: lang.TablePage_SalesDay,
				[ESalesPeriod.Month]: lang.TablePage_SalesMonth,
				[ESalesPeriod.Month3]: lang.TablePage_SalesMonth3,
			}[period] || ESalesPeriod[period]
		)
	}
	return (
		<div className='elems-10'>
			<Dropdown title={`${lang.TablePage_SalesService}:`}>
				<React.Fragment>
					<div className={`${styles.title} title`}>
						<div className={styles.serviceLogo}>
							<img src={`Resources/ServicesIcons/${EServices[filter.salesService].toLowerCase()}.png`} alt='service' />
						</div>
						{TableHelper.GetServiceName(filter.salesService)}
					</div>
					<div className='menu'>
						<div className='menu-header no-close'>{lang.TablePage_Markets}</div>
						{Extensions.GetEnumValues(EServices)
							.filter(x => TableHelper.CheckServiceIsMarket(x))
							.concat(Extensions.GetEnumValues(EServices).filter(x => !TableHelper.CheckServiceIsMarket(x)))
							.filter(x => TableHelper.CheckForSalesAvailable(x, 0))
							.map((service, i) => {
								if (!TableHelper.CheckServiceByAppId(service, filter.appId)) return
								// if (!TableHelper.CheckForSalesAvailable(service, filter.salesPeriod)) return

								if (disabledServices.has(service)) return

								const noAccess = !TableHelper.CheckForSalesBySubscription(service, user.access, 0, freeServices)
								const isMarket = TableHelper.CheckServiceIsMarket(service)
								const isRoulette = !isMarket && TableHelper.CheckServiceIsRoulette(service)
								const isExch = !isMarket && !isRoulette
								const icon = `Resources/ServicesIcons/${EServices[service].toLowerCase()}.png`
								const name = TableHelper.GetServiceName(service)

								let titleJsx: ReactNode

								if (!printedExch && isExch) {
									printedExch = true
									titleJsx = <div className='menu-header no-close'>{lang.TablePage_Exchangers}</div>
								}

								if (!printedRoulette && isRoulette) {
									printedRoulette = true
									titleJsx = <div className='menu-header no-close'>{lang.TablePage_Roulette}</div>
								}

								return (
									<React.Fragment key={i}>
										{titleJsx}
										{noAccess ? (
											<div className={`${styles.menuItem} menu-item no-close ${styles.noAccess}`}>
												<div className={`${styles.serviceLogo} no-close`}>
													<img className='no-close' src={icon} alt='icon' />
												</div>
												<div className='d-inline-block no-close'>
													<span>{name}</span>
													<div className={`d-inline-block ${styles.noAccessPre} no-close`}>Pro</div>
												</div>
											</div>
										) : (
											<div className={`${styles.menuItem} menu-item`} onClick={() => changeSalesService(service)}>
												{filter.salesService === service && <i className='fa fa-check' aria-hidden='true'></i>}
												<div className={styles.serviceLogo}>
													<img src={icon} alt='icon' />
												</div>
												<span>{name}</span>
											</div>
										)}
									</React.Fragment>
								)
							})}
					</div>
				</React.Fragment>
			</Dropdown>
			<Dropdown title={`${lang.TablePage_SalesPeriod}:`}>
				<div className={`${styles.title} title`}>{getSalesPeriodStr(filter.salesPeriod)}</div>
				<div className='menu'>
					{Extensions.GetEnumValues(ESalesPeriod).map((period, i) => {
						if (!TableHelper.CheckForSalesAvailable(filter.salesService, period)) return

						const description: string = getSalesPeriodStr(period)

						return (
							<React.Fragment key={i}>
								{TableHelper.CheckForSalesBySubscription(filter.salesService, user.access, period, freeServices) ? (
									<div className={`${styles.menuItem} menu-item`} onClick={() => changeSalesStatus(period)}>
										{filter.salesPeriod === period && <i className='fa fa-check' aria-hidden='true'></i>} <span>{description}</span>
									</div>
								) : (
									<div className={`${styles.menuItem} menu-item no-close ${styles.noAccess}`}>
										<div className='d-inline-block no-close'>
											<span>{description}</span>
											<div className={`d-inline-block ${styles.noAccessPre} no-close`}>Pro</div>
										</div>
									</div>
								)}
							</React.Fragment>
						)
					})}
				</div>
			</Dropdown>
			<div className={`${styles.filterBtn} btn-hov`} style={{ marginLeft: 30 }} onClick={showFilterSales}>
							{lang.SelectSalesPeriod_FilterServices}
			</div>
		</div>
	)
}

export default SelectSalesPeriod
