import { EFeesActionTypes } from './../../types/redux/fees'
import { EServices } from './../../types/tableHelper'
import { ServicesFee } from './../../types/table/requestData'

export const setFees = (data: Object) => {
	return {
		type: EFeesActionTypes.FEES_SET_DATA,
		payload: data,
	}
}

export const feesChangeItem = (app: number, service: EServices, data: ServicesFee) => {
	return {
		type: EFeesActionTypes.FEES_CHANGE_ITEM,
		payload: { app, service, data },
	}
}

export const setFeeInput = (app: number, service: EServices, data: string) => {
	return {
		type: EFeesActionTypes.FEES_SET_INPUT_FEE,
		payload: { app, service, data },
	}
}

export const setBonusInput = (app: number, service: EServices, data: string) => {
	return {
		type: EFeesActionTypes.FEES_SET_INPUT_BONUS,
		payload: { app, service, data },
	}
}
