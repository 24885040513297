import { api } from './../../api/api'
import { Dispatch } from 'react'
import { ReferralsGet } from './../../types/referral/referral'
import { EProfileActionTypes, ProfileAction } from './../../types/redux/profile'

export const fetchReferrals = (steamId: string) => {
	return async (dispatch: Dispatch<ProfileAction>) => {
		try {
			dispatch({ type: EProfileActionTypes.FETCH_PROFILE })

			const response = await api.get<ReferralsGet>('referral/my')

			if (response.status === 200) {
				dispatch({
					type: EProfileActionTypes.FETCH_PROFILE_SUCCESS,
					payload: response.data,
				})
			} else {
				throw Error()
			}
		} catch (e) {
			const newReferrals = {
				code: steamId,
				referrals: 0,
			} as ReferralsGet

			dispatch({
				type: EProfileActionTypes.FETCH_PROFILE_ERROR,
				payload: newReferrals,
			})
		}
	}
}

export const setReferrals = (referrals: ReferralsGet) => {
	return {
		type: EProfileActionTypes.SET_PROFILE_REFERRALS,
		payload: referrals,
	}
}
