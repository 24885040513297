import { AxiosError } from 'axios'
import React, { FC, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { api } from '../api/api'
import LoadingSpinner from '../components/LoadingSpinner'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import DataModel from '../models/dataModel'
import Extensions from '../models/extensions'
import Notification from '../models/notification'
import { EResponseCode as ResponseCode, PromoRequest, PromoResponse } from '../types/promo/promo'
import { EResponseCode as RefCodes, ReferralRequest, ReferralResponse } from '../types/referral/referral'
import { ETransactionType, TransactionResponse } from '../types/user/transaction'
import { User } from '../types/user/user'
import styles from './css/ProfilePage.module.css'
import { EStatusPause, PauseResponse } from '../types/user/pause'

const ProfilePage: FC = () => {
	const { user } = useTypedSelector(x => x.user)
	const profileImageRef = useRef<HTMLImageElement>()
	const { lang: langObj, current: langCurrent } = useTypedSelector(x => x.lang)
	const lang = langObj.Value

	const { referrals, loaded } = useTypedSelector(x => x.profile)
	const [promo, setPromo] = useState<PromoRequest>({} as PromoRequest)
	const [referral, setReferral] = useState<ReferralRequest>({ to: '' })
	const [transactionData, setTransactionData] = useState<TransactionResponse>(null)
	const [transactionPage, setTransactionPage] = useState<number>(1)
	const [loading, setLoading] = useState<boolean>(false)
	const [transactionLoading, setTransactionLoading] = useState<boolean>(true)

	const { fetchReferrals, setUser, setReferrals, setLoadingScreen, showConfirmation } = useActions()

	useEffect(() => {
		document.title = 'vvTable - Profile'

		const fetchApi = async () => {
			setLoadingScreen(true)

			if (!loaded) {
				fetchReferrals(user.steamID)
			}

			await getTransactions()

			window.setTimeout(() => {
				setLoadingScreen(false)
			}, 0)
		}

		fetchApi()
	}, [])

	const getTransactions = async (next: boolean = false) => {
		let page = transactionPage

		if (next) {
			page += 1
			setTransactionPage(page)
		}

		setTransactionLoading(true)

		let needScroll = false

		try {
			const response = await api.get<TransactionResponse>(`user/transactions?page=${page}`)
			const data = response.data

			if (transactionData == null) {
				setTransactionData(data)
			} else {
				needScroll = true
				window.blaz.markToScroll(page, `#transactions > div.${styles.transContent} > div.${styles.transItem}`)
				setTransactionData({
					next: data.next,
					page: data.page,
					transactions: transactionData.transactions.concat(data.transactions),
				})
			}
		} catch {
			if (transactionData == null) {
				setTransactionData({} as TransactionResponse)
			}
		}

		setTransactionLoading(false)

		if (needScroll) {
			window.blaz.scrollToMark(page)
		}
	}

	const activatePromo = async () => {
		if (loading) {
			return
		}

		if (!promo.promo) {
			Notification.ShowNotification(lang.SupportNotify_ErrorEmptyFields)
			return
		}

		setLoading(true)

		const response = await api.post<PromoResponse>('promo/activate', promo)

		try {
			if (response.data?.user != null) {
				setUser(response.data.user)
			}

			let ntf = lang.Notification_Error

			switch (response.data?.code) {
				case ResponseCode.Success:
					ntf = lang.Notification_Success
					break
				case ResponseCode.HasSub:
					ntf = lang.ProfilePageNotification_PromoHasSub
					break
				case ResponseCode.AlreadyUsed:
					ntf = lang.ProfilePageNotification_PromoAlreadyUsed
					break
				case ResponseCode.AlreadyUsedGroup:
					ntf = lang.ProfilePageNotification_PromoAlreadyUsedGroup
					break
				case ResponseCode.NotFound:
					ntf = lang.ProfilePageNotification_PromoNotFound
					break
				case ResponseCode.NoUses:
					ntf = lang.ProfilePageNotification_PromoNoUses
					break
			}

			Notification.ShowNotification(ntf)
		} catch (error) {
			if (error instanceof AxiosError) {
				const err = error as AxiosError
				const data = err?.response?.data as PromoResponse

				const message: string =
					{
						[ResponseCode.HasSub]: lang.ProfilePageNotification_PromoHasSub,
						[ResponseCode.AlreadyUsed]: lang.ProfilePageNotification_PromoAlreadyUsed,
						[ResponseCode.AlreadyUsedGroup]: lang.ProfilePageNotification_PromoAlreadyUsedGroup,
						[ResponseCode.NotFound]: lang.ProfilePageNotification_PromoNotFound,
						[ResponseCode.NoUses]: lang.ProfilePageNotification_PromoNoUses,
					}[data?.code] || lang.Notification_Error

				Notification.ShowNotification(message)
			} else {
				Notification.ShowNotification(lang.Notification_Error)
			}
		}

		setPromo({ promo: '' })

		setLoading(false)
	}

	const activateReferral = async () => {
		if (loading) {
			return
		}

		if (!referral.to) {
			Notification.ShowNotification(lang.SupportNotify_ErrorEmptyFields)
			return
		}

		setLoading(true)

		const response = await api.post<ReferralResponse>('referral/to', referral)

		try {
			if (response.data?.referrals != null) {
				setReferrals(response.data.referrals)
			}

			let ntf = lang.Notification_Error

			switch (response.data?.code) {
				case RefCodes.Success:
					ntf = lang.Notification_Success
					break
				case RefCodes.AlreadyRegistered:
					ntf = lang.ProfilePageNotification_ReferralAlreadyRegistered
					break
				case RefCodes.ReferralCodeNotFound:
					ntf = lang.ProfilePageNotification_ReferralReferralCodeNotFound
					break
				case RefCodes.SelfCode:
					ntf = lang.ProfilePageNotification_ReferralSelfCode
					break
			}

			Notification.ShowNotification(ntf)
		} catch (error) {
			if (error instanceof AxiosError) {
				const err = error as AxiosError
				const data = err?.response?.data as ReferralResponse

				const message: string =
					{
						[RefCodes.AlreadyRegistered]: lang.ProfilePageNotification_ReferralAlreadyRegistered,
						[RefCodes.ReferralCodeNotFound]: lang.ProfilePageNotification_ReferralReferralCodeNotFound,
						[RefCodes.SelfCode]: lang.ProfilePageNotification_ReferralSelfCode,
					}[data?.code] || lang.Notification_Error

				Notification.ShowNotification(message)
			} else {
				Notification.ShowNotification(lang.Notification_Error)
			}
		}

		setReferral({ to: '' })

		setLoading(false)
	}

	const checkForPositiveStatus = (transactionType: ETransactionType): boolean =>
		transactionType == ETransactionType.Deposits || 
		transactionType == ETransactionType.Promo ||
		transactionType == ETransactionType.SubPause

	const onPausedSubscription = () => {
		showConfirmation({
			text: lang.Confirmation_PauseSubscribe,
			action: async confirmed => {
				if (confirmed) {
					try {
						const response = await api.post<PauseResponse>('user/pause-sub')
						const data = response.data
						if (!data) throw new Error()
						let ntf = lang.Notification_Error

						switch (data.status) {
							case EStatusPause.Success:
								ntf = lang.Notification_Success
								break
							case EStatusPause.AlreadyStopped:
								ntf = lang.ProfilePageNotification_AlreadyStopped
								break
							case EStatusPause.PauseNotAvailableByDelay:
								ntf = lang.ProfilePageNotification_PauseNotAvailableByDelay + `${new Date(user.subPauseInfo.pauseAllowFrom * 1000).toLocaleString('tr-TR')}`
								break
							case EStatusPause.PauseNotAvailableBySub:
								ntf = lang.ProfilePageNotification_PauseNotAvailableBySub
								break
						}

						Notification.ShowNotification(ntf)

						if (data.user) {
							setUser(data.user)
						}
					} catch (error) {
						if (error instanceof AxiosError) {
							const err = error as AxiosError
							const data = err?.response?.data as PauseResponse

							const message: string = {
								[EStatusPause.AlreadyStopped]: lang.ProfilePageNotification_AlreadyStopped,
								[EStatusPause.PauseNotAvailableByDelay]: lang.ProfilePageNotification_PauseNotAvailableByDelay + `${new Date(user.subPauseInfo.pauseAllowFrom * 1000).toLocaleString('tr-TR')}`,
								[EStatusPause.PauseNotAvailableBySub]: lang.ProfilePageNotification_PauseNotAvailableBySub,
							}[data?.status]

							Notification.ShowNotification(message)
						} else {
							Notification.ShowNotification(lang.Notification_Error)
						}
					}
				}
			}
		})
	}
		
	return (
		<div>
			<div className={styles.data}>
				<div className={`${styles.avatar} d-inline-block`}>
					<img
						src={Extensions.GetProfileAvatarLink(user.avatarUrl, true)}
						alt='avatar'
						ref={profileImageRef}
						onError={() => {
							profileImageRef.current.onerror = null
							profileImageRef.current.src = 'Resources/no-image.png'
						}}
					/>
				</div>
				<div className={styles.userData}>
					<a
						className={styles.userNickname}
						href={User.GetProfileLink(user.steamID)}
						target='_blank'
						rel='nofollow noreferrer'
						title={`Steam profile: ${user.nickname}`}
					>
						{Extensions.GetShortNickname(user.nickname)}
					</a>
					<div className={styles.simpleData}>
						{lang.ProfilePage_Sub}: <span>{User.GetSubscriptionShort(user.access, user.subPaused)}</span>
						{user.subPauseInfo.pauseTo > 0 ? <span className={styles.subTimeRemain}>{DataModel.GetStringTimeByUnixSec(user.subPauseInfo.pauseTo).replace('.', '')}</span> : null}
						{user.time > 0 ? <span className={styles.subTimeRemain}>{DataModel.GetStringTimeByUnixSec(user.time).replace('.', '')}</span> : null}
						<br/>
						{
							user.subPaused  
							? 
							<div className={`${styles.btnPause} ${styles.btnPauseDisabled} d-inline-block`}>
								{lang.ProfilePage_SubPauseAlreadyPaused}
							</div>
							: 
							<div title={lang.Confirmation_PauseSubscribeTitle} className={`${styles.btnPause} btn-hov d-inline-block`} onClick={onPausedSubscription}>
								{lang.ProfilePage_SubPause}
							</div>
						}
					</div>
					<div className={styles.simpleData}>
						{lang.ProfilePage_Balance}: <span>{user.balance} x </span>
						<img src='Resources/key_mini.png' />
						<br />
						<NavLink className={`${styles.btnPay} btn-hov d-inline-block`} to='/balance'>
							{lang.ProfilePage_Deposit}
						</NavLink>
					</div>
				</div>
				<div className={styles.promo}>
					<div className={styles.promoTitle}>{lang.ProfilePage_PromoCodeTitle}</div>
					<input spellCheck={false} className={styles.inputStyle} value={promo?.promo} onChange={e => setPromo({ promo: e.target.value })} />
					<br />
					<div className={`${styles.btnPromo} btn-hov`} onClick={activatePromo}>
						{lang.ProfilePage_ActivatePromoCode}
					</div>
				</div>
				<div className={styles.promo}>
					{referrals != null ? (
						<div>
							<div className={styles.promoTitle}>{lang.ProfilePage_ReferralCodeTitle}</div>
							{referrals.used ? (
								<input spellCheck={false} className={styles.inputStyle} disabled={true} value={referrals.to} />
							) : (
								<React.Fragment>
									<input
										spellCheck={false}
										className={styles.inputStyle}
										disabled={true}
										value={referral.to}
									/>
								</React.Fragment>
							)}
							<div className={styles.referralsInfo}>
								<div>
									{lang.ProfilePage_YourCode}: {referrals.code}
								</div>
								<div
									className={`${styles.btnPromo} btn-hov`}
									style={{
										fontSize: '12px',
										margin: '5px 0',
										textTransform: 'none',
									}}
									onClick={() => window.blaz.CopyToClipboard(`${window.location.origin}/r/${referrals.code}`)}
								>
									{lang.ProfilePage_CopyLink}
								</div>
								{referrals.referrals > 0 ? (
									<React.Fragment>
										<div>
											{lang.ProfilePage_ReferralsCount}: {referrals.referrals}
										</div>
										<div>
											{lang.ProfilePage_ReferralsCountWithSub}: {referrals.referralsWithSub}
										</div>
										<div>
											{lang.ProfilePage_KeysAvailable}: {referrals.keysAvailable}
										</div>
									</React.Fragment>
								) : null}
							</div>
						</div>
					) : (
						<LoadingSpinner />
					)}
				</div>
			</div>

			<div className={styles.transactions} id='transactions'>
				<div className={styles.transactionTitle}>{lang.ProfilePage_Transactions_Title}</div>
				<div className={styles.transContent}>
					{transactionData?.transactions?.length > 0 ? (
						<React.Fragment>
							<React.Fragment>
								{transactionData.transactions.map((trans, i) => {
									return (
										<div className={styles.transItem} key={i}>
											<div className={styles.transactionId} data-status={checkForPositiveStatus(trans.type) ? 'positive' : 'negative'}>
												<span className='text-big'>{trans.transactionID.toString(16).toUpperCase().padStart(16, '0')}</span>
												<br />
												{new Date(trans.time * 1000).toLocaleString('tr-TR')}
											</div>
											<div className={styles.transactionDesc}>
												<div>
													{langCurrent === 0 ? (
														<React.Fragment>
															{
																{
																	[ETransactionType.Purchases]: (
																		<React.Fragment>
																			<span className='text-big'>Покупка</span>
																			{' подписки '}
																			<span className='text-big'>{User.GetSubscriptionShort(trans.access)}</span>
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.Deposits]: trans.botID ? (
																		<React.Fragment>
																			<span className='text-big'>Пополнение</span>
																			{' через '}
																			<a
																				href={User.GetProfileLink(trans.botID)}
																				target='_blank'
																				rel='nofollow noreferrer'
																				className='btn-simple-hov'
																			>
																				<u>бота</u>
																			</a>
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	) : (
																		<React.Fragment>
																			{trans.paymentID ? (
																				<a
																					href={`/payments/status/${trans.paymentID}`}
																					target='_blank'
																					rel='nofollow noreferrer'
																					className='btn-simple-hov'
																				>
																					<u>Пополнение</u>
																				</a>
																			) : (
																				<span className='text-big'>Пополнение</span>
																			)}
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.Promo]: (
																		<React.Fragment>
																			<span className='text-big'>Использование</span>
																			{' промокода x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.Withdraws]: (
																		<React.Fragment>
																			<span className='text-big'>Вывод</span>
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.SubPause]: (
																		<React.Fragment>
																			<span className='text-big'>Пауза</span>
																			{' подписки '}
																			<span className='text-big'>{User.GetSubscriptionShort(trans.access)}</span>
																			{' на '}
																			<span className='text-big'>{trans.count}</span>
																			{' д.'}
																		</React.Fragment>
																	),
																}[trans.type]
															}
														</React.Fragment>
													) : (
														<React.Fragment>
															{
																{
																	[ETransactionType.Purchases]: (
																		<React.Fragment>
																			<span className='text-big'>Buying</span>
																			{' a '}
																			<span className='text-big'>{User.GetSubscriptionShort(trans.access)}</span>
																			{' subscription x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.Deposits]: trans.botID ? (
																		<React.Fragment>
																			<span className='text-big'>Deposit</span>
																			{' by '}
																			<a
																				href={User.GetProfileLink(trans.botID)}
																				target='_blank'
																				rel='nofollow noreferrer'
																				className='btn-simple-hov'
																			>
																				<u>bot</u>
																			</a>
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	) : (
																		<React.Fragment>
																			{trans.paymentID ? (
																				<a
																					href={`/payments/status/${trans.paymentID}`}
																					target='_blank'
																					rel='nofollow noreferrer'
																					className='btn-simple-hov'
																				>
																					<u>Replenishment</u>
																				</a>
																			) : (
																				<span className='text-big'>Replenishment</span>
																			)}
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.Promo]: (
																		<React.Fragment>
																			<span className='text-big'>Using</span>
																			{' a promo code x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.Withdraws]: (
																		<React.Fragment>
																			<span className='text-big'>Withdrawal</span>
																			{' x '}
																			<span className='text-big'>{trans.count}</span>
																			<img src='Resources/key_mini.png' />
																		</React.Fragment>
																	),
																	[ETransactionType.SubPause]: (
																		<React.Fragment>
																			<span className='text-big'>Pause</span>
																			{' subscription '}
																			<span className='text-big'>{User.GetSubscriptionShort(trans.access)}</span>
																			{' for '}
																			<span className='text-big'>{trans.count}</span>
																			{' d.'}
																		</React.Fragment>
																	),
																}[trans.type]
															}
														</React.Fragment>
													)}
												</div>
											</div>
										</div>
									)
								})}
							</React.Fragment>

							{transactionLoading ? (
								<div className={`text-center ${styles.transLoading}`}>
									<LoadingSpinner />
								</div>
							) : (
								transactionData.next && (
									<div className='text-center'>
										<div
											className={`${styles.btnPromo} btn-hov`}
											onClick={() => {
												getTransactions(true)
											}}
										>
											{lang.ProfilePage_Transactions_More}
										</div>
									</div>
								)
							)}
						</React.Fragment>
					) : transactionLoading ? (
						<div className={`text-center`}>
							<LoadingSpinner />
						</div>
					) : (
						<div className='text-center'>{lang.ProfilePage_Transactions_Empty}.</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProfilePage
