import { FC, useEffect } from 'react'
import { useTypedSelector } from '../hooks/useTypedSelector'
import styles from './css/SupportPage.module.css'

const SupportPage: FC = () => {
	const {
		current: currentLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	useEffect(() => {
		document.title = 'vvTable - Support'
	}, [])

	return (
		<div>
			<h1 className={styles.pageTitle}>{lang.SupportPage_Title}</h1>

			<div className={styles.supportMessenger}>
				<div>
					<a href='https://t.me/vvtable_support' target={'_blank'} rel={'nofollow noreferrer'} className='btn-simple-hov'>
						<img src='Resources/Footer/telegram.png' />
						<span>{{ 0: 'Поддержка в Telegram чате' }[currentLang] || 'Support in Telegram chat'}</span>
					</a>
				</div>
				<div>
					<a href='https://vk.com/im?sel=-205013898' target={'_blank'} rel={'nofollow noreferrer'} className='btn-simple-hov'>
						<img src='Resources/Footer/vk.png' />
						<span>{{ 0: 'Поддержка в VK чате' }[currentLang] || 'Support in VK chat'}</span>
					</a>
				</div>
			</div>
		</div>
	)
}

export default SupportPage
