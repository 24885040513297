import { ServicesFee } from './../../types/table/requestData'
import { EServices } from './../../types/tableHelper'
import Extensions from '../../models/extensions'
import TableExtensions from '../../models/pageModels/table/tableExtensions'
import { EFeesActionTypes, FeesAction, IFeesState } from './../../types/redux/fees'

const initialState: IFeesState = {
	loaded: false,
	data: null,
	inputsFee: null,
	inputsBonus: null,
}

export const feesReducer = (state: IFeesState = initialState, action: FeesAction): IFeesState => {
	switch (action.type) {
		case EFeesActionTypes.FEES_SET_DATA:
			if (!action.payload) return
			const inputsFee = {}
			const inputsBonus = {}

			for (const app of TableExtensions.AppIds()) {
				inputsFee[app] = {}
				inputsBonus[app] = {}

				for (const service of Extensions.GetEnumValues(EServices)) {
					const serviceFee = action.payload[app][service] as ServicesFee

					inputsFee[app][service] = serviceFee.fee.toString()
					inputsBonus[app][service] = serviceFee.bonus.toString()
				}
			}

			return { loaded: true, data: action.payload, inputsFee: inputsFee, inputsBonus: inputsBonus }
		case EFeesActionTypes.FEES_CHANGE_ITEM:
			const data = action.payload
			state.data[data.app][data.service] = data.data
			state.inputsFee[data.app][data.service] = data.data.fee.toString()
			return { ...state }
		case EFeesActionTypes.FEES_SET_INPUT_FEE:
			const dataFee = action.payload
			state.inputsFee[dataFee.app][dataFee.service] = dataFee.data
			return { ...state }
		case EFeesActionTypes.FEES_SET_INPUT_BONUS:
			const dataBonus = action.payload
			state.inputsBonus[dataBonus.app][dataBonus.service] = dataBonus.data
			return { ...state }
		default:
			return state
	}
}
