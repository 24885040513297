import { ConfirmationAction, IConfirmationState, EConfirmationActionTypes } from '../../types/redux/confirmation'

const initialState: IConfirmationState = {
	active: false,
	text: null,
	action: null,
}

export const confirmationReducer = (state: IConfirmationState = initialState, action: ConfirmationAction): IConfirmationState => {
	switch (action.type) {
		case EConfirmationActionTypes.SHOW_CONFIRMATION:
			return {
				active: true,
				text: action.payload.text,
				action: action.payload.action,
			}
		case EConfirmationActionTypes.CLOSE_CONFIRMATION:
			return {
				...state,
				active: false,
			}
		default:
			return state
	}
}
