import { FC, useEffect, useRef, useState } from 'react'
import { api } from '../../api/api'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import DataModel from '../../models/dataModel'
import Extensions from '../../models/extensions'
import { hubConnection } from '../../signalR/mainConnection'
import { CustomCurrency } from '../../types/table/customCurrencyModel'
import { ItemListResponse } from '../../types/table/itemResponse'
import { Profile, ProfileWithJson } from '../../types/table/profile'
import Button from '../Button'
import LoadingSpinner from '../LoadingSpinner'
import styles from './css/ListsWindow.module.css'
import InputWindow from './InputWindow'

export interface ListsWindowProps {
	active: boolean
	onClose: (needRefresh: boolean) => void
	onNewProfiles?: (items: Profile[]) => void
}

enum EListType {
	Profiles,
	Favorites,
	BlackList,
	Currency,
}

let editId = 0
let needRefresh = false

const ListsWindow: FC<ListsWindowProps> = props => {
	const {
		lang: { Value: lang },
		current: currentLang,
	} = useTypedSelector(x => x.lang)
	const activeProfile = useTypedSelector(x => x.user.activeProfile)
	const filter = useTypedSelector(x => x.table.filter)

	const [loading, setLoading] = useState(true)
	const [empty, setEmpty] = useState(false)
	const [clearAvailable, setClearAvailable] = useState(false)

	const [currentType, setCurrentType] = useState<EListType>()
	const [profileList, setProfileList] = useState<Profile[]>()
	const [favorites, setFavorites] = useState<ItemListResponse[]>()
	const [blacklist, setBlacklist] = useState<ItemListResponse[]>()
	const [currency, setCurrency] = useState<CustomCurrency[]>()

	const [inputWindowActive, setInputWindowActive] = useState<boolean>(false)
	const [inputStartText, setInputStartText] = useState<string>()

	const { showConfirmation } = useActions()

	const checkClearAvailable = (): boolean => {
		return { [EListType.Favorites]: true, [EListType.BlackList]: true, [EListType.Currency]: true }[currentType] || false
	}

	useEffect(() => {
		if (props.active) {
			editId = 0
			needRefresh = false
			setCurrentType(EListType.Profiles)
		}
	}, [props.active])

	useEffect(() => {
		const checkAsync = async () => {
			setLoading(true)
			setEmpty(true)

			switch (currentType) {
				case EListType.Profiles: {
					const response = await api.get<Profile[]>('table/profiles')
					const data = response.data ?? []

					if (data.length > 0) {
						setEmpty(false)
					}

					setProfileList(data)

					break
				}
				case EListType.Favorites: {
					const response = await api.get<ItemListResponse[]>(`table/favorites/${activeProfile}/${filter.appId}/${filter.service1}/${filter.service2}`)
					const data = response.data ?? []

					if (data.length > 0) {
						setEmpty(false)
					}

					setFavorites(data)

					break
				}
				case EListType.BlackList: {
					const response = await api.get<ItemListResponse[]>(`table/blacklist/${activeProfile}/${filter.appId}/${filter.service1}/${filter.service2}`)
					const data = response.data ?? []

					if (data.length > 0) {
						setEmpty(false)
					}

					setBlacklist(data)

					break
				}
				case EListType.Currency: {
					const response = await api.get<CustomCurrency[]>(`table/currency`)
					const data = response.data ?? []

					if (data.length > 0) {
						setEmpty(false)
					}

					setCurrency(data)

					break
				}
			}

			setLoading(false)
		}

		if (props.active) {
			setClearAvailable(checkClearAvailable())

			checkAsync()
		}
	}, [props.active, currentType])

	const showInput = (id: number, startText: string) => {
		editId = id
		setInputStartText(startText)
		setInputWindowActive(true)
	}

	const deleteItem = (id: number) => {
		switch (currentType) {
			case EListType.Profiles: {
				if (activeProfile === id) return

				showConfirmation({
					action: async confirmed => {
						if (confirmed) {
							const newProfileList = profileList.filter(x => x.id !== id)
							setProfileList(newProfileList)

							props.onNewProfiles?.call(this, [...newProfileList])

							await api.post(`table/profile/delete/${id}`)
						}
					},
					text: null,
				})

				break
			}
			case EListType.Favorites: {
				needRefresh = true

				const newFavorites = favorites.filter(x => x.i !== id)
				setFavorites(newFavorites)

				if (newFavorites.length === 0) {
					setEmpty(true)
				}

				hubConnection.removeFavorite(activeProfile, filter.appId, filter.service1, filter.service2, id)

				break
			}
			case EListType.BlackList: {
				needRefresh = true

				const newBlacklist = blacklist.filter(x => x.i !== id)
				setBlacklist(newBlacklist)

				if (newBlacklist.length === 0) {
					setEmpty(true)
				}

				hubConnection.removeBlacklist(activeProfile, filter.appId, filter.service1, filter.service2, id)

				break
			}
			case EListType.Currency: {
				needRefresh = true

				const item = currency[id]
				if (!item.custom) {
					break
				}

				item.value = item.defaultValue
				item.custom = false

				setCurrency([...currency])

				DataModel.Currency.CurrencySet().get(item.code).Value = item.defaultValue

				api.get<CustomCurrency[]>(`table/currency/reset?code=${item.code}`)

				break
			}
		}
	}

	const editItem = async (value: string) => {
		if (value === inputStartText) return

		const id = editId

		switch (currentType) {
			case EListType.Profiles: {
				const item = profileList.find(x => x.id === id)
				item.name = value

				props.onNewProfiles?.call(this, [...profileList])

				await api.post('table/profile/update', item)

				break
			}
			case EListType.Currency: {
				const item = currency[id]
				const newValue = Number.parseFloat(value?.replaceAll(',', '.'))

				if (newValue && newValue !== item.value) {
					needRefresh = true

					item.value = newValue
					item.custom = true

					setCurrency([...currency])

					DataModel.Currency.CurrencySet().get(item.code).Value = newValue

					await api.get(`table/currency/update?code=${item.code}&value=${newValue}`)
				}

				break
			}
		}
	}

	const clearItems = async () => {
		if (!clearAvailable) {
			return
		}

		switch (currentType) {
			case EListType.Favorites: {
				showConfirmation({
					action: async confirmed => {
						if (confirmed) {
							needRefresh = true
							setFavorites([])
							setEmpty(true)

							await hubConnection.clearFavorites(activeProfile, filter.appId, filter.service1, filter.service2)
						}
					},
					text: null,
				})

				break
			}
			case EListType.BlackList: {
				showConfirmation({
					action: async confirmed => {
						if (confirmed) {
							needRefresh = true
							setBlacklist([])
							setEmpty(true)

							await hubConnection.clearBlacklist(activeProfile, filter.appId, filter.service1, filter.service2)
						}
					},
					text: null,
				})

				break
			}
			case EListType.Currency: {
				showConfirmation({
					action: async confirmed => {
						if (confirmed) {
							needRefresh = true

							for (let i = 0; i < currency.length; i++) {
								const item = currency[i]

								if (item.custom) {
									item.value = item.defaultValue
									item.custom = false
									DataModel.Currency.CurrencySet().get(item.code).Value = item.defaultValue
								}
							}

							setCurrency([...currency])

							await api.get('table/currency/reset/all')
						}
					},
					text: null,
				})

				break
			}
		}
	}

	return (
		<div
			className={`${styles.space}${props.active ? ` ${styles.active}` : ''}`}
			onClick={e => {
				const target = e.target as HTMLElement

				if (target.hasAttribute('data-close')) {
					props.onClose?.call(this, needRefresh)
				}
			}}
			data-close={1}
		>
			<InputWindow
				active={inputWindowActive}
				onClose={() => setInputWindowActive(false)}
				buttonText={'Сохранить'}
				startValue={inputStartText}
				onSave={editItem}
			/>
			<div className={styles.container} data-close={1}>
				<div className={styles.content}>
					<div className={`${styles.btnClose}`}>
						<span className='btn-simple-hov' onClick={() => props.onClose?.call(this, needRefresh)}>
							✖
						</span>
					</div>
					{clearAvailable && !empty && (
						<div className={styles.clearBtn}>
							<Button text={currentType != EListType.Currency ? lang.TablePage_Clear : lang.TablePage_Reset} onClick={clearItems} />
						</div>
					)}
					<div className={styles.settings}>
						<div className={styles.settingsList}>
							{Extensions.GetEnumValues(EListType).map((type, i) => {
								const typeStr = {
									[EListType.Profiles]: lang.TablePage_Lists_Profiles,
									[EListType.Favorites]: lang.TablePage_Lists_Favorites,
									[EListType.BlackList]: lang.TablePage_Lists_BlackList,
									[EListType.Currency]: lang.TablePage_Lists_Currency,
								}[type]

								return (
									<div
										className={`${styles.settingsItem}${currentType === type ? ' ' + styles.active : ''}`}
										onClick={() => {
											setCurrentType(type)
										}}
										key={i}
									>
										{typeStr}
									</div>
								)
							})}
						</div>
					</div>
					<div className={styles.viewContent}>
						{loading ? (
							<div className={styles.loader}>
								<LoadingSpinner />
							</div>
						) : empty ? (
							<div className='text-center'>{{ 0: 'Список пуст' }[currentLang] || 'Empty'}</div>
						) : (
							{
								[EListType.Profiles]: (
									<div className={`${styles.list}`} data-list-type={EListType.Profiles}>
										{profileList?.map((profile, i) => {
											return (
												<div key={i}>
													<div className={styles.itemName}>{profile.name}</div>
													<div className={styles.toolList}>
														<div
															className={`${styles.tool} btn-simple-hov`}
															onClick={() => {
																showInput(profile.id, profile.name)
															}}
														>
															<i className='fas fa-pen'></i>
														</div>
														{profile.id !== activeProfile && (
															<div
																className={`${styles.tool} btn-simple-hov`}
																onClick={() => {
																	deleteItem(profile.id)
																}}
																data-tool-remove
															>
																<i className='fas fa-times'></i>
															</div>
														)}
													</div>
												</div>
											)
										})}
									</div>
								),
								[EListType.Favorites]: (
									<div className={`${styles.list}`} data-list-type={EListType.Favorites}>
										{favorites?.map((item, i) => {
											const imageUrl = Extensions.GetItemImageLink(item.m, 64)

											return (
												<div key={i}>
													<div className={styles.dataList}>
														<div>
															<img src={imageUrl} alt='img' />
														</div>
														<div className={styles.itemName}>{item.n}</div>
													</div>
													<div className={styles.toolList}>
														<div
															className={`${styles.tool} btn-simple-hov`}
															onClick={() => {
																deleteItem(item.i)
															}}
															data-tool-remove
														>
															<i className='fas fa-times'></i>
														</div>
													</div>
												</div>
											)
										})}
									</div>
								),
								[EListType.BlackList]: (
									<div className={`${styles.list}`} data-list-type={EListType.BlackList}>
										{blacklist?.map((item, i) => {
											const imageUrl = Extensions.GetItemImageLink(item.m, 64)

											return (
												<div key={i}>
													<div className={styles.dataList}>
														<div>
															<img src={imageUrl} alt='img' />
														</div>
														<div className={styles.itemName}>{item.n}</div>
													</div>
													<div className={styles.toolList}>
														<div
															className={`${styles.tool} btn-simple-hov`}
															onClick={() => {
																deleteItem(item.i)
															}}
															data-tool-remove
														>
															<i className='fas fa-times'></i>
														</div>
													</div>
												</div>
											)
										})}
									</div>
								),
								[EListType.Currency]: (
									<div className={`${styles.list}`} data-list-type={EListType.Currency}>
										<div className={styles.currencyWarning}>
											{{ 0: 'Все валюты указываются к 1 USD. Например, если CNY = 7.22, то 1 USD = 7.22 CNY' }[currentLang] ||
												'All currencies are indicated to 1 USD. For example, if CNY = 7.22, then 1 USD = 7.22 CNY'}
										</div>
										{currency?.map((item, i) => {
											return (
												<div key={i}>
													<div className={styles.dataList}>
														<div className={styles.itemName}>{`${item.code}: ${item.value}`}</div>
													</div>
													<div className={styles.toolList}>
														<div
															className={`${styles.tool} btn-simple-hov`}
															onClick={() => {
																showInput(i, item.value.toString())
															}}
														>
															<i className='fas fa-pen'></i>
														</div>
														{item.custom && (
															<div
																className={`${styles.tool} btn-simple-hov`}
																onClick={() => {
																	deleteItem(i)
																}}
															>
																<i className='fas fa-redo-alt'></i>
															</div>
														)}
													</div>
												</div>
											)
										})}
									</div>
								),
							}[currentType] || <></>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ListsWindow
