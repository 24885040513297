import { EServices } from './../types/tableHelper'
import { CurrencyData, CurrencyModel } from './../types/currency/currency'
import Extensions from './extensions'

export default class Currency {
	public Current: string
	private readonly _def: string

	constructor(def: string = 'USD') {
		this.SetCurrency((this._def = def))
	}

	public CurrencySet(): Map<string, CurrencyModel> {
		return CurrencyData.AllCurrencies
	}

	public Codes(): IterableIterator<string> {
		return this.CurrencySet().keys()
	}

	public SetCurrency(currency: string): CurrencyModel {
		if (currency == null || !this.CheckForExists(currency)) return null

		this.Current = currency

		return this.CurrencySet().get(this.Current)
	}

	public GetCurrencyText(price: number, sourceCurrency: string = null): string {
		sourceCurrency ??= this._def

		var value = this.Calculate(price, sourceCurrency)

		return this.GetSymbol(value)
	}

	public GetCurrencyTextByService(Price: number, service: EServices): string {
		return this.GetCurrencyText(Price, CurrencyData.GetCurrency(service))
	}

	public GetSymbol(price: number, currencyStr: string = null): string {
		var currency = this.CurrencySet().get(currencyStr ?? this.Current)

		return currency.IsLeft ? `${currency.Symbol} ${price}` : `${price} ${currency.Symbol}`
	}

	public GetPrice(Price: number, sourceCurrency: string): number {
		return this.Calculate(Price, sourceCurrency)
	}

	public GetPriceByService(Price: number, service: EServices) {
		return this.GetPrice(Price, CurrencyData.GetCurrency(service))
	}

	private Calculate(Price: number, sourceCurrency: string): number {
		if (sourceCurrency === this.Current) return Price

		return Extensions.Ceil((Price / this.CurrencySet().get(sourceCurrency).Value) * this.CurrencySet().get(this.Current).Value)
	}

	private CheckForExists(currency: string): boolean {
		return this.CurrencySet().has(currency)
	}

	public GetClone(): Currency {
		return structuredClone(this)
	}
}
