import { FC } from 'react'

export interface LoadingProps {
	force?: boolean
	active: boolean
}

const Loading: FC<LoadingProps> = ({ force, active }) => {
	return (
		<div
			className={`loading-container${force ? ' loading-force' : ''}${
				active ? ' active' : ''
			}`}
		>
			<div className='loading-image'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 456.62 175.42'
					className='logo-full-svg ld ld-dim'
				>
					<polygon points='201.08 105.34 268.66 0.12 210.81 0.12 182.43 44.32 154.24 0.12 90.76 0.12 150.73 93.67 137.28 114.63 62.5 0.12 0 0 112.64 175.42 132.83 175.42 30.3 15.74 53.34 15.74 53.34 15.74 136.73 146.68 160.7 109.19 177.6 135.52 199.17 135.52 122.26 15.74 143.54 15.74 201.08 105.34'></polygon>
					<path d='M288.68,107.57H275.2v28.09H259.9V107.57H246.43V95.81h42.25Z'></path>
					<path d='M302.39,95.81h21.07l14.21,39.85h-16L319,127H306.1l-2.46,8.62H288.4Zm5.82,21.64h8.85l-4.34-13.08h-.11Z'></path>
					<path d='M339.68,95.81h25.06c6.91,0,13.71,2.23,13.71,9.82,0,5.48-2.91,8.4-8.23,9.77v.11c5.54.74,9,5.08,9,9.08,0,10-8.11,11.07-16.39,11.07H339.68Zm14.39,15.25h3.82c2.69,0,5.6-.8,5.6-3.83s-2.52-3.88-5.26-3.88h-4.16Zm0,16.38h4c2.8,0,5.83-1,5.83-4.11,0-3.42-2.86-4-5.71-4h-4.11Z'></path>
					<path d='M384.1,95.81h15.31V123.9h18.78v11.76H384.1Z'></path>
					<path d='M421.68,95.81h34.54v9.59H436.64v5.66h18.73v9.59H436.64v5.42h20v9.59H421.68Z'></path>
				</svg>
			</div>
		</div>
	)
}

export default Loading
