import { FC, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { api } from '../api/api'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import Extensions from '../models/extensions'
import LangController from '../models/language/langController'
import { ESubscription, User } from '../types/user/user'
import style from './css/Header.module.css'

const Header: FC = () => {
	const lang = useTypedSelector(x => x.lang.lang.Value)
	const { user } = useTypedSelector(x => x.user)
	const nyDisabled = useTypedSelector(x => x.ny.disabled)
	const { setLoading, setLanguage, showConfirmation, setNy } = useActions()

	useEffect(() => {}, [user])

	const languageChange = (code: number) => {
		if (LangController.Current === code) return

		setLanguage(setLoading, code)
	}

	const logoutClick = () => {
		showConfirmation({
			text: lang.Confirmation_Logout,
			action: e => {
				if (e) {
					window.location.href = `${window.location.origin}/logout`
				}
			},
		})
	}

	const profileJsx =
		user === null ? (
			<a className={`btn-hov ${style.login}`} href={`${api.defaults.baseURL}login?return_to=${window.location.href}`}>
				<i className='fab fa-steam-symbol'></i>
				<div>{lang.MenuLogin}</div>
			</a>
		) : (
			<div className={style.profile}>
				<div className={style.profileData}>
					<div className={style.userNick}>
						{user.nickname}
						<div className={style.userSubscription}>{User.GetSubscriptionShort(user.access, user.subPaused)}</div>
					</div>
					<NavLink to='sub' className={style.balance} title={lang.MenuBalance}>
						{user.balance} Keys
					</NavLink>
				</div>
				<div className={`${style.profileImage} ${style.headerDropdown}`}>
					<div className={style.ddTitle}>
						<NavLink to='profile' title={lang.MenuProfile}>
							<img src={Extensions.GetProfileAvatarLink(user.avatarUrl)} alt='profile' />
						</NavLink>
					</div>
					<div className={style.ddContent} dd-right=''>
						<NavLink to='profile'>{lang.MenuProfile}</NavLink>
						<NavLink to='sub'>{lang.MenuSubscribe}</NavLink>
						<NavLink to='settings'>{lang.MenuSettings}</NavLink>
						<div onClick={logoutClick}>{lang.MenuLogout}</div>
					</div>
				</div>
			</div>
		)

	const jsx = (
		<header className={style.appHeader} id='app-header'>
			<div>
				<ul className={style.ulLinks}>
					<li className={style.brandLogo}>
						<NavLink to='/' aria-label='Home' title='vvTable'>
							<div className='fill-main d-inline'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 211.64 138.19'>
									<polygon points='158.41 82.99 211.64 0.09 166.07 0.09 143.71 34.91 121.51 0.09 71.5 0.09 118.75 73.79 108.14 90.3 49.24 0.09 0 0 88.74 138.19 104.64 138.19 23.87 12.4 42.02 12.4 42.02 12.4 107.71 115.55 126.6 86.02 139.91 106.76 156.91 106.76 96.31 12.4 113.08 12.4 158.41 82.99' />
								</svg>
							</div>
						</NavLink>
					</li>
					<li>
						<NavLink to='profile' className={({ isActive }) => (isActive ? style.active : '')}>
							{lang.MenuProfile}
						</NavLink>
					</li>
					<li>
						<NavLink to='table' className={({ isActive }) => (isActive ? style.active : '')}>
							{lang.MenuTable}
						</NavLink>
					</li>
					{
						user?.access === ESubscription.ProSubscription ? <li>
							<NavLink to='inventory' className={({ isActive }) => (isActive ? style.active : '')}>
								{lang.MenuInventory}
							</NavLink>
						</li> : undefined
					}
					<li>
						<NavLink to='sub' className={({ isActive }) => (isActive ? style.active : '')}>
							{lang.MenuSubscribe}
						</NavLink>
					</li>
					<li>
						<NavLink to='balance' className={({ isActive }) => (isActive ? style.active : '')}>
							{lang.MenuBuy}
						</NavLink>
					</li>
					<li>
						<NavLink to='support' className={({ isActive }) => (isActive ? style.active : '')}>
							{lang.MenuSupport}
						</NavLink>
					</li>
					<li>
						<NavLink to='settings' className={({ isActive }) => (isActive ? style.active : '')}>
							{lang.MenuSettings}
						</NavLink>
					</li>
					<li>
						<NavLink to='faq' className={({ isActive }) => (isActive ? style.active : '')}>
							FAQ
						</NavLink>
					</li>
					<li className={style.headerDropdown}>
						<div className={style.ddTitle}>
							{lang.MenuLanguage}
							<i className='fa fa-angle-down' aria-hidden='true'></i>
						</div>
						<div className={style.ddContent}>
							{LangController.Items.map((v, i) => (
								<div key={i} onClick={() => languageChange(i)} className={i === LangController.Current ? style.active : ''}>
									{v.Name}
								</div>
							))}
						</div>
					</li>
					{/* <li className={style.headerDropdown}>
						<div className={style.ddTitle}>
							{lang.NewYear_Title}
							<i className='fa fa-angle-down' aria-hidden='true'></i>
						</div>
						<div className={style.ddContent}>
							<div onClick={() => (nyDisabled ? setNy(false) : undefined)} className={!nyDisabled ? style.active : ''}>
								{lang.NewYear_Enable}
							</div>
							<div onClick={() => (!nyDisabled ? setNy(true) : undefined)} className={nyDisabled ? style.active : ''}>
								{lang.NewYear_Disable}
							</div>
						</div>
					</li> */}
				</ul>
			</div>
			<div>{profileJsx}</div>
		</header>
	)

	return jsx
}

export default Header
