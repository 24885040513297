import { ETableActionTypes, ITableState, TableAction } from './../../types/redux/table'

const initialState: ITableState = {
	filter: null,
	loaded: false,
}

export const tableReducer = (state: ITableState = initialState, action: TableAction): ITableState => {
	switch (action.type) {
		case ETableActionTypes.SET_TABLE_FILTER:
			return { ...state, filter: action.payload }
		case ETableActionTypes.LOAD_TABLE_FILTER_FROM_PROFILE:
			return { filter: action.payload, loaded: true }
		case ETableActionTypes.SAVE_TABLE_FILTER_TO_PROFILE:
			return state
		default:
			return state
	}
}
