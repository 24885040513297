export interface IConfirmationState {
	active: boolean
	text: string
	action: (value: boolean) => void
}

export interface ConfirmationData {
	text: string
	action: (value: boolean) => void
}

export enum EConfirmationActionTypes {
	SHOW_CONFIRMATION = 'SHOW_CONFIRMATION',
	CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION',
}

interface IShowConfirmationAction {
	type: EConfirmationActionTypes.SHOW_CONFIRMATION
	payload: ConfirmationData
}

interface ICloseConfirmationAction {
	type: EConfirmationActionTypes.CLOSE_CONFIRMATION
}

export type ConfirmationAction =
	| IShowConfirmationAction
	| ICloseConfirmationAction
