import { AxiosError } from 'axios'
import React, { FC, useEffect, useRef, useState } from 'react'
import { api } from '../api/api'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import DataModel from '../models/dataModel'
import Extensions from '../models/extensions'
import Notification from '../models/notification'
import TableExtensions from '../models/pageModels/table/tableExtensions'
import { ServicesFee } from '../types/table/requestData'
import { EServices, TableHelper } from '../types/tableHelper'
import { EResponseCode, SocialResponse, TelegramAuth, TelegramUser, VkAuth, VkUser } from '../types/user/userInfo'
import styles from './css/SettingsPage.module.css'

const defaultPhotoUrl = 'https://avatars.akamai.steamstatic.com/b634b27619571cd83064438bf6a7b4b44576950a_medium.jpg'

const SettingsPage: FC = () => {
	const inputTradeOfferLinkRef = useRef<HTMLInputElement>()
	const imgVkPhotoRef = useRef<HTMLImageElement>()
	const imgTgPhotoRef = useRef<HTMLImageElement>()

	const { user, userInfo, feed } = useTypedSelector(x => x.user)
	const { loaded: feesLoaded, data: fees, inputsFee, inputsBonus } = useTypedSelector(x => x.fees)

	const disabledServices = new Set(feed.disabledServices)

	const [currentApp, setCurrentApp] = useState<number>(TableExtensions.AppIds()[0])
	const [haveToken, setHaveToken] = useState<boolean>(false)

	const {
		current: currentLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const { setLoadingScreen, showConfirmation, setUserInfoTradeUrl, setUserInfoTelegram, setUserInfoVk, setFees, feesChangeItem, setFeeInput, setBonusInput } =
		useActions()

	useEffect(() => {
		document.title = 'vvTable - Settings'

		const fetchApi = async () => {
			setLoadingScreen(true)

			if (!feesLoaded) {
				DataModel.LoadAllFees(user)
				setFees(DataModel.Fees)
			}

			setHaveToken(userInfo !== null)

			window.initTelegramContext(userAuthTg)
			window.VK.initContext(userAuthVk)

			if (!userInfo.vk) {
				window.VK.init({ apiId: DataModel.VkAPI })
			}

			window.initTelegramButton()

			setLoadingScreen(false)
		}

		fetchApi()
	}, [])

	const saveTradeOfferLink = async () => {
		if (userInfo.tradeOfferLink) {
			try {
				const response = await api.post<EResponseCode>('user-settings/token', userInfo.tradeOfferLink, {
					headers: { 'Content-Type': 'application/json' },
				})
				const code = response.data

				Notification.ShowNotification(lang.Notification_Success)

				if (code !== EResponseCode.Success) {
					setUserInfoTradeUrl(null)
				} else {
					setHaveToken(true)
				}
			} catch (error) {
				if (error instanceof AxiosError) {
					const err = error as AxiosError
					const code = err.response.data as EResponseCode

					const message: string =
						{
							[EResponseCode.IncorrectTradeOfferLink]: lang.UserSettingsPageNotification_IncorrectTradeOfferLink,
							[EResponseCode.TokenAlreadyUsed]: lang.UserSettingsPageNotification_TokenAlreadyUsed,
						}[code] || lang.Notification_Error

					Notification.ShowNotification(message)

					setUserInfoTradeUrl(null)
				} else {
					setUserInfoTradeUrl(null)

					Notification.ShowNotification(lang.Notification_Error)
				}
			}
		} else {
			Notification.ShowNotification(lang.UserSettingsPageNotification_IncorrectTradeOfferLink)
		}
	}

	const removeTradeOfferLink = () => {
		if (userInfo.tradeOfferLink && haveToken) {
			showConfirmation({
				text: null,
				action: async e => {
					if (e) {
						const response = await api.post<EResponseCode>('user-settings/remove-token')
						try {
							const code = response.data

							if (code === EResponseCode.Success) {
								setHaveToken(false)
								setUserInfoTradeUrl(null)
							}
						} catch (error) {
							Notification.ShowNotification(lang.Notification_Error)
						}
					}
				},
			})
		} else {
			setUserInfoTradeUrl(null)
		}
	}

	const userAuthTg = async (data: TelegramAuth) => {
		try {
			const response = await api.post<SocialResponse>('user-settings/tg-oauth', data)
			const res = response.data

			if (res.status === EResponseCode.Success) {
				setUserInfoTelegram({ ...TelegramUser.TelegramUserFromAuth(data), isSub: res.isSub })
			}
		} catch (error) {
			if (error instanceof AxiosError) {
				const err = error as AxiosError
				const res = err.response.data as SocialResponse

				const message =
					{
						[EResponseCode.IncorrectData]: lang.UserSettingsPageNotification_IncorrectData,
						[EResponseCode.AccountAlreadyUsed]: lang.UserSettingsPageNotification_AccountAlreadyUsed,
					}[res.status] || lang.Notification_Error

				Notification.ShowNotification(message)
			} else {
				Notification.ShowNotification(lang.Notification_Error)
			}
		}
	}

	const userAuthVk = async (data: VkAuth) => {
		data.app_id = DataModel.VkAPI

		try {
			const response = await api.post<SocialResponse>('user-settings/vk-oauth', data)
			const res = response.data

			if (res.status === EResponseCode.Success) {
				setUserInfoVk({ ...VkUser.VkUserFromAuth(data), isSub: res.isSub })
			}
		} catch (error) {
			if (error instanceof AxiosError) {
				const err = error as AxiosError
				const res = err.response.data as SocialResponse

				const message =
					{
						[EResponseCode.IncorrectData]: lang.UserSettingsPageNotification_IncorrectData,
						[EResponseCode.AccountAlreadyUsed]: lang.UserSettingsPageNotification_AccountAlreadyUsed,
					}[res.status] || lang.Notification_Error

				Notification.ShowNotification(message)
			} else {
				Notification.ShowNotification(lang.Notification_Error)
			}
		}
	}

	const userLogoutTg = () => {
		showConfirmation({
			text: null,
			action: async e => {
				if (e) {
					try {
						const response = await api.post<EResponseCode>('user-settings/tg-logout')
						const code = response.data

						if (code === EResponseCode.Success) {
							setUserInfoTelegram(null)
						}
					} catch {
						Notification.ShowNotification(lang.Notification_Error)
					}
				}
			},
		})
	}

	const userLogoutVk = () => {
		showConfirmation({
			text: null,
			action: async e => {
				if (e) {
					try {
						const response = await api.post<EResponseCode>('user-settings/vk-logout')
						const code = response.data

						if (code === EResponseCode.Success) {
							setUserInfoVk(null)
							window.VK.init({ apiId: DataModel.VkAPI })
						}
					} catch {
						Notification.ShowNotification(lang.Notification_Error)
					}
				}
			},
		})
	}

	const changeGame = (app: number) => {
		if (currentApp != app) {
			setCurrentApp(app)
		} else {
			showConfirmation({
				text: lang.UserSettingsPage_ResetCommissions(app),
				action: e => {
					if (e) {
						fees[app] = DataModel.GetDefaultFees()[app]
						setFees(fees)
						save()
					}
				},
			})
		}
	}

	const save = () => {
		DataModel.saveAllFees(user)
	}

	return (
		<div className={styles.settings}>
			<div className={styles.settingsPanel}>
				<div className={styles.settingsTitle}>{lang.UserSettingsPage_UserInfoTitle}</div>

				<div className={`${styles.social} flex-center`}>
					<div id='trade-token' className={`${styles.socItem} ${styles.tradeToken}`}>
						<label htmlFor='trade-offer-link'>{lang.UserSettingsPage_Label_TradeOfferLink}:</label>
						<input
							id='trade-offer-link'
							type='text'
							value={userInfo.tradeOfferLink ?? ''}
							onChange={e => setUserInfoTradeUrl(e.target.value)}
							onFocus={() => inputTradeOfferLinkRef.current.setSelectionRange(0, inputTradeOfferLinkRef.current.value.length)}
							ref={inputTradeOfferLinkRef}
						/>
						<div className={styles.tokenControls}>
							<div>
								<a
									href={`https://steamcommunity.com/profiles/${user.steamID}/tradeoffers/privacy#trade_offer_access_url`}
									target='_blank'
									rel='nofollow noreferrer'
									className='link-hov'
								>
									{lang.UserSettingsPage_GetTradeOfferLink}
								</a>
							</div>
							<div>
								<div className='btn-simple-hov' onClick={removeTradeOfferLink}>
									{lang.UserSettingsPage_Reset}
								</div>
							</div>
							<div>
								<div className='btn-simple-hov' onClick={saveTradeOfferLink}>
									{lang.UserSettingsPage_SaveTradeOfferLink}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.socItem}>
						<label>{lang.UserSettingsPage_Label_Vk}:</label>
						<div id='vk_auth' className={styles.socAuth} style={{ display: userInfo.vk ? 'none' : undefined }}></div>
						<div className={styles.socAuth} style={{ display: !userInfo.vk ? 'none' : undefined }}>
							{userInfo.vk && (
								<div className={styles.socInfo}>
									<div className={styles.socImg} data-status={userInfo.vk.isSub ? 'yes' : 'not'}>
										<img
											src={userInfo.vk.photoUrl ?? defaultPhotoUrl}
											alt='vk-ava'
											onError={() => {
												imgVkPhotoRef.current.onerror = null
												imgVkPhotoRef.current.src = 'Resources/no-image.png'
											}}
											ref={imgVkPhotoRef}
										/>
									</div>
									<div>
										{userInfo.vk.firstName} {userInfo.vk.lastName}
									</div>
									<div className={styles.socDisc}>
										<span className='btn-simple-hov' onClick={userLogoutVk}>
											✖
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className={styles.socItem}>
						<label>{lang.UserSettingsPage_Label_Telegram}:</label>
						<div id='tg_auth' className={styles.socAuth} style={{ display: userInfo.telegram ? 'none' : undefined }}>
							<script
								async
								src='tgauth.js?15'
								data-telegram-login={DataModel.TelegramBot}
								data-size='large'
								data-onauth='onTelegramAuth(user)'
								data-request-access='write'
							></script>
						</div>
						<div className={styles.socAuth} style={{ display: !userInfo.telegram ? 'none' : undefined }}>
							{userInfo.telegram && (
								<div className={styles.socInfo}>
									<div className={styles.socImg} data-status={userInfo.telegram.isSub ? 'yes' : 'not'}>
										<img
											src={userInfo.telegram.photoUrl ?? defaultPhotoUrl}
											alt='tg-ava'
											onError={() => {
												imgTgPhotoRef.current.onerror = null
												imgTgPhotoRef.current.src = 'Resources/no-image.png'
											}}
											ref={imgTgPhotoRef}
										/>
									</div>
									<div>
										{userInfo.telegram.firstName} {userInfo.telegram.lastName}
									</div>
									<div className={styles.socDisc}>
										<span className='btn-simple-hov' onClick={userLogoutTg}>
											✖
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{(!(userInfo?.telegram || userInfo?.vk) || userInfo?.telegram?.isSub || userInfo?.vk?.isSub) != true && (
					<div className={`${styles.subWarningTitle} mt-3`}>
						{{
							0: (
								<React.Fragment>
									Если вы подписались после авторизации
									<br />
									авторизуйтесь снова
								</React.Fragment>
							),
						}[currentLang] || (
							<React.Fragment>
								If you subscribed after authorization
								<br />
								log in againd
							</React.Fragment>
						)}
					</div>
				)}
			</div>
			<div className={styles.settingsPanel}>
				<div className={styles.settingsTitle}>{lang.UserSettingsPage_SettingsTitle}</div>
				<div className='text-center mt-3'>
					<div className={styles.appList}>
						{TableExtensions.AppIds().map((app, i) => {
							const game = Extensions.GetGameByAppId(app)

							return (
								<div className={`${styles.appItem}${app === currentApp ? ` ${styles.active}` : ''}`} onClick={() => changeGame(app)} key={i}>
									<img src={`Resources/Games/${game}.jpg`} alt={game} />
								</div>
							)
						})}
					</div>
				</div>
				<div className={styles.settingsContainer}>
					{fees &&
						inputsFee &&
						inputsBonus &&
						Extensions.GetEnumValues(EServices).map((service, i) => {
							const name = TableHelper.GetServiceName(service)

							if (!TableHelper.CheckServiceByAppId(service, currentApp)) return
							if (disabledServices.has(service)) return
							if (TableHelper.CheckServiceForSecret(service) && !user.secretAccess) return

							const item = fees[currentApp][service] as ServicesFee
							if (!item) return

							if (TableHelper.CheckServiceBySubscription(service, user.access)) {
								const jsx = (
									<div className={styles.settingsItem} key={i}>
										<div className={styles.siTitle}>
											<span>
												<img src={`Resources/ServicesIcons/${EServices[service].toLowerCase()}.png`} alt={EServices[service]} />
												{name}
											</span>
										</div>
										{lang.UserSettingsPage_Commission}
										<input
											type='number'
											className='input-style'
											onKeyPress={e => {
												if (e.key === 'e') {
													e.preventDefault()
												}
												if (e.key === 'Enter') {
													const elem = e.target as HTMLElement
													elem.blur()
												}
											}}
											value={inputsFee[currentApp][service]}
											onChange={e => setFeeInput(currentApp, service, e.target.value)}
											onBlur={e => {
												const v = Number(inputsFee[currentApp][service].replace(',', '.'))

												feesChangeItem(currentApp, service, { fee: v, bonus: item.bonus })
												save()
											}}
										/>
										{lang.UserSettingsPage_Bonus}
										<input
											type='number'
											className='input-style'
											onKeyPress={e => {
												if (e.key === 'e') {
													e.preventDefault()
												}
												if (e.key === 'Enter') {
													const elem = e.target as HTMLElement
													elem.blur()
												}
											}}
											value={inputsBonus[currentApp][service]}
											onChange={e => setBonusInput(currentApp, service, e.target.value)}
											onBlur={e => {
												const v = Number(inputsBonus[currentApp][service].replace(',', '.'))

												feesChangeItem(currentApp, service, { fee: item.fee, bonus: v })
												save()
											}}
										/>
									</div>
								)

								return jsx
							}

							return undefined
						})}
				</div>
			</div>
		</div>
	)
}

export default SettingsPage
