import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTypedSelector } from '../hooks/useTypedSelector'
import styles from './css/NotFoundPage.module.css'

const NotFoundPage = () => {
	const { current: currLang } = useTypedSelector(x => x.lang)

	return (
		<div className={styles.mainContent}>
			<div id='container' className={styles.container}>
				<div className={styles.start}>
					<div>
						<h1 className={styles.error}>{currLang === 0 ? 'Страница не найдена!' : 'Page not found!'}</h1>
						<NavLink className={styles.icon} to='/'>
							<i className='fa fa-chevron-left'></i>
							{currLang === 0 ? 'Вернутся на главную' : 'Back to home'}
						</NavLink>
						<br />
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFoundPage
