import { FC } from 'react'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import styles from './css/LoginForm.module.css'

const LoginForm: FC = () => {
	const { active, action } = useTypedSelector(x => x.login)
	const lang = useTypedSelector(x => x.lang).lang.Value
	const { closeLoginForm } = useActions()

	return (
		<div
			className={`${styles.loginSpace} flex-center${active ? ` ${styles.loginShow}` : ''}`}
			onClick={e => {
				if (e.target['id'] === 'login-space') {
					action?.call(this, false)
					closeLoginForm()
				}
			}}
			id='login-space'
		>
			<div className={styles.loginForm}>
				<div className={styles.lgText}>{lang.LoginForm_Text}</div>
				<div className={styles.lgButtons}>
					<a className={`${styles.lgBtn} btn-hov ${styles.login}`} href={`api/login?return_to=${window.location.href}`}>
						<i className='fab fa-steam-symbol'></i>
						<div>{lang.MenuLogin}</div>
					</a>
					<div
						className={`${styles.lgBtn} btn-hov`}
						onClick={() => {
							action?.call(this, false)
							closeLoginForm()
						}}
					>
						{lang.LoginForm_Back}
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginForm
