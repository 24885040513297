export interface ReferralRequest {
	to: string
}

export interface ReferralResponse {
	code: EResponseCode
	referrals: ReferralsGet
}

export interface ReferralsGet {
	code: string
	referrals: number
	referralsWithSub: number
	keysAvailable: number
	used: boolean
	to: string
}

export interface Referral {
	steamID: string
	steamIDTo: string
}

export enum EResponseCode {
	None,
	Success,
	NoAccess,
	BadRequest,
	AlreadyRegistered,
	ReferralCodeNotFound,
	SelfCode,
}
