import { config } from './../config'
import axios, { Axios, AxiosRequestConfig, AxiosResponse } from 'axios'

export let onNeedToRefresh: Function = new Function()
export const setOnNeedToRefresh = (func: Function) => {
	onNeedToRefresh = func
}

export class Api extends Axios {
	override request<T = never, R = AxiosResponse<T>>(config: AxiosRequestConfig<T>): Promise<R> {
		return new Promise<any>((resolve, reject) => {
			super
				.request(config)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					const response = error.response

					if (response.status === 401) onNeedToRefresh()

					reject(error)
				})
		})
	}

	constructor(config?: AxiosRequestConfig<any>) {
		super(config)
		const ax = axios.create(config)
		this.interceptors = ax.interceptors
		this.defaults = ax.defaults
	}
}

export const api = new Api({
	baseURL: config.baseUrl,
	headers: {
		platform: 'web',
	},
})
