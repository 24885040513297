export enum EEndpoints {
	BalanceUpdate = 'BalanceUpdate',
	GetSale = 'GetSale',
	AddFavorite = 'AddFavorite',
	RemoveFavorite = 'RemoveFavorite',
	ClearFavorites = 'ClearFavorites',
	AddBlacklist = 'AddBlacklist',
	RemoveBlacklist = 'RemoveBlacklist',
	ClearBlacklist = 'ClearBlacklist',
	AddBlacklistTmp = 'AddBlacklistTmp',
	RemoveBlacklistTmp = 'RemoveBlacklistTmp',
	ClearBlacklistTmp = 'ClearBlacklistTmp',
	ItemsUpdated = 'ItemsUpdated',
}
