import Currency from './currency'
import { PriceLimit } from './priceLimit'
import { SubPauseInfo } from './subPauseInfo'
import { UserFeed } from './userFeed'
import { UserInfo } from './userInfo'

export enum ESubscription {
	NoSubscription,
	BaseSubscription,
	ProSubscription,
}

export class User {
	steamID: string
	access: ESubscription
	time: number
	nickname: string
	avatarUrl: string
	balance: number
	secretAccess: boolean
	nickBonus: boolean
	subPaused: boolean
	subPauseInfo: SubPauseInfo

	static GetSubscriptionShort(access: ESubscription, isPaused: boolean = false): string {

		if (isPaused)
		{
			return 'Pause'
		}

		switch (access) {
			case ESubscription.BaseSubscription:
				return 'Base'
			case ESubscription.ProSubscription:
				return 'Pro'
			default:
				return 'Free'
		}
	}

	static GetProfileLink(steamId: string): string {
		return `https://steamcommunity.com/profiles/${steamId}`
	}

	static GetTradeOfferLink(steamId3: string, tradeToken: string): string {
		return `https://steamcommunity.com/tradeoffer/new/?partner=${steamId3}&token=${tradeToken}`
	}
}

export interface UserResponse {
	success: boolean
	userData?: User
	userInfo?: UserInfo
	currencies?: Currency
	feed?: UserFeed
	version?: string
	activeProfile: number
	priceLimit: PriceLimit
}
