import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { store } from './store'
import { ContextMenuProvider } from './context/ContextMenu.provider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
document.body.style.overflowAnchor = 'none'

window.addEventListener('click', event => {
	var t = event.target as HTMLElement

	const elements = document.querySelectorAll('.show')

	if (!t.classList.contains('title') && !t.classList.contains('no-close')) {
		let closed = false

		elements.forEach(e => {
			e.classList.remove('show')
			closed = true
		})

		if (closed) {
			window.dispatchEvent(new Event('dropdown_closed'))
		}
	}
})

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
)
