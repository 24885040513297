import { ILanguage } from './../models/language/language'
import { User } from './../types/user/user'
import { Deposit } from './../types/signalR/deposit'
import Notification from '../models/notification'
import Toast from '../components/Elements/Toast/toast'

export default class SignalRModel {
	public balanceUpdate(data: Deposit, lang: ILanguage, setUser: (user: User) => void) {
		setUser(data.user)

		const text = lang.Text_DepositKeys(data.count)

		Notification.ShowNotification(text)
	}

	public itemsUpdated(lang: ILanguage) {
		if (window.location.pathname !== '/table') return

		window.dispatchEvent(new Event('items_updated'))
	}
}
