import React, { FC, ReactNode, useEffect, useState } from 'react'
import { api } from '../api/api'
import Dropdown from '../components/Elements/Dropdown'
import SelectGame from '../components/Table/SelectGame'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import DataModel from '../models/dataModel'
import Extensions from '../models/extensions'
import { EPriceType, EServices, ESortPriceType, TableHelper } from '../types/tableHelper'
import styles from './css/InventoryPage.module.css'
import { InventoryFilter, InventoryResponse } from '../models/Inventory'
import InventoryCard from '../components/Elements/InventoryCard'
import ProductCardBox, { CardClassName } from '../components/Elements/ProductCardBox/ProductCardBox'
import { CurrencyData } from '../types/currency/currency'
import PriceCheck from '../components/Table/PriceCheck'
import Notification from '../models/notification'
import LoadingSpinner from '../components/LoadingSpinner'
import { EResponseExceptionStatus } from '../types/Enum/EResponseExceptionStatus'

const InventoryPage: FC = () => {
	const {
		user,
		activeProfile,
		feed: { freeServices: _freeServices, disabledServices: _disabledServices },
	} = useTypedSelector(x => x.user)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)
    const {
        inventoryFilter
    } = useTypedSelector(x => x.inventoryFilter)

    const { setInventoryFilter } = useActions()
    
    const [inventoryResponse, setInventoryResponse] = useState<InventoryResponse>(null)
    const [inventoryUpdateCount, setInventoryUpdateCount] = useState(0)
    const [limitPage, setLimitPage] = useState(50)
    const [tableLoading, setTableLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [offset, setOffset] = useState(0);
    const [maxServicePrice, setMaxServicePrice] = useState(EServices.Steam)
    const [minServicePrice, setMinServicePrice] = useState(EServices.Steam)

    const freeServices = new Set(_freeServices)
    const disabledServices = new Set(_disabledServices)
    const marketableValue = [true, false]

	useEffect(() => {
		document.title = 'vvTable - Inventory'
        fetchInventory(inventoryFilter, user.steamID, offset)
	}, [])

    const gameChanged = (appId: number) => {
        if (inventoryFilter.appId === appId) return

        inventoryFilter.appId = appId

		if (!TableHelper.CheckServiceByAppId(inventoryFilter.service, appId)) {
			inventoryFilter.service = EServices.Steam
			inventoryFilter.priceType = EPriceType.Normal
		}
		if (!TableHelper.CheckServiceByAppId(inventoryFilter.service, appId)) {
			inventoryFilter.service = EServices.Steam
			inventoryFilter.priceType = EPriceType.Normal
		}

        getInventory()
	}

	const currencyChange = async (value: string) => {
		DataModel.Currency.SetCurrency(value)
		api.post('table/profile/update', { id: activeProfile, currency: value })
        inventoryFilter.code = value
        getInventory(false)
	}

    const getInventory = (needToGoFirstPage: boolean = true) => {
        let offsetInv = offset
        if (needToGoFirstPage)
        {
            offsetInv = 0
            setCurrentPage(1)
            setOffset(offsetInv)
        }

        setInventoryFilter(inventoryFilter)
        fetchInventory(inventoryFilter, user.steamID, offsetInv, limitPage)
    }

    const fetchInventory = async (filter: InventoryFilter, steamId: string, offset: number = 0, limit: number = 50, force: boolean = false) => {
        setTableLoading(true)
        let url = `inventory/get-inventory?steamId=${steamId}&service=${filter.service}&priceType=${filter.priceType}&appId=${filter.appId}&code=${filter.code}&force=${force}&sortPriceType=${filter.sortPriceType}&offset=${offset}&marketable=${filter.marketable}&limit=${limit}`

        try {
            const response = await api.get<InventoryResponse>(url)
            console.log(response)
            setInventoryResponse(response?.data)
            setInventoryUpdateCount(inventoryUpdateCount + 1)
            if (response?.data.inventory.length > 0) {

                const resultMax = Object.entries(response.data.totalPrices).reduce((a, b) => a[1] > b[1] ? a : b)[0]
                setMaxServicePrice(resultMax as unknown as EServices)
                
                const resultMin = Object.entries(response.data.totalPrices).filter(x => x[1] > 0).reduce((a, b) => a[1] < b[1] ? a : b)[0]
                setMinServicePrice(resultMin as unknown as EServices)
            }
        } catch (error) {
            if (error.response.data.status == EResponseExceptionStatus.InventoryClosedError)
            {
                Notification.ShowNotification(lang.Notification_InventoryClosedError)
            }

            console.log(error)
            setInventoryResponse(null)
        }
        setTableLoading(false)
    }

    const changePriceTypeStatus = (priceType: EPriceType) => {
        if (inventoryFilter.priceType === priceType) return
        inventoryFilter.priceType = priceType
        getInventory()
	}

    const changeMarketable = (marketable: boolean) => {
        if (inventoryFilter.marketable === marketable) return
        inventoryFilter.marketable = marketable
        getInventory()
    }

    const changeSortPriceTypeStatus = (sortPriceType: ESortPriceType) => {
        if (inventoryFilter.sortPriceType === sortPriceType) return 
        inventoryFilter.sortPriceType = sortPriceType
        getInventory()
    }

    const changeService = (service: EServices) => {
        if (inventoryFilter.service === service) return
        inventoryFilter.service = service
        inventoryFilter.priceType = EPriceType.Normal
        getInventory()
	}

    const getPriceTypeStr = (priceType: EPriceType): string => {
		return (
			{
				[EPriceType.Normal]: lang.TablePage_PriceTypeNormal,
				[EPriceType.Deposit]: lang.TablePage_PriceTypeDeposit,
				[EPriceType.Order]: lang.TablePage_PriceTypeOrder,
				[EPriceType.Average]: lang.TablePage_PriceTypeAverage,
				[EPriceType.NoHold]: lang.TablePage_PriceTypeNoHold,
			}[priceType] || 'Normal'
		)	
	}
 
    const getPage = (page: number) => {
        if (page === currentPage) return
        const offsetCurrent = page === 1 ? 0 : limitPage * (page - 1)

        setCurrentPage(page)
        setOffset(offsetCurrent)
        fetchInventory(inventoryFilter, user.steamID, offsetCurrent)
    }

    const nextPage = () => {
        let page = currentPage + 1
        if (page > inventoryResponse?.totalPage) return
        getPage(page)
    }
    
    const prevPage = () => {
        let page = currentPage - 1
        if (page < 1) return
        getPage(page)
    }

    const getMarketableStr = (isMarketable: string): string => {
        return (
            {
                ["true"]: lang.TablePage_Yes,
                ["false"]: lang.TablePage_No
            }[isMarketable] || ''
        )
    }

    const getSortTypeStr = (sortType: ESortPriceType): string => {
        return (
            {
                [ESortPriceType.Ascending] : lang.ESortPrice_AscendingPrice,
                [ESortPriceType.Descending] : lang.ESortPrice_DescendingPrice
            }[sortType] || 'Descending Price'
        )
    }

	const currencies = DataModel.Currency.CurrencySet()

    let printedExch = false
	let printedRoulette = false

	return (
        <div>
            <div className={`${styles.filterContent} elems-10`}>
                <SelectGame onChanged={gameChanged} app={inventoryFilter.appId}/>

                <Dropdown  title={`${lang.TablePage_Currency}:`}>
                    <div className={`${styles.title} title`}>{DataModel.Currency.Current}</div>
                    <div className='menu'>
                        {Array.from(currencies).map((v, i) => {
                            const code = v[0]
                            const currency = v[1]

                            if (code === DataModel.Currency.Current) return

                            return (
                                <div
                                    key={i}
                                    className={`${styles.menuItem} ${styles.currencyItem} menu-item`}
                                    onClick={() => currencyChange(code)}
                                    title={Extensions.Round(currency.Value).toString()}
                                >
                                    <span className={styles.currencyIcon}>{currency.Symbol}</span>
                                    {code}
                                </div>
                            )
                        })}
                    </div>
                </Dropdown>
                <Dropdown  title={`${lang.TablePage_PriceTypeTitle}:`}>
                    <div className={`${styles.title} title`}>{getPriceTypeStr(inventoryFilter.priceType)}</div>
                    <div className='menu'>
                        {Extensions.GetEnumValues(EPriceType).map((priceType, i) => {
                            const currService = inventoryFilter.service
                            if (!TableHelper.CheckServicePriceType(currService, priceType)) return
                            const name = getPriceTypeStr(priceType)
                            
                            return (
                                <div className={`${styles.menuItem} menu-item`} onClick={() => changePriceTypeStatus(priceType)} key={i}>
                                    {inventoryFilter.priceType === priceType ? (
                                        <i className='fa fa-check' aria-hidden='true'></i>
                                    ) : null}{' '}
                                    {name}
                                </div>
                            )
                        })}
                    </div>
                </Dropdown> 
                <Dropdown title={`${lang.TablePage_ServiceTitle}:`}>
                    <div className={`${styles.title} title`}>
                        <div className={styles.serviceLogo}>
                            <img
                                src={`Resources/ServicesIcons/${
                                    EServices[inventoryFilter?.service].toLowerCase()
                                }.png`}
                                alt='service'
                            />
                        </div>
                        {TableHelper.GetServiceName(inventoryFilter?.service)}
                    </div>
                    <div className='menu'>
                        <div className='menu-header no-close'>{lang.TablePage_Markets}</div>
                        {Extensions.GetEnumValues(EServices)
                            .filter(x => TableHelper.CheckServiceIsMarket(x))
                            .concat(Extensions.GetEnumValues(EServices).filter(x => !TableHelper.CheckServiceIsMarket(x) && !TableHelper.CheckServiceIsRoulette(x)))
                            .concat(Extensions.GetEnumValues(EServices).filter(x => TableHelper.CheckServiceIsRoulette(x)))
                            .map((service, i) => {
                                if (!TableHelper.CheckServiceByAppId(service, inventoryFilter?.appId)) return
                                if (disabledServices.has(service)) return
                                if (TableHelper.CheckServiceForSecret(service) && !user.secretAccess) return

                                const noAccess = !(TableHelper.CheckServiceBySubscription(service, user.access) || freeServices.has(service))
                                const isMarket = TableHelper.CheckServiceIsMarket(service)
                                const isRoulette = !isMarket && TableHelper.CheckServiceIsRoulette(service)
                                const isExch = !isMarket && !isRoulette
                                const icon = `Resources/ServicesIcons/${EServices[service].toLowerCase()}.png`
                                const name = TableHelper.GetServiceName(service)
                                const currService = inventoryFilter?.service

                                let titleJsx: ReactNode

                                if (!printedExch && isExch) {
                                    printedExch = true
                                    titleJsx = <div className='menu-header no-close'>{lang.TablePage_Exchangers}</div>
                                }

                                if (!printedRoulette && isRoulette) {
                                    printedRoulette = true
                                    titleJsx = <div className='menu-header no-close'>{lang.TablePage_Roulette}</div>
                                }

                                return (
                                    <React.Fragment key={i}>
                                        {titleJsx}
                                        {noAccess ? (
                                            <div className={`${styles.menuItem} ${styles.noClose} ${styles.noAccess}  menu-item no-close`}>
                                                <div className={`${styles.serviceLogo} ${styles.noClose} no-close`}>
                                                    <img className={`${styles.noClose} no-close`} src={icon} alt='icon' />
                                                </div>
                                                <div className={`${styles.noClose} d-inline-block no-close`}>
                                                    {name}
                                                    <div className={`${styles.noAccessPre} ${styles.noClose} d-inline-block no-close`}>Pro</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={`${styles.menuItem} menu-item`} onClick={() => changeService(service)}>
                                                {currService === service && <i className='fa fa-check' aria-hidden='true'></i>}
                                                <div className={styles.serviceLogo}>
                                                    <img src={icon} alt='icon' />
                                                </div>
                                                {name}
                                            </div>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                    </div>
                </Dropdown>

                <Dropdown title={`${lang.InventoryPage_SortedPriceTitle}:`}>
                    <div className={`${styles.title} title`}>{getSortTypeStr(inventoryFilter.sortPriceType)}</div>
                    <div className='menu'>
                        {Extensions.GetEnumValues(ESortPriceType).map((sortPriceType, i) => {
                            const name = getSortTypeStr(sortPriceType)
                            
                            return (
                                <div className={`${styles.menuItem} menu-item`} onClick={() => changeSortPriceTypeStatus(sortPriceType)} key={i}>
                                    {inventoryFilter.sortPriceType === sortPriceType ? (
                                        <i className='fa fa-check' aria-hidden='true'></i>
                                    ) : null}{' '}
                                    {name}
                                </div>
                            )
                        })}
                    </div>
                </Dropdown> 

                <Dropdown title={`${lang.InventoryPage_MarketableText}:`}>
                    <div className={`${styles.title} title`}>{getMarketableStr(`${inventoryFilter.marketable}`)}</div>
                    <div className='menu'>
                        {
                            marketableValue.map((marketable, i) => {
                                const name = getMarketableStr(`${marketable}`)
                            
                                return (
                                    <div className={`${styles.menuItem} menu-item`} onClick={() => changeMarketable(marketable)} key={i}>
                                        {inventoryFilter.marketable === marketable ? (
                                            <i className='fa fa-check' aria-hidden='true'></i>
                                        ) : null}{' '}
                                        {name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </Dropdown> 

                <div className={styles.updateInventoryBtn}
                    onClick={() => fetchInventory(inventoryFilter, user.steamID, offset, limitPage, true)}>
                    {lang.InventoryPage_UpdateInventory}
                </div>
            </div>
            <div className={styles.inventoryContent}>
                <div>
                    <div className={styles.totalPricesHead}>
                        <span>{inventoryResponse?.inventory !== undefined && inventoryResponse?.inventory.length > 0
                            ? `${lang.InventoryPage_InventoryTotalPrice}:`
                            : undefined}
                        </span>
                    </div>
                    {
                        inventoryResponse?.totalPrices === undefined 
                        ? null
                        : Object.keys(inventoryResponse?.totalPrices)?.map((x, i) => {
                            const service: EServices = x as unknown as EServices
                            if (disabledServices.has(service)) return
                            const price = inventoryResponse?.totalPrices[service]

                            if (price === 0) return
                            
                            const currencySymbol = CurrencyData.AllCurrencies.get(DataModel.Currency.Current)?.Symbol
                            return (
                                <React.Fragment>
                                    <div className={`${styles.servicePriceItem} ${service === maxServicePrice ? styles.maxServicePrice : ''} ${service === minServicePrice ? styles.minServicePrice : ''}`} key={i}>
                                        <div className={styles.serviceLogo}>
                                            <img src={`Resources/ServicesIcons/${EServices[x].toLowerCase()}.png`} alt='icon' />
                                        </div>
                                        <span className={`${service == inventoryResponse?.currentService ? styles.currentServices : ''}`}>
                                            {TableHelper.GetServiceName(service)} - {price} {currencySymbol}
                                        </span>
                                    </div>
                                </React.Fragment>
                            )
                        })

                    }
                </div>
                {tableLoading ?
                        <div className={`${styles.inventoryLoading}`}>
                            <LoadingSpinner />
                        </div>
                        :
                        <div style={ { width: '100%' } }>
                        {
                            inventoryResponse?.inventory === undefined || inventoryResponse?.inventory?.length === 0 
                            ? !tableLoading && 
                            <div className={`${styles.inventoryEmpty}`}> 
                                {lang.InventoryPage_InventoryEmptyText}
                            </div>
                            : 
                            <React.Fragment>
                                <ProductCardBox key={inventoryUpdateCount} className={styles.inventory}>
                                    <React.Fragment>
                                        <span className={styles.countItems}>
                                            {lang.InventoryPage_InventoryCountText}: {inventoryResponse?.inventory !== undefined ? inventoryResponse.totalItems : "0"}
                                        </span>
                                        {
                                            inventoryResponse?.inventory?.map((x, i) => 
                                                <InventoryCard itemCard={x} pattern={inventoryResponse.imagePattern} currency={inventoryResponse.code} appId={inventoryResponse.appId} 
                                                    contextId={inventoryResponse.contextId} service={inventoryResponse.currentService} className={CardClassName} key={i} />
                                            )
                                        }
                                    </React.Fragment>
                                </ProductCardBox>
                                <div  className={styles.listPage}>
                                    <div className={styles.pagination}>
                                        <div className={styles.paginationItemPage}
                                        onClick={() => prevPage()}>
                                            <i className="fas fa-angle-left"></i>
                                        </div>
                                            {
                                                Array.from({length: inventoryResponse?.totalPage}, (_x, i) => 
                                                {
                                                    return (
                                                        <div key={i + 1} 
                                                            className={`${styles.paginationItemPage} ${(i + 1) === currentPage ? styles.paginationCurrentPage : undefined}`}
                                                            onClick={() => getPage(i + 1)}
                                                            >
                                                            {i + 1}
                                                        </div>
                                                    )
                                                })
                                            }
                                        <div className={styles.paginationItemPage}
                                            onClick={() => nextPage()}>
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            
                        }
                        
                    </div> 
                }
               
            </div>
            
            <PriceCheck appId={inventoryResponse?.appId}/>
        </div>
	)
}

export default InventoryPage
