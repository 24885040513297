import { EInventoryFilterActionTypes, IInventoryFilterState as IInventoryFilterState, InventoryFilterAction } from '../../types/redux/InventoryFilter'
import { EPriceType, EServices, ESortPriceType } from '../../types/tableHelper'

const initialState: IInventoryFilterState = {
	inventoryFilter: {
		appId: 730,
		marketable: false,
		code: 'USD',
		force: false,
		priceType: EPriceType.Normal,
		service: EServices.Steam,
		sortPriceType: ESortPriceType.Descending
	},
	loaded: false,
}

export const inventoryFilterReducer = (state: IInventoryFilterState = initialState, action: InventoryFilterAction): IInventoryFilterState => {
	switch (action.type) {
		case EInventoryFilterActionTypes.SET_INVENTORY_FILTER:
			return { 
                ...state,
                inventoryFilter: action.payload
            }
		default:
			return state
	}
}


