export default class Notification {
	private static ntfList: Element = null

	private static playNotificationSound() {
		let s = document.getElementById('ntf-sound') as HTMLAudioElement
		s.volume = 0.5
		s.currentTime = 0
		s.play()
	}

	public static ShowNotification(
		text: string,
		time: number = 5,
		withSound: boolean = true
	): void {
		if (!this.ntfList) {
			this.ntfList = document.getElementsByClassName('notify-list')[0]
		}

		let notification = document.createElement('div')
		notification.className = 'notify-item'
		notification.style.animationDuration = `${time}s`

		notification.innerText = text
		this.ntfList.appendChild(notification)

		if (withSound) {
			this.playNotificationSound()
		}

		setTimeout(() => notification.remove(), time * 1000)
	}
}
