import { CurrencyData } from './../../types/currency/currency'
import { EUserActionTypes, IUserState, UserAction } from '../../types/redux/user'
import Toast from '../../components/Elements/Toast/toast'
import cookie from '../../models/cookie'

const initialState: IUserState = {
	user: null,
	userInfo: null,
	currencies: null,
	feed: null,
	activeProfile: 0,
	priceLimit: null,
	loading: false,
	loaded: false,
	error: null,
}

export const userReducer = (state = initialState, action: UserAction): IUserState => {
	switch (action.type) {
		case EUserActionTypes.FETCH_USER:
			return {
				loading: true,
				error: null,
				user: null,
				userInfo: null,
				currencies: null,
				feed: null,
				activeProfile: 0,
				priceLimit: null,
				loaded: false,
			}
		case EUserActionTypes.FETCH_USER_SUCCESS:
			const currency = action.payload.currencies

			if (currency) {
				Object.entries(currency).map(item => {
					const key = item[0]
					CurrencyData.AllCurrencies.get(key).Value = currency[key]
				})
			}

			return {
				user: action.payload.userData,
				userInfo: action.payload.userInfo,
				currencies: action.payload.currencies,
				feed: action.payload.feed,
				activeProfile: action.payload.activeProfile,
				priceLimit: action.payload.priceLimit,
				loading: false,
				error: null,
				loaded: true,
			}
		case EUserActionTypes.FETCH_USER_ERROR:
			return {
				user: null,
				userInfo: null,
				currencies: null,
				feed: null,
				activeProfile: 0,
				priceLimit: null,
				error: action.payload,
				loading: false,
				loaded: true,
			}
		case EUserActionTypes.FETCH_USER_BANNED:
			const lang = cookie.GetCookie('lang')

			document.location.hash = '#banned'

			if (lang === '0') {
				new Toast(`${action.payload.nickname}, вы были забанены`, `Причина: ${action.payload.banReason}`, 'danger', true, 10_000)
			}
			else {
				new Toast(`${action.payload.nickname}, you are banned`, `Reason: ${action.payload.banReason}`, 'danger', true, 10_000)
			}

			return {
				user: null,
				userInfo: null,
				currencies: null,
				feed: null,
				activeProfile: 0,
				priceLimit: null,
				error: null,
				loading: false,
				loaded: true,
			}
		case EUserActionTypes.SET_USER:
			return { ...state, user: action.payload }
		case EUserActionTypes.SET_USER_INFO:
			return { ...state, userInfo: action.payload }
		case EUserActionTypes.SET_USER_INFO_TRADE_URL:
			return { ...state, userInfo: { ...state.userInfo, tradeOfferLink: action.payload } }
		case EUserActionTypes.SET_USER_INFO_TELEGRAM:
			return { ...state, userInfo: { ...state.userInfo, telegram: action.payload } }
		case EUserActionTypes.SET_USER_INFO_VK:
			return { ...state, userInfo: { ...state.userInfo, vk: action.payload } }
		case EUserActionTypes.SET_ACTIVE_PROFILE:
			return { ...state, activeProfile: action.payload }
		default:
			return state
	}
}
