export interface UserInfo {
	tradeOfferLink: string
	vk: VkUser
	telegram: TelegramUser
}

export interface TelegramAuth {
	id: bigint
	first_name: string
	last_name: string
	auth_date: number
	hash: string
	photo_url: string
	username: string
}

export class TelegramUser {
	userID: bigint = BigInt(0)
	firstName?: string
	lastName?: string
	authTime?: number
	photoUrl?: string
	isSub?: boolean

	static TelegramUserFromAuth(v: TelegramAuth): TelegramUser {
		return {
			userID: v.id,
			firstName: v.first_name,
			lastName: v.last_name,
			photoUrl: v.photo_url,
			authTime: v.auth_date,
		}
	}
}

export interface VkAuth {
	uid: bigint
	app_id: number
	first_name: string
	last_name: string
	hash: string
	photo: string
}

export class VkUser {
	userID: bigint = BigInt(0)
	firstName?: string
	lastName?: string
	authTime?: number
	photoUrl?: string
	isSub?: boolean

	static VkUserFromAuth(v: VkAuth): VkUser {
		return {
			userID: v.uid,
			firstName: v.first_name,
			lastName: v.last_name,
			photoUrl: v.photo,
			authTime: Math.round(Date.now() / 1000),
		}
	}
}

export interface SocialResponse {
	status: EResponseCode
	isSub: boolean
}

export enum EResponseCode {
	Success,
	Error,
	IncorrectTradeOfferLink,
	TokenAlreadyUsed,
	IncorrectData,
	AccountAlreadyUsed,
}
