import React, { FC, useEffect, useRef, useState } from 'react'
import { api } from '../../api/api'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Currency from '../../models/currency'
import DataModel from '../../models/dataModel'
import Extensions from '../../models/extensions'
import Notification from '../../models/notification'
import { CheckItemsFilters } from '../../models/pageModels/table/tableExtensions'
import { ItemSingleResponse } from '../../types/table/itemResponse'
import { ServicesFee } from '../../types/table/requestData'
import { SingleItem } from '../../types/table/singleItem'
import { EPriceType, EServices, TableHelper } from '../../types/tableHelper'
import LoadingSpinner from '../LoadingSpinner'
import styles from './css/PriceCheck.module.css'
import { CurrencyData } from '../../types/currency/currency'

export interface PriceCheckProps {
    appId: number
}

let checkItem: SingleItem = null

const PriceCheck: FC<PriceCheckProps> = (props: PriceCheckProps) => {
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)
	const { active, itemId } = useTypedSelector(x => x.checkItem)
	const { data: fees } = useTypedSelector(x => x.fees)

	const [checkLoading, setCheckLoading] = useState(false)
	const [checkFilters, setCheckFilters] = useState<CheckItemsFilters>()

	const { closeCheckItem } = useActions()

	const checkImgRef = useRef<HTMLImageElement>()

	useEffect(() => {
		if (active) {
			checkItem = null
			getCheckItem()
		}
	}, [active])

	if (!props.appId) return

	const getCheckItem = async () => {

		if (checkLoading) return

		setCheckLoading(true)

		const checkFilters = new CheckItemsFilters()
		checkFilters.currency = new Currency()
		checkFilters.currency.Current = DataModel.Currency.Current
		setCheckFilters(checkFilters)
		try {
			const response = await api.get<SingleItem>(`table/check/${props.appId}/${itemId}`)

			checkItem = response.data
		} catch {
			Notification.ShowNotification(lang.Notification_Error)
		} finally {
			setCheckLoading(false)
		}
	}

	const checkDisable = () => {
		closeCheckItem()
	}

	const getPriceTypeStr = (priceType: EPriceType): string => {
		return (
			{
				[EPriceType.Normal]: lang.TablePage_PriceTypeNormal,
				[EPriceType.Deposit]: lang.TablePage_PriceTypeDeposit,
				[EPriceType.Order]: lang.TablePage_PriceTypeOrder,
				[EPriceType.Average]: lang.TablePage_PriceTypeAverage,
				[EPriceType.NoHold]: lang.TablePage_PriceTypeNoHold,
			}[priceType] || 'Normal'
		)
	}

	if (!checkFilters) return

	const currencySourceArray = Array.from(checkFilters.currency.Codes())
	const currencyList: string[][] = [];
	let index = 0;

	for (let i = 0; i < currencySourceArray.length; i++) {
		if (i % 16 === 0) {
			currencyList.push([])
			index = currencyList.length - 1
		}

		currencyList[index].push(currencySourceArray[i])
	}

	return (
		<div
			className={`${styles.checkMenu} ${styles.flex} flex ${active ? styles.cmShow : ''}`}
			onClick={e => {
				if (e.target['id'] === 'cm-popup') {
					checkDisable()
				}
			}}
			id='cm-popup'
		>
			<div className={styles.cmHeader}>
				{(() => {
					if (!(props.appId && active && checkItem != null)) return

					const name = checkItem.name
					const cf = checkFilters

					let imageUrl: string = 'Resources/no-image.png'

					if (checkItem.imageUrl) {
						const imageSize = 128

						if (checkItem.imageUrl.startsWith('https')) imageUrl = checkItem.imageUrl
						else imageUrl = Extensions.GetItemImageLink(checkItem.imageUrl, imageSize)
					}
					
					return (
						<React.Fragment>
							<div className={`${styles.cmTitle} flex-center`}>
								<div>{lang.TablePage_CheckPrice_Title}</div>
							</div>
							<div className='flex-center mt-1 mb-1'>
								<img
									src={imageUrl}
									alt='item-img'
									className={styles.itemImg}
									ref={checkImgRef}
									onError={() => {
										checkImgRef.current.onerror = null
										checkImgRef.current.src = 'Resources/no-image.png'
									}}
								/>
								<div className={styles.itemName} onClick={() => window.blaz.CopyToClipboard(name)} title={lang.TablePage_Title_Copy}>
									{name}
								</div>
							</div>
							<div className={styles.cmContent}>
								{checkLoading ? (
									<LoadingSpinner />
								) : checkItem.data && cf != null ? (
									Object.entries(checkItem.data).map((item, i) => {
										const key = EServices[item[0]]
										const value = item[1] as ItemSingleResponse

										let currentFee =
										(fees === null) 
										? DataModel.GetDefaultFees()
										: fees;

										const fee = currentFee[props?.appId][key] as ServicesFee
										const price: number =
											{
												[EPriceType.Normal]: value.n,
												[EPriceType.Order]: value.o,
												[EPriceType.Deposit]: !TableHelper.CheckServiceForDepositCalculate(key)
													? value.d
													: Extensions.Floor(value.d * (1 - (fee.fee - fee.bonus) / 100)),
												[EPriceType.Average]: value.a,
												[EPriceType.NoHold]: value.h,
											}[cf.priceType] || 0

										if (price <= 0) return

										const count: number =
											{
												[EPriceType.Order]: value.q,
												[EPriceType.NoHold]: value.j,
											}[cf.priceType] || value.c

										const maxCount = value.m

										const countStr = count === -1 ? '1+' : maxCount > 0 ? `${count}/${maxCount}` : `x${count}`

										return (
											<div className={styles.cmItem} key={i}>
												<div className={styles.serviceLogo}>
													<img src={`Resources/ServicesIcons/${EServices[key].toLowerCase()}.png`} alt='service' />
												</div>
												<a
													className='btn-simple-hov'
													href={Extensions.GetItemLink(name, props.appId, key)}
													rel='noreferrer nofollow'
													target='_blank'
												>
													{TableHelper.GetServiceName(key)}
												</a>
												:{' '}
												<strong>
													{cf.currency.GetCurrencyTextByService(price, key)} <span className={styles.count}>({countStr})</span>
												</strong>
											</div>
										)
									})
								) : null}
							</div>
							<div className={`${styles.cmClose}`}>
								<span className='btn-simple-hov' onClick={checkDisable}>
									✖
								</span>
							</div>
							<div className={styles.cmSettings}>
								<div className={styles.cmSettingsList}>
									{Extensions.GetEnumValues(EPriceType).map((pt, i) => (
										<div
											className={`${styles.cmSettingsItem} ${cf.priceType === pt ? styles.active : ''}`}
											onClick={() => {
												cf.priceType = pt
												setCheckFilters({ ...cf })
											}}
											key={i}
										>
											{getPriceTypeStr(pt)}
										</div>
									))}
								</div>
								{currencyList.map((elems, i) => (
									<div className={`${styles.cmSettingsList}${i === 0 ? ' mt-3' : ''}`} key={i}>
										{elems.map((currency, i) => (
											<div
												className={`${styles.cmSettingsItem} ${cf.currency.Current === currency ? styles.active : ''}`}
												onClick={() => {
													cf.currency.SetCurrency(currency)
													setCheckFilters({ ...cf })
												}}
												key={i}
											>
												{currency}
											</div>
										))}
									</div>
								))}
							</div>
						</React.Fragment>
					)
				})()}
			</div>
		</div>
	)
}

export default PriceCheck
