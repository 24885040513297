import { ESalesPeriod, EServices, EPriceType, ItemType, ItemTypeController } from './../tableHelper'

export enum EOrderBy {
	None,
	Price1,
	Price2,
	Profit,
	Sales,
	Count1,
	Count2,
}

export enum EOrderDir {
	Asc,
	Desc,
}

export class Filter {
	appId: number // AppId
	minSales: number // Min Sales
	salesPeriod: ESalesPeriod // SalesPeriod
	salesService: EServices // SalesService

	profitMin: number // Profit min
	profitMax: number // Profit max
	service1: EServices // Service 1
	service2: EServices // Service 2
	priceType1: EPriceType // Price Type 1
	priceType2: EPriceType // Price Type 2
	priceMin1: number // Price min 1
	priceMax1: number // Price max 1
	priceMin2: number // Price min 2
	priceMax2: number // Price max 2
	countMin1: number // Count min 1
	countMin2: number // Count min 2
	countMax1: number // Count max 1
	countMax2: number // Count max 2
	autoReset: boolean // Auto Reset
	maxHoldHours: number // Hold hour
	withSound: boolean // With sound
	inInventory: boolean // in inventory items
	order: EOrderBy // OrderBy
	direction: EOrderDir // Dir

	searchName: string

	types?: Object

	allTypes: Map<number, ItemTypeController>

	filterSales: Map<ESalesPeriod, FilterSales[]>

	public static GetTypesObject(filter: Filter): Object {
		if (!filter?.allTypes) return {}

		const types: Object = {}

		for (const app of filter.allTypes) {
			types[app[0]] = {}
			for (const name of app[1].Types) {
				types[app[0]][name[0]] = {}
				for (const type of name[1]) {
					types[app[0]][name[0]][type[0]] = type[1].TypeValue
				}
			}
		}

		return types
	}
}

export class FilterSales {
	SalesService: EServices // Service
	MinSales: number // Min Sales
	MaxSales: number // Max Sales
}
