import {
	ILoadingState,
	LoadingAction,
	ELoadingActionTypes,
} from './../../types/redux/loading'

const initialState: ILoadingState = {
	loading: false,
	loadingScreen: false,
}

export const loadingReducer = (
	state: ILoadingState = initialState,
	action: LoadingAction
): ILoadingState => {
	switch (action.type) {
		case ELoadingActionTypes.SET_LOADING:
			return {
				...state,
				loading: action.payload,
			}
		case ELoadingActionTypes.SET_LOADING_SCREEN:
			return {
				...state,
				loadingScreen: action.payload,
			}
		default:
			return state
	}
}
