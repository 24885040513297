import {
	ILoginState,
	LoginAction,
	ELoginActionTypes,
} from './../../types/redux/login'

const initialState: ILoginState = {
	active: false,
	action: null,
}

export const loginReducer = (
	state: ILoginState = initialState,
	action: LoginAction
): ILoginState => {
	switch (action.type) {
		case ELoginActionTypes.SHOW_LOGIN_NEEDED:
			return {
				active: true,
				action: action.payload,
			}
		case ELoginActionTypes.CLOSE_LOGIN_NEEDED:
			return {
				...state,
				active: false,
			}
		default:
			return state
	}
}
