import { FC, useLayoutEffect, useRef } from 'react'
import DataModel from '../models/dataModel'
import styles from './css/PromoCsMoney.module.css'
import { useTypedSelector } from '../hooks/useTypedSelector'

const PromoCsMoney: FC = () => {
	const ref = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		ref.current.style.setProperty('padding', '0', 'important')
	}, [])

	const {
		current: currLang
	} = useTypedSelector(x => x.lang)

	if (currLang === 0) {

	}
	else {

	}

	const listOfBanner = [ 'banner1', 'banner2']
    const bannerName = listOfBanner[DataModel.PromoCsMoneyRandomImage]

	const typeBanner = currLang === 0 ? bannerName : 'banner3_en'
	const url = currLang === 0 
		? `https://cs.money/market/instant-sell/?utm_source=mediabuy&utm_medium=vvtable&utm_campaign=instantsell&utm_content=${typeBanner}`
		: `https://cs.money/?utm_source=mediabuy&utm_medium=vvtable&utm_campaign=vvtable&utm_content=${typeBanner}`

	return (
		<div className={styles.container} ref={ref}>
			<a href={url} target={'_blank'} rel={'nofollow'}>
				<img src={`Resources/promo/csmoney/${typeBanner}.png`} />
			</a>
		</div>
	)
}

export default PromoCsMoney
