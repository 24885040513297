import { TelegramUser, UserInfo, VkUser } from './../../types/user/userInfo'
import { api } from './../../api/api'
import { Dispatch } from 'react'
import { EUserActionTypes, UserAction } from '../../types/redux/user'
import { User, UserResponse } from '../../types/user/user'
import { AxiosError } from 'axios'
import { BannedUser } from '../../types/user/bannedUser'

export const fetchUser = () => {
	return async (dispatch: Dispatch<UserAction>) => {
		try {
			dispatch({ type: EUserActionTypes.FETCH_USER })
			const response = await api.get('user')

			dispatch({
				type: EUserActionTypes.FETCH_USER_SUCCESS,
				payload: response.data as UserResponse,
			})
		} catch (error) {
			if (error instanceof AxiosError) {
				const err = error as AxiosError
				const data = err?.response?.data as BannedUser

				if (data?.banned) {
					dispatch({
						type: EUserActionTypes.FETCH_USER_BANNED,
						payload: data,
					})
				}
				else {
					dispatch({
						type: EUserActionTypes.FETCH_USER_ERROR,
						payload: 'Произошла ошибка при загрузке пользователя',
					})
				}
			}
			else {
				dispatch({
					type: EUserActionTypes.FETCH_USER_ERROR,
					payload: 'Произошла ошибка при загрузке пользователя',
				})
			}
		}
	}
}

export const setUser = (user: User) => {
	return {
		type: EUserActionTypes.SET_USER,
		payload: user,
	}
}

export const setUserInfo = (userInfo: UserInfo) => {
	return {
		type: EUserActionTypes.SET_USER_INFO,
		payload: userInfo,
	}
}

export const setUserInfoTradeUrl = (url: string) => {
	return {
		type: EUserActionTypes.SET_USER_INFO_TRADE_URL,
		payload: url,
	}
}

export const setUserInfoTelegram = (user: TelegramUser) => {
	return {
		type: EUserActionTypes.SET_USER_INFO_TELEGRAM,
		payload: user,
	}
}

export const setUserInfoVk = (user: VkUser) => {
	return {
		type: EUserActionTypes.SET_USER_INFO_VK,
		payload: user,
	}
}

export const setActiveProfile = (id: number) => {
	return {
		type: EUserActionTypes.SET_ACTIVE_PROFILE,
		payload: id,
	}
}
