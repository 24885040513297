import {
	EProfileActionTypes,
	IProfileState,
	ProfileAction,
} from './../../types/redux/profile'

const initialState: IProfileState = {
	loaded: false,
	referrals: null,
	loading: false,
}

export const profileReducer = (
	state: IProfileState = initialState,
	action: ProfileAction
): IProfileState => {
	switch (action.type) {
		case EProfileActionTypes.FETCH_PROFILE:
			return { loading: true, referrals: null, loaded: false }
		case EProfileActionTypes.FETCH_PROFILE_SUCCESS:
			return { referrals: action.payload, loading: false, loaded: true }
		case EProfileActionTypes.FETCH_PROFILE_ERROR:
			return { referrals: action.payload, loading: false, loaded: true }
		case EProfileActionTypes.SET_PROFILE_REFERRALS:
			return { ...state, referrals: action.payload }
		default:
			return state
	}
}
