import { ESubscription, User } from './../user/user'

export interface SubscriptionBuyRequest {
	sub: ESubscription
}

export interface SubscriptionBuyResponse {
	code: EResponseCode
	user: User
}

export enum EResponseCode {
	None,
	Success,
	NoBalance,
	SubIsBetter,
	NotFound,
	BadRequest,
	UnexpectedError,
	SubPaused,
}

export interface Subscription {
	access: ESubscription
	price: number
	discount: number
}
