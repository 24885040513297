import './toast.css'

class Toast {
	private _title: string
	private _text: string
	private _theme: string
	private _autoHide: boolean
	private _interval: number
	private _el: HTMLDivElement
	private _withSound: boolean

	constructor(title: string, text: string, theme: string = 'custom', autoHide: boolean = true, interval: number = 5000, withSound: boolean = false) {
		this._title = title
		this._text = text ?? 'Message...'
		this._theme = theme ?? 'default'
		this._autoHide = autoHide ?? true
		this._interval = +interval || 5000
		this._withSound = withSound
		this._create()
		this._el.addEventListener('click', e => {
			const elem = e.target as HTMLElement

			if (elem.classList.contains('toast__close')) {
				this._hide()
			}
		})
		this._show()
	}
	_show() {
		if (this._withSound)
		{
			this._playNotificationSound()
		}
		this._el.classList.add('toast_showing')
		this._el.classList.add('toast_show')
		window.setTimeout(() => {
			this._el.classList.remove('toast_showing')
		})
		if (this._autoHide) {
			setTimeout(() => {
				this._hide()
			}, this._interval)
		}
	}
	_hide() {
		this._el.classList.add('toast_showing')
		this._el.addEventListener(
			'transitionend',
			() => {
				this._el.classList.remove('toast_showing')
				this._el.classList.remove('toast_show')
				this._el.remove()
			},
			{ once: true }
		)
		const event = new CustomEvent('hide.toast', { detail: { target: this._el } })
		document.dispatchEvent(event)
	}
	_create() {
		const el = document.createElement('div')
		el.classList.add('toast')
		el.classList.add(`toast_${this._theme}`)
		let html = `{header}<div class="toast__body"></div><button class="toast__close" type="button"></button>`
		const htmlHeader = this._title === null ? '' : '<div class="toast__header"></div>'
		html = html.replace('{header}', htmlHeader)
		el.innerHTML = html
		if (this._title) {
			el.querySelector('.toast__header').textContent = this._title
		} else {
			el.classList.add('toast_message')
		}
		el.querySelector('.toast__body').textContent = this._text
		this._el = el
		if (!document.querySelector('.toast-container')) {
			const container = document.createElement('div')
			container.classList.add('toast-container')
			document.body.append(container)
		}
		document.querySelector('.toast-container').append(this._el)
	}

	_playNotificationSound() {
		let s = document.getElementById('ntf-sound') as HTMLAudioElement
		s.volume = 0.5
		s.currentTime = 0
		s.play()
	}
}

export default Toast
