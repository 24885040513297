import { FC, useCallback, useState, MouseEvent, useMemo } from 'react'
import styles from '../css/InventoryCard.module.css'
import { InventoryItem } from '../../models/Inventory'
import { CurrencyData } from '../../types/currency/currency'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useAction'
import { useContextMenu } from '../../hooks/useContextMenu'
import Notification from '../../models/notification'
import { ContextMenuProvider } from '../../context'
import PriceCheck from '../Table/PriceCheck'
import React from 'react'
import { EServices, TableHelper } from '../../types/tableHelper'
import Extensions from '../../models/extensions'

export interface InventoryItemProps {
	itemCard: InventoryItem
    pattern: string
    currency: string
    appId: number
    contextId: number
    service: EServices
    className?: string
}

const InventoryCard: FC<InventoryItemProps> = (props: InventoryItemProps) => {
    const { setContextMenu } = useContextMenu();
    const {
		current: currentLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

    const {
        user
    } = useTypedSelector(x => x.user)

    const { showCheckItem } = useActions()

    const checkActive = (id: number) => {
		showCheckItem(id)
	}

    const getImageUrl = (pattern: string, imagePath: string) => {
        return pattern?.replace('{0}', imagePath)
    }

    const contextMenu = [
        {
            name: `${lang.InventoryPage_SteamLinkInventory }`,
            onClick: () => {
                let link = `https://steamcommunity.com/profiles/${user.steamID}/inventory/#${props.appId}_${props.contextId}_${props.itemCard.assetId}`
                window.open(link,'_blank');
            }
        },
        {
            name: `${lang.InventoryPage_ServiceItemLink } (${TableHelper.GetServiceName(props.service)})`,
            onClick: () => {
                let link = Extensions.GetItemLink(props.itemCard.marketHashName, props.appId, props.service)
                window.open(link,'_blank');
            }
        },
        {
            name: `${lang.InventoryPage_CopyName}`,
            onClick: () => {
                navigator.clipboard.writeText(props.itemCard.marketHashName)
                Notification.ShowNotification(lang.Notification_CopySuccess, 5, false)
            }
        }
    ]

    if (props.itemCard.id) {
        contextMenu.push({ 
            name: lang.InventoryPage_InfoItem,
            onClick: () => checkActive(props.itemCard.id)
        })
    }

    const handleContextMenu = useCallback((event: MouseEvent) => {
        event.preventDefault();
        const { clientX, clientY } = event
        setContextMenu(contextMenu, [clientX, clientY], props.itemCard.marketHashName)
    }, [setContextMenu, contextMenu])

	return (
            <div className={`${styles.card} ${!props.itemCard?.marketable ? styles.notMarketableCard : undefined} ${props.className}`} 
                title={props.itemCard?.marketHashName}
                onContextMenu={handleContextMenu}>
                <div className={`${styles.imageContainer}`}>
                    <img src={`${getImageUrl(props.pattern, props.itemCard?.image)}`} className={styles.image}/>
                </div>    
                <div className={`${styles.footerItem}`}>
                    <div className={`${styles.nameContainer}`}>
                        <span className={`${styles.name}`}>
                            {props.itemCard?.marketHashName ?? '-'}
                        </span>
                    </div>
                    <div className={`${styles.priceContainer}`}>
                        <div>
                            x{props.itemCard?.count ?? 0}
                        </div>
                        <div className={`${styles.price}`}>
                            {
                                props.itemCard?.marketable && props.itemCard?.price > 0
                                ? `${props.itemCard.price} ${CurrencyData.AllCurrencies.get(props.currency)?.Symbol}`
                                : '-'
                            }
                        </div>
                    </div>
                </div>
            </div>
	)
}

export default InventoryCard

