export interface ILoadingState {
	loading: boolean
	loadingScreen: boolean
}

export enum ELoadingActionTypes {
	SET_LOADING = 'SET_LOADING',
	SET_LOADING_SCREEN = 'SET_LOADING_SCREEN',
}

interface ISetLoadingAction {
	type: ELoadingActionTypes.SET_LOADING
	payload: boolean
}

interface ISetLoadingScreenAction {
	type: ELoadingActionTypes.SET_LOADING_SCREEN
	payload: boolean
}

export type LoadingAction = ISetLoadingAction | ISetLoadingScreenAction
