import { HomeData } from './../home/homeData'

export interface IHomeState {
	data: HomeData
	loading: boolean
	loaded: boolean
	error: null | string
}

export enum EHomeActionTypes {
	FETCH_HOME = 'FETCH_HOME',
	FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS',
	FETCH_HOME_ERROR = 'FETCH_HOME_ERROR',
}

interface IFetchHomeAction {
	type: EHomeActionTypes.FETCH_HOME
}

interface IFetchHomeSuccessAction {
	type: EHomeActionTypes.FETCH_HOME_SUCCESS
	payload: HomeData
}

interface IFetchHomeErrorAction {
	type: EHomeActionTypes.FETCH_HOME_ERROR
	payload: string
}

export type HomeAction =
	| IFetchHomeAction
	| IFetchHomeSuccessAction
	| IFetchHomeErrorAction
