import { FC, useEffect, useRef, useState } from 'react'
import styles from './css/InputWindow.module.css'

export interface InputWindowProps {
	active: boolean
	startValue?: string
	buttonText?: string
	onSave?: (value: string) => void
	onClose: () => void
}

const InputWindow: FC<InputWindowProps> = props => {
	props.buttonText ??= 'Text'
	props.startValue ??= ''

	const [value, setValue] = useState(props.startValue)

	const inputRef = useRef<HTMLInputElement>()

	useEffect(() => {
		if (props.active) {
			setValue(props.startValue)
			inputRef?.current?.focus()
		}
	}, [props.active])

	const save = () => {
		props.onSave?.call(this, value)
		props.onClose?.call(this)
	}

	return (
		<div
			className={`${styles.space}${props.active ? ` ${styles.active}` : ''}`}
			onClick={e => {
				const target = e.target as HTMLElement

				if (target.classList.contains(styles.container)) {
					props.onClose?.call(this)
				}
			}}
		>
			<div className={styles.container}>
				<input
					type='text'
					value={value}
					onChange={e => setValue(e.target.value)}
					ref={inputRef}
					onKeyDown={e => {
						if (e.key === 'Escape') {
							props.onClose?.call(this)
							return
						}

						if (e.key === 'Enter') {
							save()
							return
						}
					}}
					spellCheck={false}
				/>
				<div className={`${styles.btn} btn-simple-hov`} onClick={save}>
					{props.buttonText}
				</div>
			</div>
		</div>
	)
}

export default InputWindow
