import React, { FC } from 'react'

export interface ButtonProps {
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	iconAwesomeClass?: string
	text: string
}

const Button: FC<ButtonProps> = ({ onClick, text, iconAwesomeClass }) => {
	return (
		<div className='btn-main btn-hov' onClick={onClick}>
			{text} {iconAwesomeClass && <i className={`${iconAwesomeClass} btn-icon-r`}></i>}
		</div>
	)
}

export default Button
