import { EFilterSalesActionTypes, FilterSalesAction, IFilterSalesState } from '../../types/redux/filterSales'

const initialState: IFilterSalesState = {
	active: false
}

export const filterSalesReducer = (state: IFilterSalesState = initialState, action: FilterSalesAction): IFilterSalesState => {
	switch (action.type) {
		case EFilterSalesActionTypes.SHOW_FILTER_SALES:
			return {
                active: true
			}
		case EFilterSalesActionTypes.CLOSE_FILTER_SALES:
			return {
                active: false
			}
		default:
			return state
	}
}
