import { ILanguage } from './../models/language/language'
import { Deposit } from './../types/signalR/deposit'
import { User } from './../types/user/user'
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { EEndpoints as Endpoints } from './endpoints'
import SignalRModel from './signalRModel'
import { EServices } from '../types/tableHelper'
import Toast from '../components/Elements/Toast/toast'
import Extensions from '../models/extensions'
import { ImgHTMLAttributes } from 'react'

class MainConnection {
	private readonly _context: SignalRModel
	private readonly _connection: HubConnection

	private readonly _onDisconnected: CustomEvent
	private readonly _reconnectTimes = [10_000, 15_000, 20_000, 30_000, 45_000, 60_000, 120_000]

	private _user: User
	private _lang: ILanguage
	private _setUser: (user: User) => void

	constructor() {
		this._connection = new HubConnectionBuilder().withUrl(window.location.origin + '/api/ws', HttpTransportType.WebSockets).build()

		this._context = new SignalRModel()
		this._onDisconnected = new CustomEvent('signalr_disconnected')
	}

	public async start(user: User, lang: ILanguage, setUser: (user: User) => void) {
		if (!user) return

		this._user = user
		this._lang = lang
		this._setUser = setUser

		this.initHandlers(this._connection)

		await this.connect()
	}

	public async update(user: User, lang: ILanguage) {
		if (!user || !lang) return

		this._user = user
		this._lang = lang
	}

	private async connect() {
		if (this._connection.state !== HubConnectionState.Disconnected) return

		await this._connection.start()
	}

	public async addFavorite(profileId: number, appId: number, service1: EServices, service2: EServices, id: number) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.AddFavorite, profileId, appId, service1, service2, id)
	}

	public async removeFavorite(profileId: number, appId: number, service1: EServices, service2: EServices, id: number) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.RemoveFavorite, profileId, appId, service1, service2, id)
	}

	public async clearFavorites(profileId: number, appId: number, service1: EServices, service2: EServices) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.ClearFavorites, profileId, appId, service1, service2)
	}

	public async addBlacklist(profileId: number, appId: number, service1: EServices, service2: EServices, id: number) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.AddBlacklist, profileId, appId, service1, service2, id)
	}

	public async removeBlacklist(profileId: number, appId: number, service1: EServices, service2: EServices, id: number) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.RemoveBlacklist, profileId, appId, service1, service2, id)
	}

	public async clearBlacklist(profileId: number, appId: number, service1: EServices, service2: EServices) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.ClearBlacklist, profileId, appId, service1, service2)
	}

	public async addBlacklistTmp(profileId: number, appId: number, service1: EServices, service2: EServices, id: number) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.AddBlacklistTmp, profileId, appId, service1, service2, id)
	}

	public async removeBlacklistTmp(profileId: number, appId: number, service1: EServices, service2: EServices, id: number) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.RemoveBlacklistTmp, profileId, appId, service1, service2, id)
	}

	public async clearBlacklistTmp(profileId: number, appId: number, service1: EServices, service2: EServices) {
		if (this._connection.state !== HubConnectionState.Connected) return

		await this._connection.send(Endpoints.ClearBlacklistTmp, profileId, appId, service1, service2)
	}

	private initHandlers(connection: HubConnection): void {
		connection.onclose(e => {
			window.dispatchEvent(this._onDisconnected)

			const times = [...this._reconnectTimes]

			const logReconnect = (time?: number) =>
			{
				const text = !time 
					? this._lang.MainConnection_ConnectionLostBody
					: Extensions.StringFormat(this._lang.MainConnection_ConnectionLostBodySec, Math.ceil(time / 1000))

				new Toast(this._lang.MainConnection_ConnectionLostTitle, 
					text, 'danger')
			}

			const timeoutHandler = () => {
				let time = times.shift();
				
				if (!time) return

				logReconnect(time)

				window.setTimeout(async () => {
					const state = this._connection.state

					try {
						await this.connect()
					} catch (err) {}

					if (state !== HubConnectionState.Connected) {
						timeoutHandler()
					}
					else {
						new Toast(this._lang.MainConnection_ConnectionSuccessHeader, this._lang.MainConnection_ConnectionSuccessBody, 'success')
					}
				}, time)
			}

			timeoutHandler()
		})

		connection.on(Endpoints.BalanceUpdate, (data: Deposit) => {
			this._context.balanceUpdate(data, this._lang, this._setUser)
		})

		connection.on(Endpoints.ItemsUpdated, () => {
			this._context.itemsUpdated(this._lang)
		})
	}
}

export const hubConnection = new MainConnection()
