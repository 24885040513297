export interface ILoginState {
	active: boolean
	action: (value: boolean) => void
}

export enum ELoginActionTypes {
	SHOW_LOGIN_NEEDED = 'SHOW_LOGIN_NEEDED',
	CLOSE_LOGIN_NEEDED = 'CLOSE_LOGIN_NEEDED',
}

interface IShowLoginAction {
	type: ELoginActionTypes.SHOW_LOGIN_NEEDED
	payload: (value: boolean) => void
}

interface ICloseLoginAction {
	type: ELoginActionTypes.CLOSE_LOGIN_NEEDED
}

export type LoginAction = IShowLoginAction | ICloseLoginAction
