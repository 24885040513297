import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { ContextMenu, ContextMenuItem } from "./ContextMenu.context";
import styles from './ContextMenu.module.css'
import React from "react";

export const ContextMenuProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const [ contextMenuItems, setContextMenuItems ] = useState<ContextMenuItem[]>([]);  
    const [ position, setPosition ] = useState<number[]>([]);
    const [ title, setTitle ] = useState<string>(null)
    
    const setContextMenu = useCallback((items: ContextMenuItem[], position: number[], title: string = null) => {
        setContextMenuItems(items);
        setPosition(position);
        setTitle(title)
    }, []);

    const closeMenu = useCallback(() => {
        setPosition(undefined)
    }, [])
    
    useEffect(() => {
        document.body.addEventListener('click', closeMenu);

        return () => {
            document.body.removeEventListener('click', closeMenu)
        }
    })

    return (
        <ContextMenu.Provider value={{ setContextMenu }}>
            {!!position && (
                <div className={styles.contextMenu}
                    style={{ left: position[0], top: position[1]}}>
                    {title !== null && (
                        <React.Fragment>
                            <div className={styles.nameItem}>{title}</div>
                            <hr className={styles.line}/>
                        </React.Fragment>
                    )}
                    <ul>
                        {contextMenuItems.map((item) =>
                            <li key={item.name}
                                className={styles.contextMenuItem}
                                onClick={item.onClick}
                            >
                                {item.name}</li>
                        )}
                    </ul>
                </div>
            )}
            {children}
        </ContextMenu.Provider>
    )
}

