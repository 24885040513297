import { User } from './../types/user/user'

export default class Login {
	public static readonly eventName: string = 'onLogin'

	public static LoginRequired: boolean
	private static _currentPath: string = ''
	private static _currentUrl: string = ''

	private static _pathsWithLoginNeeded: string[] = ['profile', 'settings', 'table', 'sub', 'support', 'inventory']

	public static CheckForLogin(url: string, path: string, user: User): boolean {
		var p = this.GetPath(path)

		this.LoginRequired = this.CheckForLoginNeeded(p) && user == null

		if (!this.LoginRequired) {
			this._currentPath = p
			this._currentUrl = url
		}

		return this.LoginRequired
	}

	public static CheckForLoginNeeded(path: string): boolean {
		return this._pathsWithLoginNeeded.includes(path)
	}

	public static GetPath(source: string): string {
		if (source == null) return null
		return source.split('/')[1]
	}

	public static GetCurrentPath(): string {
		return this._currentPath
	}

	public static GetCurrentUrl(): string {
		return this._currentUrl
	}
}
