class Cookie {
	public SetCookie(name: string, value: string, days: number = 3650): void {
		let expires: string
		if (days) {
			var date = new Date()
			date.setTime(date.getTime() + days * 86400000)
			expires = '; expires=' + date.toUTCString()
		} else {
			expires = ''
		}
		document.cookie = name + '=' + value + expires + '; path=/'
	}

	public DeleteCookie(name: string): void {
		this.SetCookie(name, '', -1)
	}

	public GetCookie(name: string): string {
		let matches = document.cookie.match(
			new RegExp(
				'(?:^|; )' +
					name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
					'=([^;]*)'
			)
		)
		return matches ? decodeURIComponent(matches[1]) : undefined
	}
}

export default new Cookie()
