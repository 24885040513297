import React, { FC } from 'react'
import { useActions } from '../../hooks/useAction'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { EType, ETypeValue, ItemType } from '../../types/tableHelper'
import Dropdown from '../Elements/Dropdown'
import styles from './css/SelectItemFilters.module.css'

export interface SelectItemFiltersProps {
	onChanged: (app: number) => void
	afterChange: () => void
}

const SelectItemFilters: FC<SelectItemFiltersProps> = ({ onChanged, afterChange }) => {
	const { filter } = useTypedSelector(x => x.table)
	const {
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const { setTableFilter } = useActions()

	if (filter == null) return

	var apps = [0, filter.appId]

	return (
		<Dropdown>
			<div className={`${styles.title} title`}>{lang.TablePage_Filters}</div>
			<div className='menu'>
				{apps.map(app => {
					const allTypes = filter.allTypes.get(app).Types

					return Array.from(allTypes).map((types, i) => {
						const key = types[0]
						const value = types[1]	
						
						return (
							<React.Fragment key={i}>
								{key !== '' && <div className='menu-header no-close'>{key}</div>}
								{Array.from(value).map((type, i) => {
									const typeKey: EType = type[0]
									const typeValue: ItemType = type[1]
									const hasOnly = filter.allTypes.get(app).HasOnly(key, typeKey)

									return (
										<div
											className={`${styles.menuItem} menu-item no-close`}
											onClick={() => {
												typeValue.NextValue(hasOnly)
												setTableFilter(filter, false, true)
												afterChange?.call(this)
											}}
											key={i}
										>
											{typeValue.TypeValue === ETypeValue.Only ? (
												<i className='fa fa-check' aria-hidden='true'></i>
											) : (
												typeValue.TypeValue === ETypeValue.Without && <i className='fas fa-times' aria-hidden='true'></i>
											)}{' '}
											{typeValue.Description}
										</div>
									)
								})}
							</React.Fragment>
						)
					})
				})}
			</div>
		</Dropdown>
	)
}

export default SelectItemFilters
